import axios from 'axios';
import { config } from '../config';

const getBusinessTransactions = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transactions`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTransactionsViaType = async function (token, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transactions/via/type`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTransactionsViaStatus = async function (token, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transactions/via/transaction/status`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTransaction = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transaction`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const addBusinessDeposit = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transaction/payment/deposit`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const cancelBusinessDeposit = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transaction/cancel/deposit`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const completeBusinessDeposit = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transaction/complete/deposit`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addBusinessWithdrawal = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transaction/payment/withdrawal`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const cancelBusinessWithdrawal = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transaction/cancel/withdrawal`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const completeBusinessWithdrawal = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/transaction/complete/withdrawal`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const deleteBusinessTransaction = async function (token, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/business/transaction`,
			{
				data: {
					token,
					...payload
				}
			},
			// {
			// 	headers: {
			// 		'passcoder-access-token': token
			// 	}
			// }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { 
	addBusinessDeposit, cancelBusinessDeposit, completeBusinessDeposit, deleteBusinessTransaction, getBusinessTransaction, 
	getBusinessTransactions, getBusinessTransactionsViaStatus, getBusinessTransactionsViaType, addBusinessWithdrawal, 
	cancelBusinessWithdrawal, completeBusinessWithdrawal,
};