import axios from 'axios';
import { config } from '../config';

const getBusinessEventTickets = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/event/tickets`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessEventTicket = async function (token, unique_id) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/event/ticket`,
			{
				unique_id
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addBusinessEventTicket = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/event/ticket/add`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventTicketName = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/ticket/update/name`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventTicketDetails = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/ticket/update/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventTicketCriteria = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/ticket/update/criteria`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const deleteEventTicket = async function (token, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/business/event/ticket`,
			{
				data: {
					token,
					...payload
				}
			},
			// {
			// 	headers: {
			// 		'passcoder-access-token': token
			// 	}
			// }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getBusinessEventTicket, addBusinessEventTicket, deleteEventTicket, getBusinessEventTickets, updateEventTicketDetails, updateEventTicketCriteria, updateEventTicketName };
