import { useState, useEffect } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { addBusinessEventTicket, deleteEventTicket, getBusinessEventTicket, updateEventTicketCriteria, updateEventTicketDetails, updateEventTicketName } from "../api/eventTickets";

const useAddEventTicket = () => {

	const { cookie } = useCookie(config.token, "");

	const [loading, setLoading] = useState(false);
	const [removeAddEventTicketModal, setRemoveAddEventTicketModal] = useState(null);
	const [eventUniqueId, setEventUniqueId] = useState("");
	const [name, setName] = useState("");
	const [amount, setAmount] = useState(null);
	const [quantity, setQuantity] = useState(null);
	const [single, setSingle] = useState(false);

	const [errorAddEventTicket, setErrorAddEventTicket] = useState(null);
	const [successAddEventTicket, setSuccessAddEventTicket] = useState(null);

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };
	const handleAmount = (e) => { e.preventDefault(); setAmount(e.target.value); };
	const handleQuantity = (e) => { e.preventDefault(); setQuantity(e.target.value); };
	const handleSingle = (e) => { e.preventDefault(); setSingle(!single); };
	const handleEventUniqueId = (e) => { e.preventDefault(); setEventUniqueId(e.target.value) };
	
	const handleSubmit = (e) => {
		e.preventDefault();

		if (!loading) {
			if (eventUniqueId.length < 1) {
				setErrorAddEventTicket(null);
				setSuccessAddEventTicket(null);
				setErrorAddEventTicket("Event Unique Id is required");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else if (name.length < 3) {
				setErrorAddEventTicket("Name is required | Min character - 3");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else if (name.length > 200) {
				setErrorAddEventTicket("Invalid Name | Max character - 200");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else if (!amount) {
				setErrorAddEventTicket("Amount is required");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else if (amount < 0) {
				setErrorAddEventTicket("Invalid amount");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else if (!quantity) {
				setErrorAddEventTicket("Quantity is required");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else if (quantity < 1) {
				setErrorAddEventTicket("Invalid quantity (minimum - 1)");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else if (amount === 0 && !single) {
				setErrorAddEventTicket("Ticket can't be both free and unlimited");
				setTimeout(function () {
					setErrorAddEventTicket(null);
				}, 2500)
			} else {
				setLoading(true);

				const addEventTicketRes = addBusinessEventTicket(cookie, {
					event_unique_id: eventUniqueId,
					name,
					amount: parseInt(amount),
					quantity: parseInt(quantity),
					single
				})

				addEventTicketRes.then(res => {
					setLoading(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAddEventTicket(error);
							setTimeout(function () {
								setErrorAddEventTicket(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAddEventTicket(error);
							setTimeout(function () {
								setErrorAddEventTicket(null);
							}, 2000)
						}
					} else {
						setErrorAddEventTicket(null);
						setSuccessAddEventTicket(`Event Ticket added successfully!`);

						setTimeout(function () {
							setSuccessAddEventTicket(null);
							setRemoveAddEventTicketModal(true);
							setName(""); setAmount(null); setQuantity(null); setSingle(false);
						}, 2500)
					}
				}).catch(err => {
					setLoading(false);
				})

			}
		}
	};

	return {
		cookie, loading, removeAddEventTicketModal, eventUniqueId, name, amount, quantity, single, errorAddEventTicket, successAddEventTicket,
		handleName, handleAmount, handleQuantity, handleSingle, handleEventUniqueId, handleSubmit, setRemoveAddEventTicketModal, setEventUniqueId
	};
};

const useEditEventTicket = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingEditEventTicket, setLoadingEditEventTicket] = useState(false);
	const [removeEditEventTicketModal, setRemoveEditEventTicketModal] = useState(null);
	const [nameEdit, setNameEdit] = useState("");
	const [amountEdit, setAmountEdit] = useState(null);
	const [quantityEdit, setQuantityEdit] = useState(null);
	const [singleEdit, setSingleEdit] = useState(false);

	const [editEventTicketUniqueId, setEditEventTicketUniqueId] = useState(null);
	const [editEventTicketDetails, setEditEventTicketDetails] = useState(null);

	async function getBusinessEventTicketDetails(unique_id) {
		const response = await getBusinessEventTicket(cookie, unique_id);
		setEditEventTicketDetails(response.data);
		setNameEdit(response.data.data.name);
		setAmountEdit(response.data.data.amount);
		setQuantityEdit(response.data.data.quantity);
	}

	const [showEditEventTicketDetailsStatus, setShowEditEventTicketDetailsStatus] = useState(false);
	
	const [errorEditEventTicket, setErrorEditEventTicket] = useState(null);
	const [successEditEventTicket, setSuccessEditEventTicket] = useState(null);

	const handleNameEdit = (e) => { e.preventDefault(); setNameEdit(e.target.value); };
	const handleAmountEdit = (e) => { e.preventDefault(); setAmountEdit(e.target.value); };
	const handleQuantityEdit = (e) => { e.preventDefault(); setQuantityEdit(e.target.value); };
	const handleSingleEdit = (e) => { e.preventDefault(); setSingleEdit(!singleEdit); };

	const handleEditEventTicketDetails = (e) => {
		e.preventDefault();
		setShowEditEventTicketDetailsStatus(true);

		if (!loadingEditEventTicket) {
			if (nameEdit.length < 3) {
				setErrorEditEventTicket(null);
				setSuccessEditEventTicket(null);
				setErrorEditEventTicket("Name is required | Min character - 3");
				setTimeout(function () {
					setErrorEditEventTicket(null);
					setShowEditEventTicketDetailsStatus(false);
				}, 2500)
			} else if (nameEdit.length > 200) {
				setErrorEditEventTicket("Invalid Name | Max character - 200");
				setTimeout(function () {
					setErrorEditEventTicket(null);
					setShowEditEventTicketDetailsStatus(false);
				}, 2500)
			} else if (!amountEdit) {
				setErrorEditEventTicket("Amount is required");
				setTimeout(function () {
					setErrorEditEventTicket(null);
					setShowEditEventTicketDetailsStatus(false);
				}, 2500)
			} else if (amountEdit < 0) {
				setErrorEditEventTicket("Invalid amount");
				setTimeout(function () {
					setErrorEditEventTicket(null);
					setShowEditEventTicketDetailsStatus(false);
				}, 2500)
			} else if (!quantityEdit) {
				setErrorEditEventTicket("Quantity is required");
				setTimeout(function () {
					setErrorEditEventTicket(null);
					setShowEditEventTicketDetailsStatus(false);
				}, 2500)
			} else if (quantityEdit < 1) {
				setErrorEditEventTicket("Invalid quantity (minimum - 1)");
				setTimeout(function () {
					setErrorEditEventTicket(null);
					setShowEditEventTicketDetailsStatus(false);
				}, 2500)
			} else if (amountEdit === 0 && !singleEdit) {
				setErrorEditEventTicket("Ticket can't be both free and unlimited");
				setTimeout(function () {
					setErrorEditEventTicket(null);
					setShowEditEventTicketDetailsStatus(false);
				}, 2500)
			} else {
				setLoadingEditEventTicket(true);

				const editEventTicketDetailsRes = updateEventTicketDetails(cookie, {
					unique_id: editEventTicketUniqueId,
					name: nameEdit,
					amount: parseInt(amountEdit),
					quantity: parseInt(quantityEdit),
					single: singleEdit,
				})

				editEventTicketDetailsRes.then(res => {
					setLoadingEditEventTicket(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorEditEventTicket(error);
							setTimeout(function () {
								setErrorEditEventTicket(null);
								setShowEditEventTicketDetailsStatus(false);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorEditEventTicket(error);
							setTimeout(function () {
								setErrorEditEventTicket(null);
								setShowEditEventTicketDetailsStatus(false);
							}, 2000)
						}
					} else {
						setErrorEditEventTicket(null);
						setSuccessEditEventTicket(`Event Ticket details edited successfully!`);

						setTimeout(function () {
							setSuccessEditEventTicket(null);
							setShowEditEventTicketDetailsStatus(false);
							setRemoveEditEventTicketModal(true);
							setEditEventTicketUniqueId(null);
							setNameEdit(""); setAmountEdit(null); setQuantityEdit(null); setSingleEdit(false);
						}, 2500)
					}
				}).catch(err => {
					setLoadingEditEventTicket(false);
				})

			}
		}
	};

	return {
		cookie, nameEdit, amountEdit, quantityEdit, singleEdit, editEventTicketDetails, loadingEditEventTicket, removeEditEventTicketModal, errorEditEventTicket, successEditEventTicket, 
		setRemoveEditEventTicketModal, setEditEventTicketUniqueId, editEventTicketUniqueId, handleNameEdit, handleAmountEdit, handleQuantityEdit, handleSingleEdit, setNameEdit, setAmountEdit, 
		setQuantityEdit, setSingleEdit, handleEditEventTicketDetails, getBusinessEventTicketDetails, showEditEventTicketDetailsStatus
	};
};

const useDeleteEventTicket = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingDeleteEventTicket, setLoadingDeleteEventTicket] = useState(false);
	const [removeDeleteEventTicketModal, setRemoveDeleteEventTicketModal] = useState(null);
	const [deleteEventTicketUniqueId, setDeleteEventTicketUniqueId] = useState(null);

	const [errorDeleteEventTicket, setErrorDeleteEventTicket] = useState(null);
	const [successDeleteEventTicket, setSuccessDeleteEventTicket] = useState(null);

	const handleDeleteEventTicket = () => {

		if (!loadingDeleteEventTicket) {
			if (!deleteEventTicketUniqueId) {
				setErrorDeleteEventTicket(null);
				setSuccessDeleteEventTicket(null);
				setErrorDeleteEventTicket("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteEventTicket(null);
				}, 2500)
			} else {
				setLoadingDeleteEventTicket(true);

				const deleteEventTicketRes = deleteEventTicket(cookie, {
					unique_id: deleteEventTicketUniqueId
				})

				deleteEventTicketRes.then(res => {
					setLoadingDeleteEventTicket(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteEventTicket(error);
							setTimeout(function () {
								setErrorDeleteEventTicket(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteEventTicket(error);
							setTimeout(function () {
								setErrorDeleteEventTicket(null);
							}, 2000)
						}
					} else {
						setErrorDeleteEventTicket(null);
						setSuccessDeleteEventTicket(`Event Ticket deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteEventTicket(null);
							setRemoveDeleteEventTicketModal(true);
							setDeleteEventTicketUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteEventTicket(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteEventTicket, removeDeleteEventTicketModal, deleteEventTicketUniqueId, errorDeleteEventTicket, successDeleteEventTicket,
		handleDeleteEventTicket, setRemoveDeleteEventTicketModal, setDeleteEventTicketUniqueId
	};
};

export { useAddEventTicket, useEditEventTicket, useDeleteEventTicket };