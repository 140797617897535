import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCookie from "../hooks/useCookie";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { app } from "../firebase";
import { config } from "../config";
import { 
	addBusinessEvent, deleteEvent, updateEventDescription, updateEventDetails, updateEventDuration, 
	updateEventImage, updateEventLocation, updateEventName, getBusinessEventImage, getBusinessEvent
} from "../api/events";

const useAddEvent = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.token, "");

	const [loading, setLoading] = useState(false);
	const [removeAddEventModal, setRemoveAddEventModal] = useState(null);
	const [name, setName] = useState("");
	const [location, setLocation] = useState("");
	const [description, setDescription] = useState("");
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [selectedImage, setSelectedImage] = useState("");
	const [uploadingImagePercentage, setUploadingImagePercentage] = useState(0);

	const [errorAddEvent, setErrorAddEvent] = useState(null);
	const [successAddEvent, setSuccessAddEvent] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "image/webp", "image/WEBP"];
	const maximum_file_size = 5 * 1024 * 1024;

	const [reloadImage, setReloadImage] = useState(false);

	const handleName = (e) => { e.preventDefault(); setName(e.target.value); };
	const handleLocation = (e) => { e.preventDefault(); setLocation(e.target.value); };
	const handleDescription = (e) => { e.preventDefault(); setDescription(e.target.value) };
	const handleStart = (e) => { e.preventDefault(); setStart(e.target.value); };
	const handleEnd = (e) => { e.preventDefault(); setEnd(e.target.value); };

	const validateStart = (start) => {
		const d = new Date(start);
		const today = new Date();
		today.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
		if (d === "Invalid Date") return false;
		if (today.getTime() > d.getTime()) return false;
		return true;
	};

	const validateEnd = (_start, _end) => {
		const start = new Date(_start);
		start.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
		const end = new Date(_end);
		end.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
		if (start === "Invalid Date") return false;
		if (end === "Invalid Date") return false;
		if (start.getTime() > end.getTime()) return false;
		return true;
	};

	const return_start = (start) => {
		if (start === "") return undefined;
		let _start = start.split("T");
		return _start[0] + " " + _start[1];
	};

	const return_end = (end) => {
		if (end === "") return undefined;
		let _end = end.split("T");
		return _end[0] + " " + _end[1];
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!loading) {
			if (name.length < 3) {
				setErrorAddEvent(null);
				setSuccessAddEvent(null);
				setErrorAddEvent("Name is required | Min character - 3");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (name.length > 200) {
				setErrorAddEvent("Invalid Name | Max character - 200");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (location.length < 3) {
				setErrorAddEvent("Location is required | Min character - 3");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (location.length > 200) {
				setErrorAddEvent("Invalid location | Max character - 200");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (description && description.length < 3) {
				setErrorAddEvent("Description is required | Min character - 3");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (description && description.length > 1500) {
				setErrorAddEvent("Invalid Description | Max length reached");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (!start) {
				setErrorAddEvent("Start date is required");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (!validateStart(start)) {
				setErrorAddEvent("Invalid Start datetime (note: Timezone +01:00)");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (!end) {
				setErrorAddEvent("End date is required");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (!validateEnd(start, end)) {
				setErrorAddEvent("Invalid End datetime (note: Timezone +01:00)");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (selectedImage.length < 1) {
				setErrorAddEvent("Image is required");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2500)
			} else if (!allowed_extensions.includes(selectedImage.type)) {
				setErrorAddEvent("Invalid image format (.png, .jpg, .jpeg & .webp)");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2000)
			} else if (selectedImage.size > maximum_file_size) {
				setErrorAddEvent("File too large (max 5mb)");
				setTimeout(function () {
					setErrorAddEvent(null);
				}, 2000)
			} else {
				setLoading(true);

				const eventImageRes = getBusinessEventImage({ name: name })

				eventImageRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setUploadingImagePercentage(0);
							setLoading(false);
							setErrorAddEvent(error);
							setTimeout(function () {
								setErrorAddEvent(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setUploadingImagePercentage(0);
							setLoading(false);
							setErrorAddEvent(error);
							setTimeout(function () {
								setErrorAddEvent(null);
							}, 2000)
						}
					} else {
						const event_image_rename = res.data.data[0].image;
						let lastDot = selectedImage.name.lastIndexOf('.');
						let ext = selectedImage.name.substring(lastDot + 1);

						const imagePath = "/events/" + event_image_rename + "." + ext;

						const storageRef = ref(storage, imagePath);
						const uploadTask = uploadBytesResumable(storageRef, selectedImage);

						uploadTask.on('state_changed',
							(snapshot) => {
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								setUploadingImagePercentage(progress);
							},
							(error) => {
								setUploadingImagePercentage(0);
								setLoading(false);
								setErrorAddEvent("An error occured while uploading");
								setTimeout(function () {
									setErrorAddEvent(null);
								}, 3000)
							},
							() => {
								getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

									const addEventRes = addBusinessEvent(cookie, {
										name,
										location,
										description: description === null ? undefined : description,
										start: return_start(start),
										end: return_end(end),
										image: downloadURL,
										image_file_ext: imagePath
									})

									addEventRes.then(res => {
										setLoading(false);
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setUploadingImagePercentage(0);
												setLoading(false);
												setErrorAddEvent(error);
												setTimeout(function () {
													setErrorAddEvent(null);
												}, 2000)
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setUploadingImagePercentage(0);
												setLoading(false);
												setErrorAddEvent(error);
												setTimeout(function () {
													setErrorAddEvent(null);
												}, 2000)
											}
										} else {
											setErrorAddEvent(null);
											setUploadingImagePercentage(0);
											setSuccessAddEvent(`Event added successfully!`);

											setTimeout(function () {
												setSuccessAddEvent(null);
												setRemoveAddEventModal(true);
												setName(""); setLocation(""); setStart(""); setEnd(""); setDescription(""); setSelectedImage("");
											}, 2500)
										}
									}).catch(err => {
										setUploadingImagePercentage(0);
										setLoading(false);
									})
								});

							}
						)
					}
				}).catch(err => {
					setUploadingImagePercentage(0);
					setLoading(false);
				})

			}
		}
	};

	return {
		cookie, loading, removeAddEventModal, name, location, description, start, end, setSelectedImage, errorAddEvent, successAddEvent,
		handleName, handleLocation, handleDescription, handleStart, handleEnd, handleSubmit,
		setRemoveAddEventModal, uploadingImagePercentage, selectedImage,
	};
};

const useEditEvent = () => {

	const storage = getStorage(app);

	const { cookie } = useCookie(config.token, "");

	const [loadingEditEvent, setLoadingEditEvent] = useState(false);
	const [removeEditEventModal, setRemoveEditEventModal] = useState(null);
	const [nameEdit, setNameEdit] = useState("");

	const [locationEdit, setLocationEdit] = useState(null);
	
	const [descriptionEdit, setDescriptionEdit] = useState("");

	const [startEdit, setStartEdit] = useState(null);
	const [endEdit, setEndEdit] = useState(null);

	const [selectedImageEdit, setSelectedImageEdit] = useState("");
	const [uploadingImageEditPercentage, setUploadingImageEditPercentage] = useState(0);

	const [editEventUniqueId, setEditEventUniqueId] = useState(null);
	const [editEventDetails, setEditEventDetails] = useState(null);

	async function getBusinessEventDetails(unique_id) {
		const response = await getBusinessEvent(cookie, unique_id);
		setEditEventDetails(response.data);
		setNameEdit(response.data.data.name);
		setLocationEdit(response.data.data.location);
		setDescriptionEdit(response.data.data.description);
	};

	const [showEditEventNameStatus, setShowEditEventNameStatus] = useState(false);
	const [showEditEventDetailsStatus, setShowEditEventDetailsStatus] = useState(false);
	const [showEditEventDurationStatus, setShowEditEventDurationStatus] = useState(false);
	const [showEditEventLocationStatus, setShowEditEventLocationStatus] = useState(false);
	const [showEditEventDescriptionStatus, setShowEditEventDescriptionStatus] = useState(false);
	const [showEditEventImageStatus, setShowEditEventImageStatus] = useState(false);

	const [errorEditEvent, setErrorEditEvent] = useState(null);
	const [successEditEvent, setSuccessEditEvent] = useState(null);

	const allowed_extensions = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "image/webp", "image/WEBP"];
	const maximum_file_size = 5 * 1024 * 1024;

	const handleNameEdit = (e) => { e.preventDefault(); setNameEdit(e.target.value); };
	const handleLocationEdit = (e) => { e.preventDefault(); setLocationEdit(e.target.value); };
	const handleDescriptionEdit = (e) => { e.preventDefault(); setDescriptionEdit(e.target.value) };
	const handleStartEdit = (e) => { e.preventDefault(); setStartEdit(e.target.value); };
	const handleEndEdit = (e) => { e.preventDefault(); setEndEdit(e.target.value); };

	const validateStart = (start) => {
		const d = new Date(start);
		const today = new Date();
		today.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
		if (d === "Invalid Date") return false;
		if (today.getTime() > d.getTime()) return false;
		return true;
	};

	const validateEnd = (_start, _end) => {
		const start = new Date(_start);
		start.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
		const end = new Date(_end);
		end.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
		if (start === "Invalid Date") return false;
		if (end === "Invalid Date") return false;
		if (start.getTime() > end.getTime()) return false;
		return true;
	};

	const return_start = (start) => {
		if (start === "") return undefined;
		let _start = start.split("T");
		return _start[0] + " " + _start[1];
	};

	const return_end = (end) => {
		if (end === "") return undefined;
		let _end = end.split("T");
		return _end[0] + " " + _end[1];
	};

	const handleEditEventDetails = (e) => {
		e.preventDefault();
		setShowEditEventDetailsStatus(true);

		if (!loadingEditEvent) {
			if (nameEdit.length < 3) {
				setErrorEditEvent(null);
				setSuccessEditEvent(null);
				setErrorEditEvent("Name is required | Min character - 3");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (nameEdit.length > 200) {
				setErrorEditEvent("Invalid Name | Max character - 200");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (!locationEdit) {
				setErrorEditEvent("Location is required");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (locationEdit.length < 3) {
				setErrorEditEvent("Location is required | Min character - 3");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (locationEdit.length > 200) {
				setErrorEditEvent("Invalid location | Max character - 200");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (descriptionEdit && descriptionEdit.length < 3) {
				setErrorEditEvent("Description is required | Min character - 3");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (descriptionEdit && descriptionEdit.length > 1500) {
				setErrorEditEvent("Invalid Description | Max length reached");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (!startEdit) {
				setErrorEditEvent("Start date is required");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (!validateStart(startEdit)) {
				setErrorEditEvent("Invalid Start datetime (note: Timezone +01:00)");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (!endEdit) {
				setErrorEditEvent("End date is required");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (!validateEnd(startEdit, endEdit)) {
				setErrorEditEvent("Invalid End datetime (note: Timezone +01:00)");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else {
				setLoadingEditEvent(true);

				const editEventDetailsRes = updateEventDetails(cookie, {
					unique_id: editEventUniqueId,
					name: nameEdit,
					location: locationEdit,
					description: descriptionEdit === null ? undefined : descriptionEdit,
					start: return_start(startEdit),
					end: return_end(endEdit)
				})

				editEventDetailsRes.then(res => {
					setLoadingEditEvent(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorEditEvent(error);
							setTimeout(function () {
								setErrorEditEvent(null);
								setShowEditEventDetailsStatus(false);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorEditEvent(error);
							setTimeout(function () {
								setErrorEditEvent(null);
								setShowEditEventDetailsStatus(false);
							}, 2000)
						}
					} else {
						setErrorEditEvent(null);
						setSuccessEditEvent(`Event details edited successfully!`);

						setTimeout(function () {
							setSuccessEditEvent(null);
							setShowEditEventDetailsStatus(false);
							setRemoveEditEventModal(true);
							setEditEventUniqueId(null);
							setNameEdit(""); setLocationEdit(""); setStartEdit(""); setEndEdit(""); setDescriptionEdit("");
						}, 2500)
					}
				}).catch(err => {
					setLoadingEditEvent(false);
				})

			}
		}
	};

	const handleEditEventImage = (e) => {
		e.preventDefault();
		setShowEditEventImageStatus(true);

		if (!loadingEditEvent) {
			if (selectedImageEdit.length < 1) {
				setErrorEditEvent(null);
				setSuccessEditEvent(null);
				setErrorEditEvent("Image required");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventImageStatus(false);
				}, 2500)
			} else if (!allowed_extensions.includes(selectedImageEdit.type)) {
				setErrorEditEvent("Invalid image format (.png, .jpg, .jpeg & .webp)");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else if (selectedImageEdit.size > maximum_file_size) {
				setErrorEditEvent("File too large (max 5mb)");
				setTimeout(function () {
					setErrorEditEvent(null);
					setShowEditEventDetailsStatus(false);
				}, 2500)
			} else {
				setLoadingEditEvent(true);

				const eventImageRes = getBusinessEventImage({ name: nameEdit })

				eventImageRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setUploadingImageEditPercentage(0);
							setLoadingEditEvent(false);
							setErrorEditEvent(error);
							setTimeout(function () {
								setErrorEditEvent(null);
								setShowEditEventImageStatus(false);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setUploadingImageEditPercentage(0);
							setLoadingEditEvent(false);
							setErrorEditEvent(error);
							setTimeout(function () {
								setErrorEditEvent(null);
								setShowEditEventImageStatus(false);
							}, 2000)
						}
					} else {
						const event_image_rename = res.data.data[0].image;
						let lastDot = selectedImageEdit.name.lastIndexOf('.');
						let ext = selectedImageEdit.name.substring(lastDot + 1);

						const imagePath = "/events/" + event_image_rename + "." + ext;

						const storageRef = ref(storage, imagePath);
						const uploadTask = uploadBytesResumable(storageRef, selectedImageEdit);

						uploadTask.on('state_changed',
							(snapshot) => {
								const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
								setUploadingImageEditPercentage(progress);
							},
							(error) => {
								setUploadingImageEditPercentage(0);
								setLoadingEditEvent(false);
								setErrorEditEvent("An error occured while uploading");
								setTimeout(function () {
									setErrorEditEvent(null);
									setShowEditEventImageStatus(false);
								}, 3000)
							},
							() => {
								getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

									const updateEventImageRes = updateEventImage(cookie, {
										unique_id: editEventUniqueId,
										image: downloadURL,
										image_file_ext: imagePath
									})

									updateEventImageRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setUploadingImageEditPercentage(0);
												setLoadingEditEvent(false);
												setErrorEditEvent(error);
												setTimeout(function () {
													setErrorEditEvent(null);
													setShowEditEventImageStatus(false);
												}, 2000)
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setUploadingImageEditPercentage(0);
												setLoadingEditEvent(false);
												setErrorEditEvent(error);
												setTimeout(function () {
													setErrorEditEvent(null);
													setShowEditEventImageStatus(false);
												}, 2000)
											}
										} else {
											setErrorEditEvent(null);
											setUploadingImageEditPercentage(0);
											setSuccessEditEvent(`Event image updated successfully!`);

											setTimeout(function () {
												setLoadingEditEvent(false);
												setSuccessEditEvent(null);
												setShowEditEventImageStatus(false);
												setRemoveEditEventModal(true);
												setEditEventUniqueId(null);
												setSelectedImageEdit(null);
											}, 3000)
										}
									}).catch(err => {
										setUploadingImageEditPercentage(0);
										setLoadingEditEvent(false);
									})
								});

							}
						)
					}
				}).catch(err => {
					setUploadingImageEditPercentage(0);
					setLoadingEditEvent(false);
				})
			}
		}
	};

	return {
		cookie, nameEdit, locationEdit, descriptionEdit, startEdit, endEdit, editEventDetails, loadingEditEvent, removeEditEventModal, 
		errorEditEvent, successEditEvent, setRemoveEditEventModal, setEditEventUniqueId, editEventUniqueId, handleNameEdit, 
		handleLocationEdit, handleDescriptionEdit, handleStartEdit, handleEndEdit, setNameEdit, setLocationEdit, setDescriptionEdit, 
		setStartEdit, setEndEdit, setSelectedImageEdit, uploadingImageEditPercentage, selectedImageEdit, handleEditEventDetails, 
		handleEditEventImage, getBusinessEventDetails, showEditEventDetailsStatus, showEditEventImageStatus
	};
};

const useDeleteEvent = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingDeleteEvent, setLoadingDeleteEvent] = useState(false);
	const [removeDeleteEventModal, setRemoveDeleteEventModal] = useState(null);
	const [deleteEventUniqueId, setDeleteEventUniqueId] = useState(null);

	const [errorDeleteEvent, setErrorDeleteEvent] = useState(null);
	const [successDeleteEvent, setSuccessDeleteEvent] = useState(null);

	const handleDeleteEvent = () => {

		if (!loadingDeleteEvent) {
			if (!deleteEventUniqueId) {
				setErrorDeleteEvent(null);
				setSuccessDeleteEvent(null);
				setErrorDeleteEvent("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteEvent(null);
				}, 2500)
			} else {
				setLoadingDeleteEvent(true);

				const deleteEventRes = deleteEvent(cookie, {
					unique_id: deleteEventUniqueId
				})

				deleteEventRes.then(res => {
					setLoadingDeleteEvent(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteEvent(error);
							setTimeout(function () {
								setErrorDeleteEvent(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteEvent(error);
							setTimeout(function () {
								setErrorDeleteEvent(null);
							}, 2000)
						}
					} else {
						setErrorDeleteEvent(null);
						setSuccessDeleteEvent(`Event deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteEvent(null);
							setRemoveDeleteEventModal(true);
							setDeleteEventUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteEvent(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteEvent, removeDeleteEventModal, deleteEventUniqueId, errorDeleteEvent, successDeleteEvent,
		handleDeleteEvent, setRemoveDeleteEventModal, setDeleteEventUniqueId
	};
};

export { useAddEvent, useEditEvent, useDeleteEvent };