import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import { useState } from "react";
import AccountProfile from "./settings-tabs/AccountProfile";
import CompanyProfile from "./settings-tabs/CompanyProfile";
import PremiumPackages from "./settings-tabs/PremiumPackages";
import MasterToken from "./settings-tabs/MasterToken";
import MasterPassword from "./settings-tabs/MasterPassword";
import BankDetails from "./settings-tabs/BankDetails";

export default function Settings(){
    const [tab, setTab] = useState("accountProfile");
    return(
        <>
        <Screen aside="false" navbar="false">
            <Content>
            <Navbar placeholder="Search something..." makeHidden={true} />
                <section className="xui-bdr-w-1 xui-bdr-fade xui-bdr-s-solid xui-pt-half xui-pb-1 xui-px-1 xui-font-sz-85 psc-tabs-holder">
                    <div onClick={() => setTab("accountProfile")} className={"xui-cursor-pointer xui-py-1 xui-px-half es-tab-card " + (tab === "accountProfile" ? "active" : "")}>
                        <span>Account Profile</span>
                    </div>
                    <div onClick={() => setTab("companyProfile")} className={"xui-cursor-pointer xui-py-1 xui-px-half es-tab-card " + (tab === "companyProfile" ? "active" : "")}>
                        <span>Company Profile</span>
                    </div>
                    <div onClick={() => setTab("premiumPackages")} className={"xui-cursor-pointer xui-py-1 xui-px-half es-tab-card " + (tab === "premiumPackages" ? "active" : "")}>
                        <span>Premium Packages</span>
                    </div>
                    <div onClick={() => setTab("masterToken")} className={"xui-cursor-pointer xui-py-1 xui-px-half es-tab-card " + (tab === "masterToken" ? "active" : "")}>
                        <span>Master Token</span>
                    </div>
                    <div onClick={() => setTab("masterPassword")} className={"xui-cursor-pointer xui-py-1 xui-px-half es-tab-card " + (tab === "masterPassword" ? "active" : "")}>
                        <span>Password</span>
                    </div>
                    <div onClick={() => setTab("bankDetails")} className={"xui-cursor-pointer xui-py-1 xui-px-half es-tab-card " + (tab === "bankDetails" ? "active" : "")}>
                        <span>Bank Details</span>
                    </div>
                </section>
                <section className="xui-py-2">
                    {tab === "accountProfile" && <AccountProfile />}
                    {tab === "companyProfile" && <CompanyProfile />}
                    {tab === "premiumPackages" && <PremiumPackages />}
                    {tab === "masterPassword" && <MasterPassword />}
                    {tab === "masterToken" && <MasterToken />}
                    {tab === "bankDetails" && <BankDetails />}
                </section>
            </Content>
        </Screen>
        </>
    );
}