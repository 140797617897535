import axios from 'axios';
import { config } from '../config';

const getBusinessTickets = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/tickets`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTicketsViaEvent = async function (token, page, size, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/tickets/via/event`,
			{
				page,
				size,
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessTicketViaCode = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/ticket/via/code`,
			{
				code: payload.code
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const validateTicket = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/validate/ticket`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getBusinessTicketViaCode, getBusinessTickets, getBusinessTicketsViaEvent, validateTicket };
