import { useState } from "react";
import { usePaystackPayment } from 'react-paystack';
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { addBusinessDeposit, cancelBusinessDeposit, completeBusinessDeposit, deleteBusinessTransaction, addBusinessWithdrawal, cancelBusinessWithdrawal } from "../api/transactions";
import { addPremiumUpgrade, cancelPremiumUpgrade, completePremiumUpgrade } from "../api/business";

const useAddDeposit = () => {

	const { cookie } = useCookie(config.token, "");

	const [businessDetails, setBusinessDetails] = useState(null);
	const [depositReference, setDepositReference] = useState(null);

	const general_min_amount = 1000;
	const credit_card_max_amount = 20000;
	const transfer_max_amount = 100000;

	const [loading, setLoading] = useState(false);
	const [loadingRetryDepositCheck, setLoadingRetryDepositCheck] = useState(false);
	const [removeFundingModal, setRemoveFundingModal] = useState(null);
	const [showSuccessfulDepositModal, setShowSuccessfulDepositModal] = useState(null);
	const [showUnsuccessfulDepositModal, setShowUnsuccessfulDepositModal] = useState(null);
	const [fundingAmount, setFundingAmount] = useState(null);
	const [fundingPaymentMethod, setFundingPaymentMethod] = useState("Credit/Debit Card");
	const [minFundingAmount, setMinFundingAmount] = useState(general_min_amount);
	const [maxFundingAmount, setMaxFundingAmount] = useState(credit_card_max_amount);

	const [errorAddDeposit, setErrorAddDeposit] = useState(null);
	const [successAddDeposit, setSuccessAddDeposit] = useState(null);

	const [paystackConfig, setPaystackConfig] = useState({
		publicKey: config.paystackPublicApiKey,
		reference: "TXP" + config.uuid(12)
	});

	const initializePayment = usePaystackPayment(paystackConfig);

	const handleFundingAmount = (e) => {
		e.preventDefault();
		setFundingAmount(e.target.value);
		setPaystackConfig({
			...paystackConfig,
			amount: (parseInt(e.target.value) * 100) + ((config.charge_price * 2) * 100),
		});
	};
	const handleFundingPaymentMethod = (e) => { e.preventDefault(); setFundingPaymentMethod(fundingPaymentMethod === "Credit/Debit Card" ? "Transfer" : "Credit/Debit Card"); if (fundingPaymentMethod === "Credit/Debit Card") setMaxFundingAmount(transfer_max_amount); if (fundingPaymentMethod !== "Credit/Debit Card") setMaxFundingAmount(credit_card_max_amount) };
	const handleBusinessDetails = (details) => {
		setBusinessDetails(details);
		setPaystackConfig({
			...paystackConfig,
			email: details.email,
		});
	};

	const retryDepositCheck = (reference) => {
		if (!reference) {
			setErrorAddDeposit("Deposit reference required");
		} else {
			setErrorAddDeposit(null);
			setLoadingRetryDepositCheck(true);

			const completeDepositRes = completeBusinessDeposit(cookie, {
				unique_id: reference
			})

			completeDepositRes.then(res => {
				setLoadingRetryDepositCheck(false);
				if (res.err) {
					if (!res.error.response.data.success) {
						const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
						setErrorAddDeposit(error);
					} else {
						const error = `${res.error.code} - ${res.error.message}`;
						setErrorAddDeposit(error);
					}
				} else {
					setSuccessAddDeposit(`Transaction completed successfully!`);
				}
			}).catch(err => {
				setLoadingRetryDepositCheck(false);
				const error = `An error occured`;
				setErrorAddDeposit(error);
			})
		}
	};

	const retryDepositCheckAlt = (reference) => {
		setShowSuccessfulDepositModal(true);
		if (!reference) {
			setErrorAddDeposit("Deposit reference required");
		} else {
			setErrorAddDeposit(null);
			setLoadingRetryDepositCheck(true);

			const completeDepositRes = completeBusinessDeposit(cookie, {
				unique_id: reference
			})

			completeDepositRes.then(res => {
				setLoadingRetryDepositCheck(false);
				if (res.err) {
					if (!res.error.response.data.success) {
						const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
						setErrorAddDeposit(error);
					} else {
						const error = `${res.error.code} - ${res.error.message}`;
						setErrorAddDeposit(error);
					}
				} else {
					setSuccessAddDeposit(`Transaction completed successfully!`);
				}
			}).catch(err => {
				setLoadingRetryDepositCheck(false);
				const error = `An error occured`;
				setErrorAddDeposit(error);
			})
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!loading) {
			if (fundingAmount < minFundingAmount) {
				setErrorAddDeposit(null);
				setSuccessAddDeposit(null);
				setErrorAddDeposit(`Minimum amount is NGN ${minFundingAmount.toLocaleString()}`);
				setTimeout(function () {
					setErrorAddDeposit(null);
				}, 2500)
			} else if (fundingAmount > maxFundingAmount) {
				setErrorAddDeposit(`Maximum amount is NGN ${maxFundingAmount.toLocaleString()}`);
				setTimeout(function () {
					setErrorAddDeposit(null);
				}, 2500)
			} else if (!fundingPaymentMethod) {
				setErrorAddDeposit("Payment Method is Required");
				setTimeout(function () {
					setErrorAddDeposit(null);
				}, 2500)
			} else {
				setLoading(true);

				const addDepositRes = addBusinessDeposit(cookie, {
					amount: parseInt(fundingAmount),
					payment_method: fundingPaymentMethod
				})

				addDepositRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							setLoading(false);
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAddDeposit(error);
							setTimeout(function () {
								setErrorAddDeposit(null);
							}, 2000)
						} else {
							setLoading(false);
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAddDeposit(error);
							setTimeout(function () {
								setErrorAddDeposit(null);
							}, 2000)
						}
					} else {
						setErrorAddDeposit(null);
						setDepositReference(res.data.data.unique_id);

						if (fundingPaymentMethod === "Transfer") {
							setLoading(false);
							setSuccessAddDeposit(`Transaction added successfully!`);
							setTimeout(function () {
								setSuccessAddDeposit(null);
								setRemoveFundingModal(true);
								setFundingAmount(0);
							}, 2500)
						} else {
							setTimeout(function () {
								setLoading(false);

								const onPaystackSuccess = (reference) => {
									setShowSuccessfulDepositModal(true);
									const completeDepositRes = completeBusinessDeposit(cookie, {
										unique_id: res.data.data.unique_id
									})

									completeDepositRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setErrorAddDeposit(error);
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setErrorAddDeposit(error);
											}
										} else {
											setSuccessAddDeposit(`Transaction completed successfully!`);
										}
									}).catch(err => {
										const error = `An error occured`;
										setErrorAddDeposit(error);
									})
								};

								const onPaystackClose = () => {
									setShowUnsuccessfulDepositModal(true);
									const cancelDepositRes = cancelBusinessDeposit(cookie, {
										unique_id: res.data.data.unique_id
									})

									cancelDepositRes.then(res => {
										if (res.err) {
											if (!res.error.response.data.success) {
												const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
												setErrorAddDeposit(error);
											} else {
												const error = `${res.error.code} - ${res.error.message}`;
												setErrorAddDeposit(error);
											}
										} else {
											setErrorAddDeposit(`Transaction cancelled successfully!`);
										}
									}).catch(err => {
										const error = `An error occured`;
										setErrorAddDeposit(error);
									})
								};

								setSuccessAddDeposit(null);
								setRemoveFundingModal(true);
								setFundingAmount(0);
								setPaystackConfig({
									...paystackConfig,
									reference: res.data.data.unique_id
								});
								initializePayment(onPaystackSuccess, onPaystackClose);
							}, 2500)
						}
					}
				}).catch(err => {
					setLoading(false);
				})

			}
		}
	};

	return {
		cookie, loading, removeFundingModal, fundingAmount, fundingPaymentMethod, errorAddDeposit, successAddDeposit, setSuccessAddDeposit,
		handleFundingAmount, handleFundingPaymentMethod, handleSubmit, setRemoveFundingModal, handleBusinessDetails, setErrorAddDeposit,
		showSuccessfulDepositModal, setShowSuccessfulDepositModal, showUnsuccessfulDepositModal, setShowUnsuccessfulDepositModal,
		depositReference, retryDepositCheck, loadingRetryDepositCheck, retryDepositCheckAlt
	};
};

const useCancelDeposit = () => {

	const {cookie} = useCookie(config.token, "");

	const [loadingCancelDeposit, setLoadingCancelDeposit] = useState(false);
	const [removeCancelDepositModal, setRemoveCancelDepositModal] = useState(null);
	const [cancelDepositUniqueId, setCancelDepositUniqueId] = useState(null);

	const [errorCancelDeposit, setErrorCancelDeposit] = useState(null);
	const [successCancelDeposit, setSuccessCancelDeposit] = useState(null);

	const handleCancelDeposit = () => {

		if (!loadingCancelDeposit) {
			if (!cancelDepositUniqueId) {
				setErrorCancelDeposit(null);
				setSuccessCancelDeposit(null);
				setErrorCancelDeposit("Unique ID is required");
				setTimeout(function () {
					setErrorCancelDeposit(null);
				}, 2500)
			} else {
				setLoadingCancelDeposit(true);
	
				const cancelDepositRes = cancelBusinessDeposit(cookie, {
					unique_id: cancelDepositUniqueId
				})
	
				cancelDepositRes.then(res => {
					setLoadingCancelDeposit(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCancelDeposit(error);
							setTimeout(function () {
								setErrorCancelDeposit(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCancelDeposit(error);
							setTimeout(function () {
								setErrorCancelDeposit(null);
							}, 2000)
						}
					} else {
						setErrorCancelDeposit(null);
						setSuccessCancelDeposit(`Transaction cancelled successfully!`);
	
						setTimeout(function () {
							setSuccessCancelDeposit(null);
							setRemoveCancelDepositModal(true);
							setCancelDepositUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCancelDeposit(false);
				})
	
			}
		}
	};

	return {
		cookie, loadingCancelDeposit, removeCancelDepositModal, cancelDepositUniqueId, errorCancelDeposit, successCancelDeposit,
		handleCancelDeposit, setRemoveCancelDepositModal, setCancelDepositUniqueId
	};
};

const useAddWithdrawal = () => {

	const { cookie } = useCookie(config.token, "");

	const [businessDetails, setBusinessDetails] = useState(null);

	const general_min_amount = 1000;
	const credit_card_max_amount = 20000;
	const transfer_max_amount = 100000;

	const [loading, setLoading] = useState(false);
	const [removeWithdrawalModal, setRemoveWithdrawalModal] = useState(null);
	const [showSuccessfulWithdrawalModal, setShowSuccessfulWithdrawalModal] = useState(null);
	const [showUnsuccessfulWithdrawalModal, setShowUnsuccessfulWithdrawalModal] = useState(null);
	const [withdrawalAmount, setWithdrawalAmount] = useState(null);
	const [withdrawalPaymentMethod, setWithdrawalPaymentMethod] = useState("Transfer");
	const [minWithdrawalAmount, setMinWithdrawalAmount] = useState(general_min_amount);
	const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(transfer_max_amount);

	const [errorAddWithdrawal, setErrorAddWithdrawal] = useState(null);
	const [successAddWithdrawal, setSuccessAddWithdrawal] = useState(null);

	const handleWithdrawalAmount = (e) => {
		e.preventDefault();
		setWithdrawalAmount(e.target.value);
	};
	const handleWithdrawalPaymentMethod = (e) => { e.preventDefault(); setWithdrawalPaymentMethod(withdrawalPaymentMethod === "Credit/Debit Card" ? "Transfer" : "Credit/Debit Card"); if (withdrawalPaymentMethod === "Credit/Debit Card") setMaxWithdrawalAmount(transfer_max_amount); if (withdrawalPaymentMethod !== "Credit/Debit Card") setMaxWithdrawalAmount(credit_card_max_amount) };
	const handleBusinessDetails = (details) => {
		setBusinessDetails(details);
		setMaxWithdrawalAmount(details.balance > transfer_max_amount ? transfer_max_amount : details.balance);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!loading) {
			if (withdrawalAmount < minWithdrawalAmount) {
				setErrorAddWithdrawal(null);
				setSuccessAddWithdrawal(null);
				setErrorAddWithdrawal(`Minimum amount is NGN ${minWithdrawalAmount.toLocaleString()}`);
				setTimeout(function () {
					setErrorAddWithdrawal(null);
				}, 2500)
			} else if (withdrawalAmount > maxWithdrawalAmount) {
				setErrorAddWithdrawal(`Maximum amount is NGN ${maxWithdrawalAmount.toLocaleString()}`);
				setTimeout(function () {
					setErrorAddWithdrawal(null);
				}, 2500)
			} else if (!withdrawalPaymentMethod) {
				setErrorAddWithdrawal("Payment Method is Required");
				setTimeout(function () {
					setErrorAddWithdrawal(null);
				}, 2500)
			} else {
				setLoading(true);

				const addWithdrawalRes = addBusinessWithdrawal(cookie, {
					amount: parseInt(withdrawalAmount),
					payment_method: withdrawalPaymentMethod
				})

				addWithdrawalRes.then(res => {
					if (res.err) {
						if (!res.error.response.data.success) {
							setLoading(false);
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAddWithdrawal(error);
							setTimeout(function () {
								setErrorAddWithdrawal(null);
							}, 2000)
						} else {
							setLoading(false);
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAddWithdrawal(error);
							setTimeout(function () {
								setErrorAddWithdrawal(null);
							}, 2000)
						}
					} else {
						setErrorAddWithdrawal(null);

						setLoading(false);
						setSuccessAddWithdrawal(`Transaction added successfully!`);
						setTimeout(function () {
							setSuccessAddWithdrawal(null);
							setRemoveWithdrawalModal(true);
							setWithdrawalAmount(0);
						}, 2500);
					}
				}).catch(err => {
					setLoading(false);
				})

			}
		}
	};

	return {
		cookie, loading, removeWithdrawalModal, withdrawalAmount, withdrawalPaymentMethod, errorAddWithdrawal, successAddWithdrawal, setSuccessAddWithdrawal,
		handleWithdrawalAmount, handleWithdrawalPaymentMethod, handleSubmit, setRemoveWithdrawalModal, handleBusinessDetails, setErrorAddWithdrawal,
		showSuccessfulWithdrawalModal, setShowSuccessfulWithdrawalModal, showUnsuccessfulWithdrawalModal, setShowUnsuccessfulWithdrawalModal,
	};
};

const useCancelWithdrawal = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingCancelWithdrawal, setLoadingCancelWithdrawal] = useState(false);
	const [removeCancelWithdrawalModal, setRemoveCancelWithdrawalModal] = useState(null);
	const [cancelWithdrawalUniqueId, setCancelWithdrawalUniqueId] = useState(null);

	const [errorCancelWithdrawal, setErrorCancelWithdrawal] = useState(null);
	const [successCancelWithdrawal, setSuccessCancelWithdrawal] = useState(null);

	const handleCancelWithdrawal = () => {

		if (!loadingCancelWithdrawal) {
			if (!cancelWithdrawalUniqueId) {
				setErrorCancelWithdrawal(null);
				setSuccessCancelWithdrawal(null);
				setErrorCancelWithdrawal("Unique ID is required");
				setTimeout(function () {
					setErrorCancelWithdrawal(null);
				}, 2500)
			} else {
				setLoadingCancelWithdrawal(true);

				const cancelWithdrawalRes = cancelBusinessWithdrawal(cookie, {
					unique_id: cancelWithdrawalUniqueId
				})

				cancelWithdrawalRes.then(res => {
					setLoadingCancelWithdrawal(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCancelWithdrawal(error);
							setTimeout(function () {
								setErrorCancelWithdrawal(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCancelWithdrawal(error);
							setTimeout(function () {
								setErrorCancelWithdrawal(null);
							}, 2000)
						}
					} else {
						setErrorCancelWithdrawal(null);
						setSuccessCancelWithdrawal(`Transaction cancelled successfully!`);

						setTimeout(function () {
							setSuccessCancelWithdrawal(null);
							setRemoveCancelWithdrawalModal(true);
							setCancelWithdrawalUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCancelWithdrawal(false);
				})

			}
		}
	};

	return {
		cookie, loadingCancelWithdrawal, removeCancelWithdrawalModal, cancelWithdrawalUniqueId, errorCancelWithdrawal, successCancelWithdrawal,
		handleCancelWithdrawal, setRemoveCancelWithdrawalModal, setCancelWithdrawalUniqueId
	};
};

const usePremiumUpgrade = () => {

	const { cookie } = useCookie(config.token, "");

	const [businessDetails, setBusinessDetails] = useState(null);
	const [premiumUpgradeReference, setPremiumUpgradeReference] = useState(null);

	const [loadingPremiumPackage, setLoadingPremiumPackage] = useState(false);
	const [loadingRetryPremiumPackageCheck, setLoadingRetryPremiumPackageCheck] = useState(false);
	const [removePremiumUpgradeModal, setRemovePremiumUpgradeModal] = useState(null);
	const [showSuccessfulPremiumUpgradeModal, setShowSuccessfulPremiumUpgradeModal] = useState(null);
	const [showUnsuccessfulPremiumUpgradeModal, setShowUnsuccessfulPremiumUpgradeModal] = useState(null);
	const [premiumPackage, setPremiumPackage] = useState("");
	const [months, setMonths] = useState(1);
	const [paymentMethod, setPaymentMethod] = useState("Credit/Debit Card");
	const [premiumAmount, setPremiumAmount] = useState(null);
	const [premiumPrice, setPremiumPrice] = useState(null);

	const [selectedPackage, setSelectedPackage] = useState(null);

	const [errorPremiumUpgrade, setErrorPremiumUpgrade] = useState(null);
	const [successPremiumUpgrade, setSuccessPremiumUpgrade] = useState(null);

	const [paystackConfig, setPaystackConfig] = useState({
		publicKey: config.paystackPublicApiKey,
		reference: "TXPP" + config.uuid(11)
	});

	const initializePayment = usePaystackPayment(paystackConfig);

	const handleMonths = (e) => { 
		e.preventDefault(); 
		setMonths(e.target.value); 
		setPremiumAmount(premiumPrice * parseInt(e.target.value)); 
		setPaystackConfig({
			...paystackConfig,
			amount: premiumPrice * parseInt(e.target.value) * 100,
		});
	};
	const handlePaymentMethod = (e) => { e.preventDefault(); setPaymentMethod(paymentMethod === "Credit/Debit Card" ? "Transfer" : "Credit/Debit Card"); };

	const handleSelectedPackage = (premium_package, index) => {
		setSelectedPackage(index);
		setPremiumPackage(premium_package.name);
		setPremiumAmount(parseInt(premium_package.price) * months);
		setPremiumPrice(premium_package.price); 
		setPaystackConfig({
			...paystackConfig,
			amount: premium_package.price * months * 100,
		});
	};
	const handleBusinessDetails = (details) => {
		setBusinessDetails(details);
		setPaystackConfig({
			...paystackConfig,
			email: details.email,
		});
	};

	const retryPremiumUpgradeCheck = (reference) => {
		if (!reference) {
			setErrorPremiumUpgrade("Premium Upgrade reference required");
		} else {
			setErrorPremiumUpgrade(null);
			setLoadingRetryPremiumPackageCheck(true);

			const completePremiumUpgradeRes = completePremiumUpgrade(cookie, {
				unique_id: reference
			})

			completePremiumUpgradeRes.then(res => {
				setLoadingRetryPremiumPackageCheck(false);
				if (res.err) {
					if (!res.error.response.data.success) {
						const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
						setErrorPremiumUpgrade(error);
					} else {
						const error = `${res.error.code} - ${res.error.message}`;
						setErrorPremiumUpgrade(error);
					}
				} else {
					setSuccessPremiumUpgrade(`Transaction completed successfully!`);
				}
			}).catch(err => {
				setLoadingRetryPremiumPackageCheck(false);
				const error = `An error occured`;
				setErrorPremiumUpgrade(error);
			})
		}
	};

	const retryPremiumUpgradeCheckAlt = (reference) => {
		setShowSuccessfulPremiumUpgradeModal(true);
		if (!reference) {
			setErrorPremiumUpgrade("Premium Upgrade reference required");
		} else {
			setErrorPremiumUpgrade(null);
			setLoadingRetryPremiumPackageCheck(true);

			const completePremiumUpgradeRes = completePremiumUpgrade(cookie, {
				unique_id: reference
			})

			completePremiumUpgradeRes.then(res => {
				setLoadingRetryPremiumPackageCheck(false);
				if (res.err) {
					if (!res.error.response.data.success) {
						const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
						setErrorPremiumUpgrade(error);
					} else {
						const error = `${res.error.code} - ${res.error.message}`;
						setErrorPremiumUpgrade(error);
					}
				} else {
					setSuccessPremiumUpgrade(`Transaction completed successfully!`);
				}
			}).catch(err => {
				setLoadingRetryPremiumPackageCheck(false);
				const error = `An error occured`;
				setErrorPremiumUpgrade(error);
			})
		}
	};

	const handlePremiumUpgradeSubmit = (e) => {
		e.preventDefault();

		if (!loadingPremiumPackage) {
			if (!premiumPackage) {
				setErrorPremiumUpgrade(null);
				setSuccessPremiumUpgrade(null);
				setErrorPremiumUpgrade("Package is required");
				setTimeout(function () {
					setErrorPremiumUpgrade(null);
				}, 2500)
			} else if (months < 1) {
				setErrorPremiumUpgrade(`Minimum of 1 month`);
				setTimeout(function () {
					setErrorPremiumUpgrade(null);
				}, 2500)
			} else if (months > 12) {
				setErrorPremiumUpgrade(`Minimum of 12 months`);
				setTimeout(function () {
					setErrorPremiumUpgrade(null);
				}, 2500)
			} else if (!paymentMethod) {
				setErrorPremiumUpgrade("Payment Method is Required");
				setTimeout(function () {
					setErrorPremiumUpgrade(null);
				}, 2500)
			} else if (!premiumAmount) {
				setErrorPremiumUpgrade("Unable to calculate total");
				setTimeout(function () {
					setErrorPremiumUpgrade(null);
				}, 2500)
			} else {
				setLoadingPremiumPackage(true);

				const addPremiumUpgradeRes = addPremiumUpgrade(cookie, {
					package: premiumPackage,
					months: parseInt(months),
					payment_method: paymentMethod
				})

				addPremiumUpgradeRes.then(res => {
					setLoadingPremiumPackage(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorPremiumUpgrade(error);
							setTimeout(function () {
								setErrorPremiumUpgrade(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorPremiumUpgrade(error);
							setTimeout(function () {
								setErrorPremiumUpgrade(null);
							}, 2000)
						}
					} else {
						setPremiumUpgradeReference(res.data.data.unique_id);
						
						const onPaystackSuccess = (reference) => {
							setShowSuccessfulPremiumUpgradeModal(true);
							const completePremiumUpgradeRes = completePremiumUpgrade(cookie, {
								unique_id: res.data.data.unique_id
							})

							completePremiumUpgradeRes.then(res => {
								if (res.err) {
									if (!res.error.response.data.success) {
										const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
										setErrorPremiumUpgrade(error);
									} else {
										const error = `${res.error.code} - ${res.error.message}`;
										setErrorPremiumUpgrade(error);
									}
								} else {
									setSuccessPremiumUpgrade(`Upgraded to premium successfully!`);
								}
							}).catch(err => {
								const error = `An error occured`;
								setErrorPremiumUpgrade(error);
							})
						};

						const onPaystackClose = () => {
							showUnsuccessfulPremiumUpgradeModal(true);
							const cancelPremiumUpgradeRes = cancelPremiumUpgrade(cookie, {
								unique_id: res.data.data.unique_id
							})

							cancelPremiumUpgradeRes.then(res => {
								if (res.err) {
									if (!res.error.response.data.success) {
										const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
										setErrorPremiumUpgrade(error);
									} else {
										const error = `${res.error.code} - ${res.error.message}`;
										setErrorPremiumUpgrade(error);
									}
								} else {
									setErrorPremiumUpgrade(`Premium upgrade cancelled!`);
								}
							}).catch(err => {
								const error = `An error occured`;
								setErrorPremiumUpgrade(error);
							})
						};

						setSuccessPremiumUpgrade(null);
						setRemovePremiumUpgradeModal(true);
						setMonths(1);
						setPaystackConfig({
							...paystackConfig,
							reference: res.data.data.unique_id
						});
						initializePayment(onPaystackSuccess, onPaystackClose);
					}
				}).catch(err => {
					setLoadingPremiumPackage(false);
				})

			}
		}
	};

	return {
		cookie, loadingPremiumPackage, premiumPackage, months, paymentMethod, errorPremiumUpgrade, successPremiumUpgrade,
		handleMonths, handlePaymentMethod, handlePremiumUpgradeSubmit, setRemovePremiumUpgradeModal, handleBusinessDetails, 
		removePremiumUpgradeModal, selectedPackage, handleSelectedPackage, premiumAmount, premiumPrice, setPremiumPrice, 
		showSuccessfulPremiumUpgradeModal, setShowSuccessfulPremiumUpgradeModal, showUnsuccessfulPremiumUpgradeModal, 
		setShowUnsuccessfulPremiumUpgradeModal, premiumUpgradeReference, retryPremiumUpgradeCheck, loadingRetryPremiumPackageCheck, 
		retryPremiumUpgradeCheckAlt, setErrorPremiumUpgrade, setSuccessPremiumUpgrade
	};
};

const useCancelPremiumUpgrade = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingCancelPremiumUpgrade, setLoadingCancelPremiumUpgrade] = useState(false);
	const [removeCancelPremiumUpgradeModal, setRemoveCancelPremiumUpgradeModal] = useState(null);
	const [cancelPremiumUpgradeUniqueId, setCancelPremiumUpgradeUniqueId] = useState(null);

	const [errorCancelPremiumUpgrade, setErrorCancelPremiumUpgrade] = useState(null);
	const [successCancelPremiumUpgrade, setSuccessCancelPremiumUpgrade] = useState(null);

	const handleCancelPremiumUpgrade = () => {

		if (!loadingCancelPremiumUpgrade) {
			if (!cancelPremiumUpgradeUniqueId) {
				setErrorCancelPremiumUpgrade(null);
				setSuccessCancelPremiumUpgrade(null);
				setErrorCancelPremiumUpgrade("Unique ID is required");
				setTimeout(function () {
					setErrorCancelPremiumUpgrade(null);
				}, 2500)
			} else {
				setLoadingCancelPremiumUpgrade(true);

				const cancelPremiumUpgradeRes = cancelPremiumUpgrade(cookie, {
					unique_id: cancelPremiumUpgradeUniqueId
				})

				cancelPremiumUpgradeRes.then(res => {
					setLoadingCancelPremiumUpgrade(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorCancelPremiumUpgrade(error);
							setTimeout(function () {
								setErrorCancelPremiumUpgrade(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorCancelPremiumUpgrade(error);
							setTimeout(function () {
								setErrorCancelPremiumUpgrade(null);
							}, 2000)
						}
					} else {
						setErrorCancelPremiumUpgrade(null);
						setSuccessCancelPremiumUpgrade(`Premium Upgrade cancelled successfully!`);

						setTimeout(function () {
							setSuccessCancelPremiumUpgrade(null);
							setRemoveCancelPremiumUpgradeModal(true);
							setCancelPremiumUpgradeUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingCancelPremiumUpgrade(false);
				})

			}
		}
	};

	return {
		cookie, loadingCancelPremiumUpgrade, removeCancelPremiumUpgradeModal, cancelPremiumUpgradeUniqueId, errorCancelPremiumUpgrade, successCancelPremiumUpgrade,
		handleCancelPremiumUpgrade, setRemoveCancelPremiumUpgradeModal, setCancelPremiumUpgradeUniqueId
	};
};

export { useAddDeposit, useCancelDeposit, usePremiumUpgrade, useCancelPremiumUpgrade, useAddWithdrawal, useCancelWithdrawal };