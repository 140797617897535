import axios from 'axios';
import { config } from '../config';

const getAppUsers = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/app/users`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const issueLoyaltyPoint = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/user/issue/loyalty/point`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const checkoutLoyaltyPoint = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/user/checkout/loyalty/point`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addAnnouncementList = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/announcement/list/add/user`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getAppUsers, issueLoyaltyPoint, checkoutLoyaltyPoint, addAnnouncementList };