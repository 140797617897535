import axios from 'axios';
import { config } from '../config';

const getPremiumPackages = async function () {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/premium/packages`,
			{

			},
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getSecretKey = async function () {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/secret/key/paystack`,
			{

			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getBanks = async function (key) {
	try {
		const response = await axios.get(
			`https://api.paystack.co/bank?currency=NGN`,
			{

			},
			{
				headers: {
					'Authorization': `Bearer ${key}`
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getBusinessProfilePhotoProof = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/business/profile/photo`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getBusinessProfileCoverProof = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/business/cover/photo`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getBusinessComplianceDocumentsProof = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/business/compliance/documents`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateProfilePhoto = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/profile/photo`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateProfileCover = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/profile/cover`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateName = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/name`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEmail = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/email`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updatePointThreshold = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/point/threshold`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateDescription = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/description`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateMasterToken = async function (token) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/update/token`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateBankAccount = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/bank/account`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceDetails = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/compliance/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data, response_code: response.status };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceCertificate = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/compliance/certificate`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceDocument = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/compliance/document`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateComplianceDocuments = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/compliance/documents`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const changePassword = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/password/change`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { 
	getPremiumPackages, updateComplianceCertificate, updateComplianceDetails, updateComplianceDocument, updateComplianceDocuments, 
	updateDescription, updateEmail, updateMasterToken, updateName, updateProfilePhoto, updateProfileCover, updatePointThreshold, changePassword, 
	getBusinessProfilePhotoProof, getBusinessComplianceDocumentsProof, getBusinessProfileCoverProof, updateBankAccount, getSecretKey, getBanks
};