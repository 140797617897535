import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Filter from "../icons/Filter";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getBusinessTransactions, getBusinessTransaction, getBusinessTransactionsViaStatus, getBusinessTransactionsViaType } from "../api/transactions";
import { getCompanyBankAccount } from "../api/business";
import { getPremiumPackages } from "../api/settings";
import Loading from "../icons/Loading";
import { useAddDeposit, useCancelDeposit, useCancelPremiumUpgrade, usePremiumUpgrade, useAddWithdrawal, useCancelWithdrawal } from "../hooks/useTransactions";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import { useGetBusiness } from "../hooks/useBusiness";
import EyeOpenAlt from "../icons/EyeOpenAlt";

export default function Transactions(){
    const { cookie, forceLogout } = useCookie(config.token, "");
    const [copiedAccountNumber, setCopiedAccountNumber] = useState(false);

    const [copiedText, setCopiedText] = useState(false);
    const [textCopied, setTextCopied] = useState(null);

    const { businessDetails, getBusinessDetails } = useGetBusiness();

    const [premiumPackages, setPremiumPackages] = useState(null);
    const [errorPremiumPackages, setErrorPremiumPackages] = useState(null);
    const [loadingPremiumPackages, setLoadingPremiumPackages] = useState(false);

    const { 
        errorPremiumUpgrade, handleMonths, handlePremiumUpgradeSubmit, selectedPackage, loadingRetryPremiumPackageCheck, 
        loadingPremiumPackage, months, successPremiumUpgrade, removePremiumUpgradeModal, premiumAmount, handleBusinessDetails, 
        setRemovePremiumUpgradeModal, handleSelectedPackage, retryPremiumUpgradeCheckAlt, setShowSuccessfulPremiumUpgradeModal, 
        premiumUpgradeReference, retryPremiumUpgradeCheck, showSuccessfulPremiumUpgradeModal, showUnsuccessfulPremiumUpgradeModal, 
        setShowUnsuccessfulPremiumUpgradeModal, setErrorPremiumUpgrade, setSuccessPremiumUpgrade, premiumPackage
    } = usePremiumUpgrade();

    const {
        errorCancelPremiumUpgrade, handleCancelPremiumUpgrade, setCancelPremiumUpgradeUniqueId, loadingCancelPremiumUpgrade,
        removeCancelPremiumUpgradeModal, setRemoveCancelPremiumUpgradeModal, successCancelPremiumUpgrade
    } = useCancelPremiumUpgrade();

    const {
        errorAddDeposit, fundingAmount, fundingPaymentMethod, handleFundingAmount, handleFundingPaymentMethod,
        handleSubmit: DepositHandleSubmit, loading: DepositLoading, removeFundingModal, successAddDeposit, setRemoveFundingModal,
        showSuccessfulDepositModal, showUnsuccessfulDepositModal, setErrorAddDeposit, setSuccessAddDeposit,
        setShowSuccessfulDepositModal, setShowUnsuccessfulDepositModal, depositReference, retryDepositCheck,
        loadingRetryDepositCheck, retryDepositCheckAlt
    } = useAddDeposit();

    const {
        errorCancelDeposit, handleCancelDeposit, setCancelDepositUniqueId, loadingCancelDeposit, 
        removeCancelDepositModal, setRemoveCancelDepositModal, successCancelDeposit
    } = useCancelDeposit();

    const {
        errorAddWithdrawal, withdrawalAmount, withdrawalPaymentMethod, handleWithdrawalAmount, handleWithdrawalPaymentMethod,
        handleSubmit: WithdrawalHandleSubmit, loading: WithdrawalLoading, removeWithdrawalModal, successAddWithdrawal, setRemoveWithdrawalModal,
        showSuccessfulWithdrawalModal, showUnsuccessfulWithdrawalModal, setErrorAddWithdrawal, setSuccessAddWithdrawal,
        setShowSuccessfulWithdrawalModal, setShowUnsuccessfulWithdrawalModal, handleBusinessDetails: WithdrawalHandleBusinessDetails
    } = useAddWithdrawal();

    const {
        errorCancelWithdrawal, handleCancelWithdrawal, setCancelWithdrawalUniqueId, loadingCancelWithdrawal,
        removeCancelWithdrawalModal, setRemoveCancelWithdrawalModal, successCancelWithdrawal
    } = useCancelWithdrawal();

    const validateExpiration = (expiration) => {
        const d = new Date(expiration);
        const today = new Date();
        today.toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
        if (d === "Invalid Date") return false;
        if (today.getTime() > d.getTime()) return false;
        return true;
    };

    const [paymentDetails, setPaymentDetails] = useState(null);

    const [filterType, setFilterType] = useState(null);
    const [companyBankDetails, setCompanyBankDetails] = useState(null);
    const [allTransactions, setAllTransactions] = useState(null);
    const [errorTransactions, setErrorTransactions] = useState(null);
    const [loadingAllTransactions, setLoadingAllTransactions] = useState(false);

    const [size, setSize] = useState(50);
    const [page, setPage] = useState(1);

    const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); if (!filterType) getAllTransactions(page, e.target.value); if (filterType) getAllTypeTransactions(filterType, page, e.target.value); };
    const handleFilterType = (e) => { e.preventDefault(); setFilterType(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") getAllTypeTransactions(e.target.value, page, size); if (e.target.value === null || e.target.value === "Select to Reset") getAllTransactions(page, size); };

    async function previousTransactions() {
        if (page !== 1) setPage(page - 1);
        if (page !== 1) getAllTransactions(page - 1, size);
    };

    async function nextTransactions() {
        if (page < allTransactions.data.pages) setPage(page + 1);
        if (page < allTransactions.data.pages) getAllTransactions(page + 1, size);
    };

    async function getCompanyBankAccountAlt() {
        const response = await getCompanyBankAccount();
        setCompanyBankDetails(response.data);
    };
    async function getAllTransactions(_page, _size) {
        setLoadingAllTransactions(true);
        const response = await getBusinessTransactions(cookie, (_page || page), (_size || size));
        setAllTransactions(response.data);
        if (response.error) setErrorTransactions(response.error.response.data.message);
        setLoadingAllTransactions(false);
    };
    async function getAllTypeTransactions(type, _page, _size) {
        setLoadingAllTransactions(true);
        const response = await getBusinessTransactionsViaType(cookie, (_page || page), (_size || size), ({ type: type }));
        setAllTransactions(response.data);
        if (response.error) setErrorTransactions(response.error.response.data.message);
        setLoadingAllTransactions(false);
    };
    async function _getPremiumPackages() {
        setLoadingPremiumPackages(true);
        const response = await getPremiumPackages(cookie);
        setPremiumPackages(response.data);
        if (response.error) setErrorPremiumPackages(response.error.response.data.message);
        setLoadingPremiumPackages(false);
    };

    useEffect(() => {
        if (companyBankDetails === null) {
            getCompanyBankAccountAlt();
        }
        if (allTransactions === null) {
            getAllTransactions();
        }
    }, [allTransactions, companyBankDetails]);

    if (removePremiumUpgradeModal) {
        const modalResponse = document.querySelector("#upgradePlan");
        modalResponse.setAttribute("display", false);
        getAllTransactions();
        setRemovePremiumUpgradeModal(null);
    }
    if (showUnsuccessfulPremiumUpgradeModal) {
        const modalResponse = document.querySelector("#upgradePlanUnsuccessful");
        modalResponse.setAttribute("display", true);
    }
    if (showSuccessfulPremiumUpgradeModal) {
        const modalResponse = document.querySelector("#upgradePlanSuccessful");
        modalResponse.setAttribute("display", true);
    }
    if (removeCancelPremiumUpgradeModal) {
        const modalResponse = document.querySelector("#confirmPremiumUpgradeCancellation");
        modalResponse.setAttribute("display", false);
        getAllTransactions();
        setRemoveCancelPremiumUpgradeModal(null);
    }

    if (removeFundingModal) {
        const modalResponse = document.querySelector("#fundWallet");
        modalResponse.setAttribute("display", false);
        getAllTransactions();
        setRemoveFundingModal(null);
    }
    if (showUnsuccessfulDepositModal) {
        const modalResponse = document.querySelector("#depositUnsuccessful");
        modalResponse.setAttribute("display", true);
    }
    if (showSuccessfulDepositModal) {
        const modalResponse = document.querySelector("#depositSuccessful");
        modalResponse.setAttribute("display", true);
    }
    if (removeCancelDepositModal) {
        const modalResponse = document.querySelector("#confirmCancellation");
        modalResponse.setAttribute("display", false);
        getAllTransactions();
        setRemoveCancelDepositModal(null);
    }

    if (removeWithdrawalModal) {
        const modalResponse = document.querySelector("#withdrawalModal");
        modalResponse.setAttribute("display", false);
        getAllTransactions();
        setRemoveWithdrawalModal(null);
    }
    if (showSuccessfulWithdrawalModal) {
        const modalResponse = document.querySelector("#withdrawalSuccessful");
        modalResponse.setAttribute("display", true);
    }
    if (removeCancelWithdrawalModal) {
        const modalResponse = document.querySelector("#confirmWithdrawalCancellation");
        modalResponse.setAttribute("display", false);
        getAllTransactions();
        setRemoveCancelWithdrawalModal(null);
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
    };

    const copyAccountNumber = (accountNumber) => {
        copyText(accountNumber);
        setCopiedAccountNumber(true);
        setTimeout(function () {
            setCopiedAccountNumber(false);
        }, 2000)
    };

    const copySomeText = (text) => {
        copyText(text);
        setCopiedText(true);
        setTimeout(function () {
            setCopiedText(false);
        }, 2000)
    };

    return(
        <>
            <Screen aside="false" navbar="false">
                <Content>
                    <Navbar placeholder="Search something..." makeHidden={true} />
                    <section className="xui-d-flex xui-flex-jc-flex-start xui-lg-flex-jc-flex-end">
                        {
                            !businessDetails ? <Loading width="12" height="12" /> :
                                (
                                    <>
                                        <div>
                                            <p className="xui-opacity-5 xui-font-sz-80">Current Premium Plan</p>
                                            { !businessDetails.success ? "Error" :
                                                <>
                                                    <span className="xui-d-inline-block xui-font-sz-120 xui-mt-half">{businessDetails.data.premium.type}</span><br></br>
                                                    {
                                                        validateExpiration(businessDetails.data.premium.expiring.date + " " + businessDetails.data.premium.expiring.time) ? 
                                                            <>
                                                                <span className="xui-d-inline-block xui-font-sz-90 xui-mt-half">{"Expiring - " + businessDetails.data.premium.expiring.fulldate}</span><br></br>
                                                                <span className="xui-d-inline-block xui-font-sz-80 xui-mt-half">{"Offers left - " + (businessDetails.data.premium.offers === true ? "Unlimited" : businessDetails.data.premium.offers.toLocaleString())}</span><br></br>
                                                                <span className="xui-d-inline-block xui-font-sz-80 xui-mt-half">{"Announcements left - " + (businessDetails.data.premium.announcements === true ? "Unlimited" : businessDetails.data.premium.announcements.toLocaleString())}</span><br></br>
                                                                <span className="xui-d-inline-block xui-font-sz-80 xui-mt-half">{"Issued Points left - " + (businessDetails.data.premium.customer_issued_points === true ? "Unlimited" : businessDetails.data.premium.customer_issued_points.toLocaleString())}</span>
                                                            </> :
                                                            <>
                                                                <span className='xui-badge xui-badge-danger xui-font-sz-90 xui-bdr-rad-half xui-mt-1'>Plan Expired</span><br></br>
                                                            </>
                                                    }
                                                    {
                                                        validateExpiration(businessDetails.data.premium.expiring.date + " " + businessDetails.data.premium.expiring.time) && businessDetails.data.premium.type !== "Free" ? 
                                                            <div className="xui-d-flex xui-mt-1 xui-font-sz-80 xui-lg-d-none">
                                                                <button disabled className="xui-font-sz-80 xui-btn psc-btn-green">Active</button>
                                                            </div> : 
                                                            <div className="xui-d-flex xui-mt-1 xui-font-sz-80 xui-lg-d-none">
                                                                <button disabled={!businessDetails} className="xui-font-sz-80 xui-btn psc-btn-red" xui-modal-open="upgradePlan" onClick={() => { handleSelectedPackage("", null); handleBusinessDetails(!businessDetails ? null : businessDetails.data); _getPremiumPackages(); }}>Upgrade</button>
                                                            </div>
                                                    }
                                                </> 
                                            }  
                                        </div>
                                        {
                                            validateExpiration(businessDetails.data.premium.expiring.date + " " + businessDetails.data.premium.expiring.time) && businessDetails.data.premium.type !== "Free" ?
                                                <div className="xui-ml-2 xui-d-none xui-lg-d-block">
                                                    <button disabled className="xui-font-sz-80 xui-btn psc-btn-green">Active</button>
                                                </div> :
                                                <div className="xui-ml-2 xui-d-none xui-lg-d-block">
                                                    <button disabled={!businessDetails} className="xui-font-sz-80 xui-btn psc-btn-red" xui-modal-open="upgradePlan" onClick={() => { handleSelectedPackage("", null); handleBusinessDetails(!businessDetails ? null : businessDetails.data); _getPremiumPackages(); }}>Upgrade</button>
                                                </div>
                                        }
                                    </>
                                )
                        }
                    </section>
                    <section className="xui-d-grid xui-lg-grid-col-2 xui-grid-gap-2 xui-mt-2">
                        <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                            <h3 className="xui-font-sz-100 xui-font-w-normal xui-mt-half">Wallet</h3>
                            <span className="xui-font-sz-75 xui-opacity-5">This is your Passcoder wallet balance</span>
                            <hr className="xui-opacity-4 xui-my-1" />
                            <p className="xui-d-inline-block xui-font-sz-150 xui-mt-half xui-mb-2">
                                {
                                    !businessDetails ? <Loading width="12" height="12" /> : 
                                    (
                                        businessDetails && businessDetails.success ? 
                                            <>
                                                <span>NGN {businessDetails.data.balance.toLocaleString()}</span>
                                                {
                                                    businessDetails ?
                                                        <span className="xui-cursor-pointer xui-ml-1" onClick={() => { getBusinessDetails() }}>
                                                            <Reset width="16" height="16" />
                                                        </span> :
                                                        <span className="xui-cursor-pointer xui-ml-1">
                                                            <Loading width="16" height="16" />
                                                        </span>
                                                }
                                            </> :
                                            "Error"
                                    )
                                }
                            </p>
                            <h3 className="xui-font-sz-100 xui-font-w-normal xui-mt-half xui-mt-2">Passcoder ID:
                                {businessDetails ? 
                                    (
                                        businessDetails.data.verified ?
                                            <div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1'>
                                                <span>{businessDetails.data.pid}</span>
                                                <span title="Copy PID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copySomeText(businessDetails.data.pid); setTextCopied(businessDetails.data.pid); }}>
                                                    {copiedText && textCopied === businessDetails.data.pid ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
                                                </span>
                                            </div> : 
                                            " XXXXXX"
                                    )
                                    : " XXXXXX"}
                            </h3>
                            <h3 className="xui-font-sz-100 xui-font-w-normal xui-mt-half xui-mb-1">Your Bank Details</h3>
                            {/* <hr className="xui-opacity-4 xui-my-1" /> */}
                            {
                                !businessDetails ? <Loading width="12" height="12" /> :
                                    (
                                        businessDetails && businessDetails.success ? 
                                        <>
                                            <p className="xui-font-sz-75 xui-opacity-5">Account Name</p>
                                            <h3 className="xui-font-sz-100 xui-font-w-normal xui-mt-half">{businessDetails.data.account_name}</h3>
                                            <div className="xui-d-grid xui-grid-col-2 xui-grid-gap-2 xui-mt-1">
                                                <div>
                                                    <span className="xui-font-sz-75 xui-opacity-5">Bank Name</span>
                                                    <h3 className="xui-font-sz-90 xui-font-w-normal xui-mt-half">{businessDetails.data.bank}</h3>
                                                </div>
                                                <div>
                                                    <span className="xui-font-sz-75 xui-opacity-5">Account Number</span>
                                                    <h3 className="xui-font-sz-90 xui-font-w-normal xui-mt-half">
                                                        {businessDetails.data.account_number}
                                                    </h3>
                                                </div>
                                            </div>
                                        </>
                                        : "Error"
                                    )
                            }
                            <button disabled={businessDetails ? !businessDetails.data.verified : true} className="xui-btn psc-btn-blue xui-font-sz-80 xui-mt-2" onClick={() => WithdrawalHandleBusinessDetails(!businessDetails ? null : businessDetails.data)} xui-modal-open={businessDetails ? (!businessDetails.data.verified ? "" : "withdrawalModal") : ""}>Withdraw</button>
                        </div>
                        {
                            !companyBankDetails ?
                                <center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
                                (
                                    companyBankDetails && companyBankDetails.success ? 
                                    <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                        <h3 className="xui-font-sz-100 xui-font-w-normal xui-mt-half xui-mb-half">Transfer Details</h3>
                                        <span className="xui-font-sz-75 xui-opacity-5">Account Name</span>
                                        <h3 className="xui-font-sz-100 xui-font-w-normal xui-mt-half">{companyBankDetails.data.acc_name}</h3>
                                        <div className="xui-d-grid xui-grid-col-2 xui-grid-gap-2 xui-mt-1">
                                            <div>
                                                <span className="xui-font-sz-75 xui-opacity-5">Bank Name</span>
                                                <h3 className="xui-font-sz-90 xui-font-w-normal xui-mt-half">{companyBankDetails.data.acc_bank}</h3>
                                            </div>
                                            <div>
                                                <span className="xui-font-sz-75 xui-opacity-5">Account Number</span>
                                                <h3 className="xui-font-sz-90 xui-font-w-normal xui-mt-half">
                                                    {companyBankDetails.data.acc_number}
                                                    {
                                                        companyBankDetails ?
                                                            <span className="xui-cursor-pointer xui-ml-1" onClick={() => { if (companyBankDetails) copyAccountNumber(companyBankDetails.data.acc_number); }}>
                                                                {copiedAccountNumber ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
                                                            </span> :
                                                            ""
                                                    }
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="xui-mt-2">
                                            <h4 className="xui-font-sz-85 xui-mb-1 xui-font-w-normal xui-opacity-8">Steps to upgrade to premium via transfer</h4>
                                            <ol className="xui-font-sz-80 xui-opacity-5 xui-my-half">
                                                <li>See the packages in your settings, choose the one you want to upgrade to, determine the number of months you want this to be active (12 months max).</li><br></br>
                                                <li>Copy the account number & Transfer the amount times the number of months you want (i.e. package price x month = total amount).</li><br></br>
                                                <li>Send an email to <a href="mailto:support@passcoder.io">support@passcoder.io</a> with your receipt of payment, package selected and await confirmation.</li>
                                            </ol>
                                        </div>
                                    </div> :
                                    <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                        <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                            <center className="xui-text-red">
                                                <Close width="100" height="100" />
                                                <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{companyBankDetails ? companyBankDetails.message : "..."}</h3>
                                            </center>
                                        </div>
                                    </div>
                                )
                        }
                        {/* <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                            <h3 className="xui-font-sz-100 xui-font-w-normal xui-mt-half">Billing Account</h3>
                            <span className="xui-font-sz-75 xui-opacity-5">Set up account for direct debit for when wallet is low</span>
                            <hr className="xui-opacity-4 xui-my-1" />
                            <h2 className="xui-font-w-normal xui-font-sz-125 xui-text-center xui-mx-auto xui-w-fluid-90 xui-my-2">You have not added any card yet</h2>
                            <button className="xui-btn psc-btn-blue xui-font-sz-80 xui-mt-2">Add backup payment method</button>
                        </div> */}
                    </section>
                    <section className='xui-mt-2'>
                        <div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
                            <div className="xui-mb-1">
                                <h1 className='xui-font-sz-110 xui-font-w-normal'>All Transactions</h1>
                                <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View your transaction history</p>
                            </div>
                            {/* <div className="xui-mb-1">
                                <div className='xui-d-inline-flex'>
                                    <div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
                                        <Filter width="16" height="16" />
                                        <select value={filterType} onChange={handleFilterType} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
                                            {
                                                !filterType ?
                                                <option selected disabled>Filter By Type</option> :
                                                <option value={null}>Select to Reset</option>
                                            }
                                            <option value={"Deposit"}>Deposit</option>
                                            <option value={"API Call"}>API Call</option>
                                            <option value={"Subscription"}>Subscription</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {
                            loadingAllTransactions ?
                                <center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
                                (
                                    allTransactions && allTransactions.success ?
                                    <div className='xui-table-responsive'>
                                        <table className='xui-table xui-font-sz-90'>
                                            <thead>
                                                <tr className='xui-text-left xui-opacity-6'>
                                                    <th className='xui-min-w-20'>S/N</th>
                                                    <th className='xui-min-w-150'>Reference</th>
                                                    <th className='xui-min-w-100'>Type</th>
                                                    <th className='xui-min-w-200'>Payment Method</th>
                                                    <th className='xui-min-w-200'>Amount</th>
                                                    <th className='xui-min-w-150'>Status</th>
                                                    <th className='xui-min-w-300'>Date</th>
                                                    <th className='xui-min-w-150'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allTransactions.data.rows.map((data, i) => (
                                                    <tr className='' key={i}>
                                                        <td className='xui-opacity-5'>
                                                            <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                                {i + 1}
                                                            </div>
                                                        </td>
                                                        <td className='xui-opacity-5'>
                                                            <span>#{data.unique_id}</span>
                                                        </td>
                                                        <td className='xui-opacity-5'>
                                                            <span>{data.type}</span>
                                                        </td>
                                                        <td className='xui-opacity-5'>
                                                            <span>{data.payment_method ? data.payment_method : "Not found"}</span>
                                                        </td>
                                                        <td className='xui-opacity-5 xui-font-w-bold'>
                                                            <span>{data.amount === 0 ? "Free" : "NGN " + data.amount.toLocaleString()}</span>
                                                        </td>
                                                        <td className=''>
                                                            {
                                                                data.transaction_status === "Completed" ?
                                                                    <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{data.transaction_status}</span> : ""
                                                            }
                                                            {
                                                                data.transaction_status === "Processing" ?
                                                                    <span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>{data.transaction_status}</span> : ""
                                                            }
                                                            {
                                                                data.transaction_status === "Cancelled" ?
                                                                    <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.transaction_status}</span> : ""
                                                            }
                                                        </td>
                                                        <td className='xui-opacity-5'>
                                                            <span>{data.updatedAt.date} at {data.updatedAt.time}</span>
                                                        </td>
                                                        <td className=''>
                                                            <div className="xui-d-flex xui-grid-gap-1">
                                                                {
                                                                    data.transaction_status === "Processing" && data.type === "Subscription" && data.payment_method === "Credit/Debit Card" ?
                                                                        <button title="Check Premium Upgrade Transaction" onClick={() => { retryPremiumUpgradeCheckAlt(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue-alt xui-bdr-rad-half xui-font-sz-50">
                                                                            <Reset width="20" height="20" />
                                                                        </button> :
                                                                        ""
                                                                }
                                                                {
                                                                    data.transaction_status === "Processing" && data.type === "Deposit" && data.payment_method === "Credit/Debit Card" ?
                                                                        <button title="Check Transaction" onClick={() => { retryDepositCheckAlt(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue-alt xui-bdr-rad-half xui-font-sz-50">
                                                                            <Reset width="20" height="20" />
                                                                        </button> :
                                                                        ""
                                                                }
                                                                {
                                                                    data.transaction_status === "Processing" && data.type === "Deposit" ?
                                                                        <button title="Cancel Transaction" onClick={() => { setCancelDepositUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="confirmCancellation">
                                                                            <Cancel width="20" height="20" />
                                                                        </button> : 
                                                                        ""
                                                                }
                                                                {
                                                                    data.transaction_status === "Processing" && data.type === "Withdrawal" ?
                                                                        <button title="Cancel Withdrawal" onClick={() => { setCancelWithdrawalUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="confirmWithdrawalCancellation">
                                                                            <Cancel width="20" height="20" />
                                                                        </button> :
                                                                        ""
                                                                }
                                                                {
                                                                    data.transaction_status === "Processing" && data.type === "Subscription" ?
                                                                        <button title="Cancel Premium Upgrade Transaction" onClick={() => { setCancelPremiumUpgradeUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="confirmPremiumUpgradeCancellation">
                                                                            <Cancel width="20" height="20" />
                                                                        </button> :
                                                                        ""
                                                                }
                                                                {
                                                                    data.type === "Payment" || data.type === "Credit" ?
                                                                        <button title="View Transaction Details"
                                                                            onClick={() => {
                                                                                setPaymentDetails({
                                                                                    unique_id: data.unique_id,
                                                                                    amount: data.amount.toLocaleString(),
                                                                                    transaction_status: data.transaction_status,
                                                                                    details: data.details,
                                                                                    reference: data.reference ? data.reference : "No reference",
                                                                                    timestamp: `${data.updatedAt.date} at ${data.updatedAt.time}`
                                                                                });
                                                                            }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="paymentDetailsModal">
                                                                            <EyeOpenAlt width="20" height="20" />
                                                                        </button> :
                                                                        ""
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> :
                                    <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                        <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                            <center className="xui-text-red">
                                                <Close width="100" height="100" />
                                                <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorTransactions}</h3>
                                            </center>
                                        </div>
                                    </div>
                                )
                        }
                        {
                            loadingAllTransactions ?
                                <Loading width="12" height="12" /> :
                                (
                                    allTransactions && allTransactions.success ?
                                        <div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
                                            <div className='xui-d-inline-flex xui-flex-ai-center'>
                                                <span>Rows per page:</span>
                                                <select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={500}>500</option>
                                                    <option value={1000}>1000</option>
                                                </select>
                                            </div>
                                            <div className='xui-mx-1 xui-lg-mx-2'>
                                            <span><span className='xui-font-w-bold'>{page}</span> of {allTransactions ? allTransactions.data.pages : "..."}</span>
                                            </div>
                                            <div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
                                                <div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousTransactions}>
                                                    <Arrowleft width="18" height="18" />
                                                </div>
                                                <div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextTransactions}>
                                                    <Arrowright width="18" height="18" />
                                                </div>
                                            </div>
                                        </div> :
                                        ""
                                )
                        }
                    </section>
                </Content>
            </Screen>
            <section className='xui-modal' xui-modal="fundWallet" id="fundWallet">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="fundWallet">
                        <Close width="24" height="24" />
                    </div>
                    <h1>Fund Wallet</h1>
                    <form className="xui-form" onSubmit={DepositHandleSubmit}>
                        <div className="xui-form-box">
                            <label>Enter amount <span className="xui-font-w-bold">(NGN)</span></label>
                            <input required onChange={handleFundingAmount} value={fundingAmount} type={"number"} />
                            {
                                fundingPaymentMethod === "Credit/Debit Card" ?
                                    <label className="xui-mt-1">Charge - <span className="xui-font-w-bold xui-mt-1">NGN {config.charge_price * 2}</span></label> :
                                    null
                            }
                        </div>
                        <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly">
                            <div className="xui-d-inline-flex xui-flex-ai-center">
                                <input required type="radio" onChange={handleFundingPaymentMethod} checked={fundingPaymentMethod === "Credit/Debit Card"} id="credit_card" />
                                <label for="credit_card" className="xui-ml-half" style={{ marginBottom: '0' }}>Card</label>
                            </div>
                            <div className="xui-d-inline-flex xui-flex-ai-center">
                                <input required type="radio" onChange={handleFundingPaymentMethod} checked={fundingPaymentMethod === "Transfer"} id="transfer" />
                                <label for="transfer" className="xui-ml-half" style={{ marginBottom: '0' }}>Transfer</label>
                            </div>
                        </div>
                        <div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Top Up</span>
                                {
                                    DepositLoading ?
                                        <Loading width="12" height="12" />
                                        : <Arrowright width="12" height="12" />
                                }
                            </button>
                        </div>
                    </form>
                    <p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddDeposit}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddDeposit}</span></p>
                </div>
            </section>
            <section className='xui-modal' xui-modal="depositSuccessful" id="depositSuccessful">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    {
                        !successAddDeposit && !errorAddDeposit ?
                            <center className="xui-form-box">
                                <Loading width="12" height="12" />
                            </center> : ""
                    }
                    {
                        successAddDeposit ?
                            <>
                                <center>
                                    <h1>Deposit Successful</h1>
                                    <img className="xui-img-100 xui-my-2" src={SuccessTick} alt="" />
                                </center>
                                <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddDeposit}</span></p>
                            </> : ""
                    }
                    {
                        errorAddDeposit ?
                            <>
                                <center>
                                    <h1>Deposit not successful</h1>
                                </center>
                                <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddDeposit}</span></p>
                            </> : ""
                    }
                    {
                        errorAddDeposit ?
                            <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-between">
                                <div className="xui-d-inline-flex xui-flex-ai-center">
                                    <button onClick={() => { retryDepositCheck(depositReference) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue-alt xui-bdr-rad-half xui-font-sz-85">
                                        {
                                            loadingRetryDepositCheck ?
                                                <Loading width="12" height="12" /> :
                                                <Reset width="20" height="20" />
                                        }
                                        <span className="xui-ml-half">Retry</span>
                                    </button>
                                </div>
                                <div className="xui-d-inline-flex xui-flex-ai-center">
                                    <button onClick={() => { setErrorAddDeposit(null); setSuccessAddDeposit(null); setShowSuccessfulDepositModal(null); getAllTransactions(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" xui-modal-close="depositSuccessful">
                                        <span className="xui-mr-half">Continue</span>
                                        <Arrowright width="20" height="20" />
                                    </button>
                                </div>
                            </div> :
                            <div className="xui-d-flex xui-flex-jc-flex-end xui-mt-1">
                                <button onClick={() => { setErrorAddDeposit(null); setSuccessAddDeposit(null); setShowSuccessfulDepositModal(null); getAllTransactions(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" xui-modal-close="depositSuccessful">
                                    <span className="xui-mr-half">Continue</span>
                                    <Arrowright width="20" height="20" />
                                </button>
                            </div>
                    }
                </div>
            </section>
            <section className='xui-modal' xui-modal="depositUnsuccessful" id="depositUnsuccessful">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Deposit not successful</h1>
                    </center>
                    {
                        !errorAddDeposit ?
                            <center className="xui-mt-1">
                                <Loading width="12" height="12" />
                            </center> :
                            <p className="xui-font-sz-110 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddDeposit}</span></p>
                    }
                    <div className="xui-d-flex xui-flex-jc-flex-end xui-mt-1">
                        <button disabled={!errorAddDeposit} onClick={() => { setErrorAddDeposit(null); setShowUnsuccessfulDepositModal(null); getAllTransactions(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" xui-modal-close="depositUnsuccessful">
                            <span className="xui-mr-half">Continue</span>
                            <Arrowright width="20" height="20" />
                        </button>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="withdrawalModal" id="withdrawalModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="withdrawalModal">
                        <Close width="24" height="24" />
                    </div>
                    <h1>Withdraw funds</h1>
                    <form className="xui-form" onSubmit={WithdrawalHandleSubmit}>
                        <div className="xui-form-box">
                            <label>Enter amount <span className="xui-font-w-bold">(NGN)</span></label>
                            <input required onChange={handleWithdrawalAmount} value={withdrawalAmount} type={"number"} />
                        </div>
                        {/* <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly">
                            <div className="xui-d-inline-flex xui-flex-ai-center">
                                <input required type="radio" onChange={handleWithdrawalPaymentMethod} checked={withdrawalPaymentMethod === "Credit/Debit Card"} id="credit_card" />
                                <label for="credit_card" className="xui-ml-half" style={{ marginBottom: '0' }}>Card</label>
                            </div>
                            <div className="xui-d-inline-flex xui-flex-ai-center">
                                <input required type="radio" onChange={handleWithdrawalPaymentMethod} checked={withdrawalPaymentMethod === "Transfer"} id="transfer" />
                                <label for="transfer" className="xui-ml-half" style={{ marginBottom: '0' }}>Transfer</label>
                            </div>
                        </div> */}
                        <label>Payment method</label>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <input required type="radio" disabled onChange={handleWithdrawalPaymentMethod} checked={withdrawalPaymentMethod === "Transfer"} id="transfer" />
                            <label for="transfer" className="xui-ml-half" style={{ marginBottom: '0' }}>Transfer</label>
                        </div>
                        <div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Withdraw</span>
                                {
                                    WithdrawalLoading ?
                                        <Loading width="12" height="12" />
                                        : <Arrowright width="12" height="12" />
                                }
                            </button>
                        </div>
                    </form>
                    <p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddWithdrawal}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddWithdrawal}</span></p>
                </div>
            </section>
            <section className='xui-modal' xui-modal="withdrawalSuccessful" id="withdrawalSuccessful">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    {
                        !successAddWithdrawal && !errorAddWithdrawal ?
                            <center className="xui-form-box">
                                <Loading width="12" height="12" />
                            </center> : ""
                    }
                    {
                        successAddWithdrawal ?
                            <>
                                <center>
                                    <h1>Withdrawal request successful</h1>
                                    <img className="xui-img-100 xui-my-2" src={SuccessTick} alt="" />
                                </center>
                                <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddWithdrawal}</span></p>
                            </> : ""
                    }
                    {
                        errorAddWithdrawal ?
                            <>
                                <center>
                                    <h1>Withdrawal request not successful</h1>
                                </center>
                                <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddWithdrawal}</span></p>
                            </> : ""
                    }
                    <div className="xui-d-flex xui-flex-jc-flex-end xui-mt-1">
                        <button onClick={() => { setErrorAddWithdrawal(null); setSuccessAddWithdrawal(null); setShowSuccessfulWithdrawalModal(null); getAllTransactions(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" xui-modal-close="withdrawalSuccessful">
                            <span className="xui-mr-half">Continue</span>
                            <Arrowright width="20" height="20" />
                        </button>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="paymentDetailsModal" id="paymentDetailsModal">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => setPaymentDetails(null)} xui-modal-close="paymentDetailsModal">
                        <Close width="24" height="24" />
                    </div>
                    <center>
                        <h1>#{paymentDetails?.unique_id}</h1>
                        <p className="xui-opacity-5 xui-font-sz-100 xui-mt-1">{paymentDetails?.details}</p>
                    </center>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-1">
                        <div className="">
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Amount</p>
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Reference</p>
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Transaction Status</p>
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">Timestamp</p>
                        </div>
                        <div className="">
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">NGN {paymentDetails?.amount}</p>
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{paymentDetails?.reference}</p>
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{paymentDetails?.transaction_status}</p>
                            <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half">{paymentDetails?.timestamp}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="upgradePlan" id="upgradePlan">
                <div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
                    <div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="upgradePlan">
                        <Close width="24" height="24" />
                    </div>
                    <h1>Premium Upgrade</h1>
                    <span className="xui-font-sz-90 xui-opacity-5">Select your preferred package, determine the duration (months) and pay via Credit/Debit Card</span>
                    <section className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                        {
                            loadingPremiumPackages ?
                                <center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
                                (
                                    premiumPackages && premiumPackages.success ?
                                        <section className="xui-d-grid xui-lg-grid-col-3 xui-grid-gap-2 xui-mt-1">
                                            {
                                                premiumPackages.data.map((data, i) => (
                                                    (
                                                        data.name === "Free" ? "" : 
                                                        <div onClick={() => handleSelectedPackage(data, i)} className={`xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1 xui-cursor-pointer ${selectedPackage === i ? "xui-bg-blue xui-text-white" : ""}`}>
                                                            <h3 className="xui-font-sz-100 xui-font-w-bold xui-mt-half">{data.name}</h3>
                                                            <hr className="xui-opacity-4 xui-my-1" />
                                                            <h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Offers - {data.offers === true ? "Unlimited" : data.offers.toLocaleString()}</h2>
                                                            <h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Announcements - {data.announcements === true ? "Unlimited" : data.announcements.toLocaleString()}</h2>
                                                            <h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Issued Points - {data.customer_issued_points === true ? "Unlimited" : data.customer_issued_points.toLocaleString()}</h2>
                                                            {/* <h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Export to Excel - 
                                                                {
                                                                    data.export_to_excel ?
                                                                        <span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half xui-mx-1'>{"Yes"}</span> :
                                                                        <span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half xui-mx-1'>{"No"}</span>
                                                                }
                                                            </h2> */}
                                                            <h2 className="xui-font-w-bold xui-text-center xui-font-sz-100 xui-mx-auto xui-w-fluid-100 xui-mt-2">NGN {data.price.toLocaleString()}</h2>
                                                        </div>
                                                    )
                                                ))
                                            }
                                        </section> :
                                        <div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
                                            <div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
                                                <center className="xui-text-red">
                                                    <Close width="100" height="100" />
                                                    <h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorPremiumPackages}</h3>
                                                </center>
                                            </div>
                                        </div>
                                )
                        }
                    </section>
                    <form className="xui-form" onSubmit={handlePremiumUpgradeSubmit}>
                        <div className="xui-form-box">
                            <label>Enter Duration <span className="xui-font-w-bold">(Number of Months)</span></label>
                            <input required onChange={handleMonths} min={1} max={12} value={months} type={"number"} />
                        </div>
                        <span className="xui-font-sz-90 xui-opacity-5 xui-font-w-bold xui-mt-1">{premiumPackage ? premiumPackage + " | " : ""}Total: NGN {premiumAmount ? premiumAmount.toLocaleString() : 0}</span>
                        <p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorPremiumUpgrade}</span></p>
                        <p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successPremiumUpgrade}</span></p>
                        <div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
                            <button disabled={loadingPremiumPackage} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
                                {
                                    loadingPremiumPackage ?
                                        <>
                                            <span className="xui-mr-half">Processing</span>
                                            <Loading width="12" height="12" />
                                        </> : 
                                        <>
                                            <span className="xui-mr-half">Pay via gateway</span>
                                            <Arrowright width="12" height="12" />
                                        </>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </section>
            <section className='xui-modal' xui-modal="upgradePlanSuccessful" id="upgradePlanSuccessful">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    {
                        !successPremiumUpgrade && !errorPremiumUpgrade ?
                            <center className="xui-form-box">
                                <Loading width="12" height="12" />
                            </center> : ""
                    }
                    {
                        successPremiumUpgrade ?
                            <>
                                <center>
                                    <h1>Premium Upgrade Successful</h1>
                                    <img className="xui-img-100 xui-my-2" src={SuccessTick} alt="" />
                                </center>
                                <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successPremiumUpgrade}</span></p>
                            </> : ""
                    }
                    {
                        errorPremiumUpgrade ?
                            <>
                                <center>
                                    <h1>Premium Upgrade not successful</h1>
                                </center>
                                <p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorPremiumUpgrade}</span></p>
                            </> : ""
                    }
                    {
                        errorPremiumUpgrade ?
                            <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-between">
                                <div className="xui-d-inline-flex xui-flex-ai-center">
                                    <button onClick={() => { retryPremiumUpgradeCheck(premiumUpgradeReference) }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue-alt xui-bdr-rad-half xui-font-sz-85">
                                        {
                                            loadingRetryPremiumPackageCheck ?
                                                <Loading width="12" height="12" /> :
                                                <Reset width="20" height="20" />
                                        }
                                        <span className="xui-ml-half">Retry</span>
                                    </button>
                                </div>
                                <div className="xui-d-inline-flex xui-flex-ai-center">
                                    <button onClick={() => { setErrorPremiumUpgrade(null); setSuccessPremiumUpgrade(null); setShowSuccessfulPremiumUpgradeModal(null); getAllTransactions(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" xui-modal-close="upgradePlanSuccessful">
                                        <span className="xui-mr-half">Continue</span>
                                        <Arrowright width="20" height="20" />
                                    </button>
                                </div>
                            </div> :
                            <div className="xui-d-flex xui-flex-jc-flex-end xui-mt-1">
                                <button onClick={() => { setErrorPremiumUpgrade(null); setSuccessPremiumUpgrade(null); setShowSuccessfulPremiumUpgradeModal(null); getAllTransactions(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" xui-modal-close="upgradePlanSuccessful">
                                    <span className="xui-mr-half">Continue</span>
                                    <Arrowright width="20" height="20" />
                                </button>
                            </div>
                    }
                </div>
            </section>
            <section className='xui-modal' xui-modal="upgradePlanUnsuccessful" id="upgradePlanUnsuccessful">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Premium Upgrade not successful</h1>
                    </center>
                    {
                        !errorPremiumUpgrade ?
                            <center className="xui-mt-1">
                                <Loading width="12" height="12" />
                            </center> :
                            <p className="xui-font-sz-110 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorPremiumUpgrade}</span></p>
                    }
                    <div className="xui-d-flex xui-flex-jc-flex-end xui-mt-1">
                        <button disabled={!errorPremiumUpgrade} onClick={() => { setErrorPremiumUpgrade(null); setShowUnsuccessfulPremiumUpgradeModal(null); getAllTransactions(); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85" xui-modal-close="upgradePlanUnsuccessful">
                            <span className="xui-mr-half">Continue</span>
                            <Arrowright width="20" height="20" />
                        </button>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="confirmCancellation" id="confirmCancellation">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Cancel Transaction</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCancelDeposit}</span></p>
                    <p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCancelDeposit}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleCancelDeposit} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingCancelDeposit ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCancelDeposit ? "" : "confirmCancellation"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="confirmWithdrawalCancellation" id="confirmWithdrawalCancellation">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Cancel Withdrawal</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCancelWithdrawal}</span></p>
                    <p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCancelWithdrawal}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleCancelWithdrawal} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingCancelWithdrawal ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCancelWithdrawal ? "" : "confirmWithdrawalCancellation"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="confirmPremiumUpgradeCancellation" id="confirmPremiumUpgradeCancellation">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1>Cancel Premium Upgrade Transaction</h1>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
                    </center>
                    <p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorCancelPremiumUpgrade}</span></p>
                    <p className="xui-font-sz-80 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successCancelPremiumUpgrade}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleCancelPremiumUpgrade} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingCancelPremiumUpgrade ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingCancelPremiumUpgrade ? "" : "confirmPremiumUpgradeCancellation"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}