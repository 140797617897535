import { useState, useEffect } from "react";
import Loading from "../../icons/Loading";
import { useUpdateBankAccount, useGetBanks } from "../../hooks/useSettings";
import { useGetBusiness } from "../../hooks/useBusiness";

export default function BankDetails() {
	const [canCallBusinessDetails, setCanCallBusinessDetails] = useState(false);

	const {
		errorUpdateBankAccount, handleBusinessAccountName, handleBusinessAccountNumber, handleBusinessBank, handleUpdateBankAccount, loadingUpdateBankAccount,
		businessAccountName, businessAccountNumber, businessBank, setBusinessAccountName, setBusinessAccountNumber, setBusinessBank, successUpdateBankAccount
	} = useUpdateBankAccount();

	const { getBusinessDetails, businessDetails } = useGetBusiness();
	const { getAllBanks, banks } = useGetBanks();

	const callGetBusinessDetails = getBusinessDetails;

	if (successUpdateBankAccount) callGetBusinessDetails();

	if (canCallBusinessDetails) {
		setTimeout(function () {
			callGetBusinessDetails();
			setCanCallBusinessDetails(false);
		}, 2000)
	}

	useEffect(() => {
		if (businessDetails !== null) {
			if (businessDetails.data.account_name) setBusinessAccountName(businessDetails.data.account_name);
			if (businessDetails.data.account_number) setBusinessAccountNumber(businessDetails.data.account_number);
			if (businessDetails.data.bank) setBusinessBank(businessDetails.data.bank);
		}
	}, [businessDetails, banks]);

	return (
		<>
			<div className="xui-form-box xui-w-fluid-100 xui-mt-1">
				<form className="xui-form" onSubmit={(e) => e.preventDefault()}>
					<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1 xui-mb-2">
						<div className="xui-mt-1">
							<label>Account Name</label>
							<input type={"text"} placeholder={businessDetails ? businessDetails.data.account_name : ""} readOnly={businessDetails ? false : true} value={businessAccountName} onClick={() => { if (businessDetails) setBusinessAccountName(businessDetails.data.account_name) }} onChange={handleBusinessAccountName} required />
						</div>
						<div className="xui-mt-1">
							<label>Account Number</label>
							<input type={"number"} minLength={10} maxLength={10} placeholder={businessDetails ? businessDetails.data.account_number : ""} readOnly={businessDetails ? false : true} value={businessAccountNumber} onClick={() => { if (businessDetails) setBusinessAccountNumber(businessDetails.data.account_number) }} onChange={handleBusinessAccountNumber} required />
						</div>
					</div>
					<label>Bank</label>
					<div className="xui-d-flex xui-flex-ai-center">
						<select disabled={businessDetails ? false : true} onChange={handleBusinessBank} value={businessBank} required>
							<option selected disabled>Select Bank</option>
							{
								banks ? (
									banks.map((item, index) => {
										return (
											<option selected={businessDetails ? (businessDetails.data.bank === item.name ? true : false) : false} value={item.name}>{item.name}</option>
										)
									})
								) : ""
							}
						</select>
					</div>
					<button onClick={handleUpdateBankAccount} className="xui-btn xui-mt-2 psc-btn-blue xui-font-sz-80">
						{
							loadingUpdateBankAccount ?
								<Loading width="12" height="12" />
								: "Save Changes"
						}
					</button>
					<p className="xui-font-sz-100 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateBankAccount}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateBankAccount}</span></p>
				</form>
			</div>
		</>
	);
}