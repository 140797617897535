import axios from 'axios';
import { config } from '../config';

const businessSignup = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/business/signup`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const resendVerificationEmail = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/business/resend/verification/email`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const loginViaEmail = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/business/access`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const verifyEmailOtp = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/business/access/verify`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const loginViaToken = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/business/access/token`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const resetMasterToken = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/auth/business/reset/token`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const resetPassword = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/password/reset`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const verifyEmail = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/verify/email`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { businessSignup, loginViaEmail, loginViaToken, resendVerificationEmail, resetMasterToken, verifyEmailOtp, verifyEmail, resetPassword };