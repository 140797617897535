import axios from 'axios';
import { config } from '../config';

const getBusinessAnnouncements = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/announcements`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessAnnouncement = async function (token, unique_id) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/announcement`,
			{
				unique_id
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addBusinessAnnouncement = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/announcement/add`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getBusinessAnnouncement, addBusinessAnnouncement, getBusinessAnnouncements };
