import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { offerAuthenticateUser, requestUserPayment } from "../api/requests";

const useOfferAuthenticateUser = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingOfferAuthenticateUser, setLoadingOfferAuthenticateUser] = useState(false);
	const [showOfferAuthenticateUserModal, setShowOfferAuthenticateUserModal] = useState(null);
	const [pid, setPID] = useState("");
	const [offerUniqueId, setOfferUniqueId] = useState("");
	const [authenticatedUserDetails, setAuthenticatedUserDetails] = useState(null);

	const [errorOfferAuthenticateUser, setErrorOfferAuthenticateUser] = useState(null);
	const [successOfferAuthenticateUser, setSuccessOfferAuthenticateUser] = useState(null);

	const handlePID = (e) => { e.preventDefault(); setPID(e.target.value.toLocaleUpperCase()); };
	const handleOfferUniqueId = (e) => { e.preventDefault(); setOfferUniqueId(e.target.value); };

	const handleSubmitOfferAuthenticateUser = (e) => {
		e.preventDefault();

		if (!loadingOfferAuthenticateUser) {
			if (pid.length === 0) {
				setErrorOfferAuthenticateUser(null);
				setSuccessOfferAuthenticateUser(null);
				setErrorOfferAuthenticateUser("PID is required");
				setTimeout(function () {
					setErrorOfferAuthenticateUser(null);
				}, 2500)
			} else if (pid.length !== 6) {
				setErrorOfferAuthenticateUser("Invalid PID");
				setTimeout(function () {
					setErrorOfferAuthenticateUser(null);
				}, 2500)
			} else if (offerUniqueId.length === 0) {
				setErrorOfferAuthenticateUser("Offer is required");
				setTimeout(function () {
					setErrorOfferAuthenticateUser(null);
				}, 2500)
			} else {
				setLoadingOfferAuthenticateUser(true);

				const offerAuthenticateUserRes = offerAuthenticateUser(cookie, {
					pid,
					offer_unique_id: offerUniqueId
				})

				offerAuthenticateUserRes.then(res => {
					setLoadingOfferAuthenticateUser(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorOfferAuthenticateUser(error);
							setTimeout(function () {
								setErrorOfferAuthenticateUser(null);
							}, 3500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorOfferAuthenticateUser(error);
							setTimeout(function () {
								setErrorOfferAuthenticateUser(null);
							}, 3500)
						}
					} else {
						setErrorOfferAuthenticateUser(null);
						setSuccessOfferAuthenticateUser(res.data.message);
						setAuthenticatedUserDetails(res.data.data);

						setTimeout(function () {
							setSuccessOfferAuthenticateUser(null);
							setShowOfferAuthenticateUserModal(true);
							setPID(""); setOfferUniqueId("");
						}, 2500)
					}
				}).catch(err => {
					setLoadingOfferAuthenticateUser(false);
				})

			}
		}
	};

	return {
		cookie, loadingOfferAuthenticateUser, showOfferAuthenticateUserModal, pid, errorOfferAuthenticateUser, successOfferAuthenticateUser,
		handlePID, handleSubmitOfferAuthenticateUser, setShowOfferAuthenticateUserModal, offerUniqueId, setOfferUniqueId, handleOfferUniqueId, 
		setAuthenticatedUserDetails, authenticatedUserDetails
	};
};

const useRequestUserPayment = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingRequestUserPayment, setLoadingRequestUserPayment] = useState(false);
	const [showRequestUserPaymentModal, setShowRequestUserPaymentModal] = useState(null);
	const [pid, setPID] = useState("");
	const [amount, setAmount] = useState(null);
	const [details, setDetails] = useState("");
	const [userPaymentDetails, setUserPaymentDetails] = useState(null);

	const [errorRequestUserPayment, setErrorRequestUserPayment] = useState(null);
	const [successRequestUserPayment, setSuccessRequestUserPayment] = useState(null);

	const handlePID = (e) => { e.preventDefault(); setPID(e.target.value.toLocaleUpperCase()); };
	const handleDetails = (e) => { e.preventDefault(); setDetails(e.target.value); };
	const handleAmount = (e) => { e.preventDefault(); setAmount(e.target.value); };

	const handleSubmitRequestUserPayment = (e) => {
		e.preventDefault();

		if (!loadingRequestUserPayment) {
			if (pid.length === 0) {
				setErrorRequestUserPayment(null);
				setSuccessRequestUserPayment(null);
				setErrorRequestUserPayment("PID is required");
				setTimeout(function () {
					setErrorRequestUserPayment(null);
				}, 2500)
			} else if (pid.length !== 6) {
				setErrorRequestUserPayment("Invalid PID");
				setTimeout(function () {
					setErrorRequestUserPayment(null);
				}, 2500)
			} else if (details && details.length > 300) {
				setErrorRequestUserPayment("Maximum Details character - 300");
				setTimeout(function () {
					setErrorRequestUserPayment(null);
				}, 2500)
			} else if (!amount) {
				setErrorRequestUserPayment("Amount is required");
				setTimeout(function () {
					setErrorRequestUserPayment(null);
				}, 2500)
			} else if (amount < 50) {
				setErrorRequestUserPayment("Min Amount - NGN 50");
				setTimeout(function () {
					setErrorRequestUserPayment(null);
				}, 2500)
			} else if (amount > 1000000) {
				setErrorRequestUserPayment("Max Amount - NGN 1,000,000");
				setTimeout(function () {
					setErrorRequestUserPayment(null);
				}, 2500)
			} else {
				setLoadingRequestUserPayment(true);	

				const requestUserPaymentRes = requestUserPayment(cookie, {
					pid,
					amount: parseInt(amount),
					details: details ? details : undefined
				})

				requestUserPaymentRes.then(res => {
					setLoadingRequestUserPayment(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorRequestUserPayment(error);
							setTimeout(function () {
								setErrorRequestUserPayment(null);
							}, 3500)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorRequestUserPayment(error);
							setTimeout(function () {
								setErrorRequestUserPayment(null);
							}, 3500)
						}
					} else {
						setErrorRequestUserPayment(null);
						setSuccessRequestUserPayment(res.data.message);
						setUserPaymentDetails(res.data.data);

						setTimeout(function () {
							setSuccessRequestUserPayment(null);
							setShowRequestUserPaymentModal(true);
							setPID(""); setAmount(0); setDetails("");
						}, 2500)
					}
				}).catch(err => {
					setLoadingRequestUserPayment(false);
				})

			}
		}
	};

	return {
		cookie, loadingRequestUserPayment, showRequestUserPaymentModal, pid, errorRequestUserPayment, successRequestUserPayment,
		handlePID, handleSubmitRequestUserPayment, setShowRequestUserPaymentModal, amount, setAmount, handleAmount, details, setDetails, handleDetails,
		setUserPaymentDetails, userPaymentDetails
	};
};

export { useOfferAuthenticateUser, useRequestUserPayment };