import { useState, useEffect } from "react";
import useCookie from "./useCookie";
import { config } from "../config";
import { getBusiness, getBusinessMetrics } from "../api/business";

const useGetBusiness = () => {
	const {cookie, forceLogout} = useCookie(config.token, "");

	const [businessDetails, setBusinessDetails] = useState(null);

	async function getBusinessDetails() {
		const response = await getBusiness(cookie);
		setBusinessDetails(response.data);
		if (response.response_code === 403) forceLogout();
	}

	useEffect(() => {
		if (businessDetails === null) {
			getBusinessDetails();
		}
	}, [businessDetails]);

	return { businessDetails, setBusinessDetails, getBusinessDetails }
};

const useGetBusinessMetrics = () => {
	const {cookie} = useCookie(config.token, "");

	const [businessMetrics, setBusinessMetricsDetails] = useState(null);

	async function getBusinessMetricsDetails() {
		const response = await getBusinessMetrics(cookie);
		setBusinessMetricsDetails(response.data);
	}

	useEffect(() => {
		if (businessMetrics === null) {
			getBusinessMetricsDetails();
		}
	}, [businessMetrics]);

	return { businessMetrics, setBusinessMetricsDetails, getBusinessMetricsDetails }
};

export { useGetBusiness, useGetBusinessMetrics };