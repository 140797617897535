import { useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import Screen from '../components/Screen';
import Content from '../components/Content';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Boxes from '../assets/images/boxes.png';
import FlowerPlant from '../assets/images/flower-plant.png';
import { getBusinessEvents, getBusinessEvent } from "../api/events";
import { getBusinessTicketViaCode } from "../api/tickets";
import { getBusinessEventTickets } from "../api/eventTickets";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { useAddEvent, useDeleteEvent, useEditEvent } from "../hooks/useEvents";
import { useAddEventTicket, useDeleteEventTicket, useEditEventTicket } from "../hooks/useEventTickets";
import { useValidateTicket } from "../hooks/useTickets";
import { useRequestUserPayment } from "../hooks/useRequests";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Edit from "../icons/Edit";
import Copy from "../icons/Copy";
import EyeOpen from "../icons/EyeOpen";
import Ticket from "../icons/Ticket";
import Plus from "../icons/Plus";
import Minus from "../icons/Minus";
import Delete from "../icons/Delete";
import Check from "../icons/Check";
import Star from "../icons/Star";
import CheckCircle from "../icons/CheckCircle";
import { useGetBusiness } from "../hooks/useBusiness";

export default function Events() {
	const { cookie, forceLogout } = useCookie(config.token, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);
	const events_link = "https://events.passcoder.io/";

	const { businessDetails } = useGetBusiness();

	const {
		errorRequestUserPayment, handleAmount, handlePID: RequestUserPaymentHandlePID, handleSubmitRequestUserPayment, loadingRequestUserPayment,
		amount, pid: RequestUserPaymentPID, showRequestUserPaymentModal, setAmount, setShowRequestUserPaymentModal, successRequestUserPayment, setDetails,
		userPaymentDetails, setUserPaymentDetails: PaymentAuthenticatedUserDetails, handleDetails, details
	} = useRequestUserPayment();

	const {
		description, end, errorAddEvent, handleDescription, handleEnd, handleLocation, handleName, handleStart, handleSubmit, loading, location,
		name, removeAddEventModal, selectedImage, setRemoveAddEventModal, setSelectedImage, start, successAddEvent, uploadingImagePercentage
	} = useAddEvent();

	const {
		errorDeleteEvent, handleDeleteEvent, loadingDeleteEvent, removeDeleteEventModal, setDeleteEventUniqueId, setRemoveDeleteEventModal, successDeleteEvent
	} = useDeleteEvent();

	const {
		descriptionEdit, editEventDetails, editEventUniqueId, endEdit, errorEditEvent, getBusinessEventDetails, handleDescriptionEdit, handleEditEventDetails, 
		handleEditEventImage, handleEndEdit, handleLocationEdit, handleNameEdit, handleStartEdit, loadingEditEvent, locationEdit, nameEdit, removeEditEventModal, 
		selectedImageEdit, setDescriptionEdit, setEditEventUniqueId, setEndEdit, setLocationEdit, setNameEdit, setRemoveEditEventModal, setSelectedImageEdit, 
		setStartEdit, showEditEventDetailsStatus, showEditEventImageStatus, startEdit, successEditEvent, uploadingImageEditPercentage
	} = useEditEvent();

	const {
		amount: EventTicketAmount, errorAddEventTicket, eventUniqueId, handleAmount: EventTicketHandleAmount, handleEventUniqueId, handleName: EventTicketHandleName, 
		handleQuantity, handleSubmit: EventTicketHandleSubmit, loading: EventTicketLoading, name: EventTicketName, quantity, removeAddEventTicketModal, setEventUniqueId, 
		setRemoveAddEventTicketModal, successAddEventTicket, handleSingle, single
	} = useAddEventTicket();

	const {
		errorDeleteEventTicket, handleDeleteEventTicket, loadingDeleteEventTicket, removeDeleteEventTicketModal, setDeleteEventTicketUniqueId, setRemoveDeleteEventTicketModal, successDeleteEventTicket
	} = useDeleteEventTicket();

	const {
		amountEdit, editEventTicketDetails, editEventTicketUniqueId, errorEditEventTicket, getBusinessEventTicketDetails, handleAmountEdit, handleEditEventTicketDetails, 
		handleNameEdit: EventTicketHandleNameEdit, handleQuantityEdit, loadingEditEventTicket, nameEdit: EventTicketNameEdit, quantityEdit, removeEditEventTicketModal, 
		setAmountEdit, setEditEventTicketUniqueId, setNameEdit: EventTicketSetNameEdit, setQuantityEdit, setRemoveEditEventTicketModal, showEditEventTicketDetailsStatus, 
		successEditEventTicket, handleSingleEdit, setSingleEdit, singleEdit
	} = useEditEventTicket();

	const {
		errorValidateTicket, handleValidateTicket, handleValidateTicketCode, loadingValidateTicket, removeValidateTicketModal, setRemoveValidateTicketModal, 
		setValidateTicketCode, successValidateTicket, validateTicketCode
	} = useValidateTicket();

	const return_date_reverse = (date) => {
		let _date = date.split(" ");
		return _date[0] + "T" + _date[1];
	};

	const timestamp_str = (date) => {
		const d = new Date(date);
		return {
			fulldate: d.toDateString() + " at " + d.toLocaleTimeString(),
			date: d.toDateString(),
			time: d.toLocaleTimeString(),
		};
	};

	const handleSelectImage = (e) => {
		const el = e.target.files[0];
		setSelectedImage("");
		setSelectedImage(el);
	};

	const handleSelectImageEdit = (e) => {
		const el = e.target.files[0];
		setSelectedImageEdit("");
		setSelectedImageEdit(el);
	};

	const getFileExtension = (filename) => {
		let lastDot = filename.lastIndexOf('.');
		let ext = filename.substring(lastDot + 1);
		return ext;
	};

	const getFileNameAlone = (filename) => {
		let _filename = filename.split("/");
		return _filename[_filename.length - 1];
	};

	const showPreview = function (file) {
		const preview = file;

		window.open(preview, "_blank");
	};

	const [allEvents, setAllEvents] = useState(null);
	const [errorAllEvents, setErrorAllEvents] = useState(null);
	const [loadingAllEvents, setLoadingAllEvents] = useState(false);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getAllEvents(page, e.target.value); };

	async function previousEvents() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getAllEvents(page - 1, size);
	};

	async function nextEvents() {
		if (page < allEvents.data.pages) setPage(page + 1);
		if (page < allEvents.data.pages) getAllEvents(page + 1, size);
	};

	async function getAllEvents(_page, _size) {
		setLoadingAllEvents(true);
		const response = await getBusinessEvents(cookie, (_page || page), (_size || size));
		setAllEvents(response.data);
		if (response.response_code === 403) forceLogout();
		if (response.error) setErrorAllEvents(response.error.response.data.message);
		setLoadingAllEvents(false);
	};

	const [loadingViewTicket, setLoadingViewTicket] = useState(false);
	const [errorViewTicket, setErrorViewTicket] = useState(null);
	const [viewTicket, setViewTicket] = useState(null);

	async function getTicket() {
		setLoadingViewTicket(true)
		setViewTicket(null);
		setErrorViewTicket(null);
		const response = await getBusinessTicketViaCode(cookie, { code: validateTicketCode });
		if (!response.err) {
			setViewTicket(response.data);
		} else { setErrorViewTicket(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewTicket(false)
	};

	const [selectedEventUniqueId, setSelectedEventUniqueId] = useState(null);

	const [showAddEventTicket, setShowAddEventTicket] = useState(false);
	const [showEditEventTicket, setShowEditEventTicket] = useState(false);
	const [viewEventTicketsName, setViewEventTicketsName] = useState(null);
	const [loadingViewEventTickets, setLoadingViewEventTickets] = useState(false);
	const [errorViewEventTickets, setErrorViewEventTickets] = useState(null);
	const [viewEventTickets, setViewEventTickets] = useState(null);

	async function getEventTickets(event_unique_id) {
		setLoadingViewEventTickets(true)
		const response = await getBusinessEventTickets(cookie, { event_unique_id: event_unique_id });
		if (!response.err) {
			setViewEventTickets(response.data);
		} else { setErrorViewEventTickets(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewEventTickets(false)
	};

	useEffect(() => {
		if (allEvents === null) {
			getAllEvents();
		}
		if (viewEventTickets === null && selectedEventUniqueId !== null) {
			getEventTickets(selectedEventUniqueId);
		}
	}, [allEvents, viewEventTickets]);

	if (successRequestUserPayment) {
		const modalResponse = document.querySelector("#paymentUserAuthenticated");
		modalResponse.setAttribute("display", true);
	}

	async function continueSuccessRequestUserPayment() {
		const modalResponse = document.querySelector("#paymentUserAuthenticated");
		modalResponse.setAttribute("display", false);
		PaymentAuthenticatedUserDetails(null);
	}
	
	if (removeValidateTicketModal) {
		const modalResponse = document.querySelector("#ticketDetails");
		modalResponse.setAttribute("display", false);
		setRemoveValidateTicketModal(null);
	}

	if (removeAddEventModal) {
		const modalResponse = document.querySelector("#addEvent");
		modalResponse.setAttribute("display", false);
		getAllEvents();
		setRemoveAddEventModal(null);
	}
	if (removeEditEventModal) {
		const modalResponse = document.querySelector("#editEvent");
		modalResponse.setAttribute("display", false);
		getAllEvents();
		setRemoveEditEventModal(null);
	}
	if (removeDeleteEventModal) {
		const modalResponse = document.querySelector("#deleteEvent");
		modalResponse.setAttribute("display", false);
		getAllEvents();
		setRemoveDeleteEventModal(null);
	}

	if (removeAddEventTicketModal) {
		setShowAddEventTicket(false);
		setViewEventTickets(null);
		getEventTickets(selectedEventUniqueId);
		setRemoveAddEventTicketModal(null);
	}
	if (removeEditEventTicketModal) {
		setShowEditEventTicket(false);
		setViewEventTickets(null);
		getEventTickets(selectedEventUniqueId);
		setRemoveEditEventTicketModal(null);
	}
	if (removeDeleteEventTicketModal) {
		const modalResponse = document.querySelector("#deleteEventTicket");
		modalResponse.setAttribute("display", false);
		setViewEventTickets(null);
		getEventTickets(selectedEventUniqueId);
		setRemoveDeleteEventTicketModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const getObjectValues = (obj) => {
		let values = [];
		Object.keys(obj).some((key) => {
			values.push(
				<>
					<br></br>
					<div className='xui-d-inline-flex xui-flex-ai-center'>
						<p id={obj[key]}><b>{key}</b> : {obj[key].length > 300 ? obj[key].slice(0, 200) + " ..." : obj[key]}</p>
						<span title="Copy Text" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(obj[key]); setTextCopied(obj[key]); }}>
							{copiedText && textCopied === obj[key] ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
						</span>
					</div>
				</>
			)
		});
		return values;
	};

	return (
		<>
			<Screen aside="true" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Personalized Events</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Create new event, edit duration, location and set tickets.</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button disabled={businessDetails ? !businessDetails.data.verified : true} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open={businessDetails ? (!businessDetails.data.verified ? "" : "addEvent") : ""}>
										<span>Create Event</span>
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllEvents ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allEvents && allEvents.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-min-w-20'>S/N</th>
														<th className='xui-min-w-300'>Name</th>
														<th className='xui-min-w-200'>Location</th>
														<th className='xui-min-w-300'>Duration</th>
														<th className='xui-min-w-150'>Image</th>
														<th className='xui-min-w-300'>Date</th>
														<th className='xui-min-w-200'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allEvents.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	{i + 1}
																</div>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<p>{data.name}</p>
																	<span title="Copy Event Link" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText((events_link + data.stripped)); setTextCopied((events_link + data.stripped)); }}>
																		{copiedText && textCopied === (events_link + data.stripped) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.location}</span>
															</td>
															<td className='xui-opacity-5 xui-font-w-bold'>
																{
																	data.start || data.end ?
																		<span>{data.start ? (data.end ? timestamp_str(data.start).fulldate + " to " : "Starts - " + timestamp_str(data.start).fulldate) : ""}{data.end ? (data.start ? timestamp_str(data.end).fulldate : "Ends - " + timestamp_str(data.end).fulldate) : ""}</span> :
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>No duration</span>
																}
															</td>
															<td className=''>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<img className="xui-img-50" src={data.image} alt="Event Image" />
																	{/* <span title="Copy Link" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.image); setTextCopied(data.image); }}>
																		{copiedText && textCopied === data.image ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span> */}
																	<span title="View Image" className="xui-cursor-pointer xui-mx-1" onClick={() => { showPreview(data.image); }}>
																		<EyeOpen width="16" height="16" />
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="Add Event Tickets" onClick={() => { setEventUniqueId(data.unique_id); setSelectedEventUniqueId(data.unique_id); getEventTickets(data.unique_id); setViewEventTicketsName(data.name); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="eventTicket">
																		<Ticket width="20" height="20" />
																	</button>
																	<button title="Edit Event"
																		onClick={() => {
																			setEditEventUniqueId(data.unique_id);
																			setDescriptionEdit("");
																			setLocationEdit(data.location);
																			setEndEdit(data.end === null ? "" : return_date_reverse(data.end));
																			setNameEdit(data.name);
																			setStartEdit(data.start === null ? "" : return_date_reverse(data.start));
																			getBusinessEventDetails(data.unique_id);
																		}}
																		className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="editEvent">
																		<Edit width="20" height="20" />
																	</button>
																	<button title="Delete Event" onClick={() => { setDeleteEventUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="deleteEvent">
																		<Delete width="20" height="20" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllEvents}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllEvents ?
								<Loading width="12" height="12" /> :
								(
									allEvents && allEvents.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'>{page}</span> of {allEvents ? allEvents.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousEvents}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextEvents}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
					<center className="xui-mt-4 xui-lg-d-none">
						<span className='xui-opacity-4 xui-font-sz-100 xui-font-w-700 xui-open-sidebar'>Click to open right sidebar</span>
					</center>
				</Content>
				<div className="aside psc-bg-light-blue xui-py-2 xui-px-1-half">
					<div className='xui-mt-1 xui-mb-3'>
						<div className='xui-d-flex xui-flex-ai-baseline xui-flex-jc-flex-end'>
							<div className='xui-pr-1 '>
								<img className='xui-img-100' src={FlowerPlant} alt='flower plant' />
							</div>
						</div>
						<div className='psc-bg-light-blue-ii xui-px-1 xui-pt-5 xui-pb-1 xui-mt--4'>
							<form className="xui-form" layout="2" onSubmit={(e) => { e.preventDefault(); }}>
								<h1 className='xui-font-sz-110 xui-mt-half'>Validate Ticket</h1>
								<div className="xui-mt-2">
									<label>Code</label>
									<input type="text" className="xui-bdr-black" minLength={10} maxLength={10} value={validateTicketCode} onChange={handleValidateTicketCode} placeholder="Enter ticket code" required ></input>
								</div>
								<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-black"><span className="xui-font-w-bold psc-text-red">{loadingViewTicket ? "Checking ..." : ""}</span></p>
								<button onClick={() => getTicket()} disabled={loadingViewTicket || (businessDetails ? !businessDetails.data.verified : true) || validateTicketCode.length !== 10} className={`xui-btn-block ${loadingViewTicket ? "psc-btn-blue xui-mt-1" : "psc-btn-blue-alt xui-mt-2"} xui-bdr-rad-half xui-text-center xui-font-sz-85`} xui-modal-open="ticketDetails">
									<center>
										{
											loadingViewTicket ?
												<Loading width="12" height="12" />
												: "Check Ticket"
										}
									</center>
								</button>
							</form>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorViewTicket}</span></p>
						</div>
					</div>
					<div className='xui-mt-5 xui-mb-3'>
						<div className='xui-d-flex xui-flex-ai-baseline xui-flex-jc-flex-end'>
							<div className='xui-pr-1 '>
								<img className='xui-img-100' src={FlowerPlant} alt='flower plant' />
							</div>
						</div>
						<div className='psc-bg-light-blue-ii xui-px-1 xui-pt-5 xui-pb-1 xui-mt--4'>
							<form className="xui-form" layout="2" onSubmit={handleSubmitRequestUserPayment}>
								<h1 className='xui-font-sz-110 xui-mt-half'>Create Invoice</h1>
								<div className="xui-mt-2">
									<label>Passcoder ID</label>
									<input type="text" className="xui-bdr-black" minLength={6} maxLength={6} value={RequestUserPaymentPID} onChange={RequestUserPaymentHandlePID} placeholder="Enter user Passcoder ID" required ></input>
								</div>
								<div className="xui-mt-2">
									<label>Amount</label>
									<input type={"number"} className="xui-bdr-black" min={1} value={amount} onChange={handleAmount} placeholder="Bill amount" required ></input>
								</div>
								<div className="xui-mt-2">
									<label>Description</label>
									<textarea type={"text"} value={details} maxLength={300} onChange={handleDetails} placeholder="Enter description (optional)"></textarea>
								</div>
								<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-black"><span className="xui-font-w-bold psc-text-red">{loadingRequestUserPayment ? "Awaiting authentication" : ""}</span></p>
								<button disabled={loadingRequestUserPayment || (businessDetails ? !businessDetails.data.verified : true)} className={`xui-btn-block ${loadingRequestUserPayment ? "psc-btn-blue xui-mt-1" : "psc-btn-blue-alt xui-mt-2"} xui-bdr-rad-half xui-text-center xui-font-sz-85`}>
									<center>
										{
											loadingRequestUserPayment ?
												<Loading width="12" height="12" />
												: "Request Payment"
										}
									</center>
								</button>
							</form>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorRequestUserPayment}</span></p>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successRequestUserPayment}</span></p>
						</div>
					</div>
				</div>
			</Screen>
			<section className='xui-modal' xui-modal="addEvent" id="addEvent">
				<div className='xui-modal-content xui-max-h-600 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="addEvent">
						<Close width="24" height="24" />
					</div>
					<h1>New event</h1>
					<form className="xui-form" layout="2" onSubmit={handleSubmit}>
						<div className="xui-form-box xui-mt-2">
							<label>Name</label>
							<input type="text" value={name} onChange={handleName} placeholder="Enter event name" required ></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Location</label>
							<input type="text" value={location} onChange={handleLocation} placeholder="Enter event location" required ></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Description</label>
							<textarea type={"text"} value={description} onChange={handleDescription} placeholder="Enter event description" required></textarea>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
							<div className="xui-mt-1">
								<label>Start</label>
								<input className="xui-font-sz-90" type={"datetime-local"} value={start} onChange={handleStart} required></input>
							</div>
							<div className="xui-mt-1">
								<label>End</label>
								<input className="xui-font-sz-90" type={"datetime-local"} value={end} onChange={handleEnd} required></input>
							</div>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Banner</label>
							<input onChange={handleSelectImage} type={"file"} accept={"image/*"} id="image" required />
						</div>
						{
							uploadingImagePercentage > 0 ?
								<>
									<label htmlFor="uploader">Uploading</label>
									<progress className="xui-h-30" value={uploadingImagePercentage} id="uploader" max="100">{uploadingImagePercentage + "%"}</progress><br /><br></br>
								</> :
								""
						}
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Save event</span>
								{
									loading ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddEvent}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddEvent}</span></p>
				</div>
			</section>
			<section className='xui-modal' xui-modal="editEvent" id="editEvent">
				<div className='xui-modal-content xui-max-h-600 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="editEvent">
						<Close width="24" height="24" />
					</div>
					<h1>Edit event</h1>
					<form className="xui-form" layout="2" onSubmit={(e) => e.preventDefault()}>
						<div className="xui-form-box xui-mt-2">
							<label>Name</label>
							<input type="text" value={nameEdit} onChange={handleNameEdit} placeholder="Enter event name" required ></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Location</label>
							<input type="text" value={locationEdit} onChange={handleLocationEdit} placeholder="Enter event location" required ></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Description</label>
							<textarea type={"text"} value={descriptionEdit} onChange={handleDescriptionEdit} required></textarea>
						</div>
						<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
							<div className="xui-mt-1">
								<label>Start</label>
								<input className="xui-font-sz-90" type={"datetime-local"} value={startEdit} onChange={handleStartEdit} required></input>
							</div>
							<div className="xui-mt-1">
								<label>End</label>
								<input className="xui-font-sz-90" type={"datetime-local"} value={endEdit} onChange={handleEndEdit} required></input>
							</div>
						</div>
						{
							showEditEventDetailsStatus ?
								<>
									<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEditEvent}</span></p>
									<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEditEvent}</span></p>
								</> : ""
						}
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button disabled={showEditEventImageStatus} onClick={handleEditEventDetails} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Update details</span>
								{
									showEditEventDetailsStatus && loadingEditEvent ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
					<form className="xui-form" layout="2" onSubmit={(e) => e.preventDefault()}>
						<div className="psc-broken-line-text xui-opacity-4">
							<span className="xui-font-sz-80 xui-font-w-700">Banner Image</span>
						</div>
						<div className="xui-form-box mt-2">
							<label>Current Image</label>
							<div className="xui-d-flex xui-flex-ai-center">
								<img className="xui-img-200" src={editEventDetails ? editEventDetails.data.image : ""} alt="Event Image" />
								<input onChange={handleSelectImageEdit} className='xui-my-2' type={"file"} accept={"image/*"} id="image" required />
							</div>
							{
								uploadingImageEditPercentage > 0 ?
									<>
										<label htmlFor="uploader">Uploading</label>
										<progress className="xui-h-30" value={uploadingImageEditPercentage} id="uploader" max="100">{uploadingImageEditPercentage + "%"}</progress><br /><br></br>
									</> :
									""
							}
						</div>
						{
							showEditEventImageStatus ?
								<>
									<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEditEvent}</span></p>
									<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEditEvent}</span></p>
								</> : ""
						}
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button disabled={showEditEventDetailsStatus} onClick={handleEditEventImage} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Update banner</span>
								{
									showEditEventImageStatus && loadingEditEvent ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="deleteEvent" id="deleteEvent">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Delete Event</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeleteEvent}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeleteEvent}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeleteEvent} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeleteEvent ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeleteEvent ? "" : "deleteEvent"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="eventTicket" id="eventTicket">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="eventTicket" onClick={() => { setViewEventTicketsName(null); }}>
						<Close width="24" height="24" />
					</div>
					<h1>{viewEventTicketsName}</h1>
					<span className="xui-font-sz-90 xui-opacity-5">Add / Edit event tickets</span>
					<section className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2 xui-mb-2">
						{
							loadingViewEventTickets ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									viewEventTickets && viewEventTickets.success ?
										<section className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-1">
											{
												<>
													{
														viewEventTickets.data.rows.map((data, i) => (
															<>
																<div onClick={() => { setShowEditEventTicket(true); setShowAddEventTicket(false); setEditEventTicketUniqueId(data.unique_id); setEventUniqueId(data.event_unique_id); setAmountEdit(data.amount); EventTicketSetNameEdit(data.name); setQuantityEdit(data.quantity); setSingleEdit(data.single); }} className={`xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-1 xui-px-1 xui-cursor-pointer ${editEventTicketUniqueId === data.unique_id ? "xui-bg-blue xui-text-white" : ""}`}>
																	<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-btn-red psc-bg xui-text-white" style={{ right: "45px", position: "absolute" }} onClick={() => { setShowEditEventTicket(false); setDeleteEventTicketUniqueId(data.unique_id); }} xui-modal-open="deleteEventTicket">
																		<Delete width="24" height="24" />
																	</div>
																	<h3 className="xui-font-sz-100 xui-font-w-bold xui-mt-1">{data.name}</h3>
																	<hr className="xui-opacity-4 xui-my-1" />
																	<h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Quantity - {data.quantity.toLocaleString()} </h2>
																	<h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Remaining - {data.remaining.toLocaleString()}</h2>
																	<h2 className="xui-font-w-normal xui-font-sz-90 xui-mx-auto xui-w-fluid-90">Purchase -
																		{
																			data.single ?
																				<span className='xui-badge xui-badge-success xui-font-sz-70 xui-bdr-rad-half xui-mx-1'>Limited</span> :
																				<span className='xui-badge xui-badge-danger xui-font-sz-70 xui-bdr-rad-half xui-mx-1'>Unlimited</span>
																		}
																	</h2>
																	{
																		data.amount === 0 ? 
																			<h2 className="xui-text-center xui-font-sz-80 xui-mx-auto xui-w-fluid-100 xui-mt-1">
																				<span className='xui-badge xui-badge-success xui-font-w-bold xui-text-center xui-bdr-rad-half xui-mx-1'>Free</span>
																			</h2> : 
																			<h2 className="xui-font-w-bold xui-text-center xui-font-sz-100 xui-mx-auto xui-w-fluid-100 xui-mt-1">NGN {data.amount.toLocaleString()}</h2>
																	}
																</div>
																{
																	showEditEventTicket && editEventTicketUniqueId === data.unique_id ? 
																		<div className={`xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1`}>
																			<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white" style={{ right: "45px", position: "absolute" }} onClick={() => { setShowEditEventTicket(false); setEditEventTicketUniqueId(null); }}>
																				<Close width="24" height="24" />
																			</div>
																			<form className="xui-form xui-mt-4" onSubmit={handleEditEventTicketDetails}>
																				<div className="xui-form-box xui-mt-1">
																					<label>Name</label>
																					<input type="text" value={EventTicketNameEdit} onChange={EventTicketHandleNameEdit} placeholder="Enter name of ticket" required ></input>
																				</div>
																				<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
																					<div className="xui-mt-1">
																						<label>Amount <span className="xui-font-w-bold">(Price of this ticket)</span></label>
																						<input required onChange={handleAmountEdit} min={0} value={amountEdit} type={"number"} />
																					</div>
																					<div className="xui-mt-1">
																						<label>Quantity <span className="xui-font-w-bold">(Max available)</span></label>
																						<input required onChange={handleQuantityEdit} min={1} value={quantityEdit} type={"number"} />
																					</div>
																				</div>
																				<div className="xui-form-box xui-d-flex xui-mt-2">
																					<div className="xui-d-inline-flex xui-flex-ai-center">
																						<input type="checkbox" onChange={handleSingleEdit} checked={singleEdit} id="single" />
																						<label htmlFor="single" className="xui-ml-half" style={{ marginBottom: '0' }}>Limited purchase ? (one per person)</label>
																					</div>
																				</div>
																				<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorEditEventTicket}</span></p>
																				<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successEditEventTicket}</span></p>
																				<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
																					<button disabled={loadingEditEventTicket} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
																						<span className="xui-mr-half">Save changes</span>
																						{
																							loadingEditEventTicket ?
																								<Loading width="12" height="12" />
																								: <Arrowright width="12" height="12" />
																						}
																					</button>
																				</div>
																			</form>
																		</div> : null
																}
															</>
														))
													}
													{
														!showAddEventTicket ? 
															<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2">
																<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
																	<center className="xui-text-blue xui-cursor-pointer" onClick={() => { setShowAddEventTicket(true); setShowEditEventTicket(false); }}>
																		<Ticket width="40" height="40" />
																		<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half" style={{ textDecoration: "underline" }} >Add more tickets</h3>
																	</center>
																</div>
															</div> : null
													}
												</>
											}
										</section> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-blue xui-cursor-pointer" onClick={() => { setShowAddEventTicket(true); }}>
													<Ticket width="40" height="40" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half" style={{ textDecoration: "underline" }} >Add ticket</h3>
												</center>
											</div>
										</div>
								)
						}
					</section>
					{
						showAddEventTicket ? 
							<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-1 xui-px-1">
								<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white" style={{ right: "45px", position: "absolute" }} onClick={() => { setShowAddEventTicket(false); }}>
									<Close width="24" height="24" />
								</div>
								<form className="xui-form xui-mt-4" onSubmit={EventTicketHandleSubmit}>
									<div className="xui-form-box xui-mt-2">
										<label>Name</label>
										<input type="text" value={EventTicketName} onChange={EventTicketHandleName} placeholder="Enter name of ticket" required ></input>
									</div>
									<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
										<div className="xui-mt-1">
											<label>Amount <span className="xui-font-w-bold">(Price of this ticket)</span></label>
											<input required onChange={EventTicketHandleAmount} min={0} value={EventTicketAmount} type={"number"} />
										</div>
										<div className="xui-mt-1">
											<label>Quantity <span className="xui-font-w-bold">(Max available)</span></label>
											<input required onChange={handleQuantity} min={1} value={quantity} type={"number"} />
										</div>
									</div>
									<div className="xui-form-box xui-d-flex xui-mt-2">
										<div className="xui-d-inline-flex xui-flex-ai-center">
											<input type="checkbox" onChange={handleSingle} checked={single} id="single" />
											<label htmlFor="single" className="xui-ml-half" style={{ marginBottom: '0' }}>Limited purchase ? (one per person)</label>
										</div>
									</div>
									<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddEventTicket}</span></p>
									<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddEventTicket}</span></p>
									<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
										<button disabled={EventTicketLoading} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
											<span className="xui-mr-half">Save ticket</span>
											{
												EventTicketLoading ?
													<Loading width="12" height="12" />
													: <Arrowright width="12" height="12" />
											}
										</button>
									</div>
								</form>
							</div> : null
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="deleteEventTicket" id="deleteEventTicket">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Delete Event Ticket</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeleteEventTicket}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeleteEventTicket}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeleteEventTicket} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeleteEventTicket ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={() => { setShowEditEventTicket(false); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeleteEventTicket ? "" : "deleteEventTicket"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="paymentUserAuthenticated" id="paymentUserAuthenticated">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<h1>Payment Summary</h1>
					<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Visible details of the user below</p>
					{
						userPaymentDetails ?
							<>
								<center className="xui-m-1-half">
									<div className="xui-w-200 xui-h-200">
										<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={userPaymentDetails.photo} alt={userPaymentDetails.name + " Selfie Image"} />
									</div>
								</center>
								<center>
									<p className="xui-opacity-4 xui-font-sz-150 xui-m-half">{userPaymentDetails.name}</p>
									<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {userPaymentDetails.pid}</b>
									<center>
										<div className="xui-d-inline-flex xui-flex-ai-center">
											<span>
												{
													userPaymentDetails.star === 0 ?
														<div className='xui-m-half'>
															<p>No star</p>
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 1 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 2 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 3 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 4 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 5 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
											</span>
										</div>
									</center>
									<div className="xui-d-inline-flex xui-flex-ai-center">
										<span><CheckCircle width="20" height="20" /></span>
										<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Successfully received NGN {userPaymentDetails.amount_txt}</p>
									</div>
								</center>
								<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly ">
									<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Total Points: <b>{userPaymentDetails.user_points.toLocaleString()}</b></p>
									<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Points with you: <b>{userPaymentDetails.user_business_points.toLocaleString()}</b></p>
								</div>
								<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
									<button onClick={continueSuccessRequestUserPayment} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
										<span className="xui-mr-half">Complete</span>
										<Arrowright width="12" height="12" />
									</button>
								</div>
							</> :
							<center>
								<Loading width="12" height="12" />
							</center>
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="ticketDetails" id="ticketDetails">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="ticketDetails">
						<Close width="24" height="24" />
					</div>
					<h1>#{validateTicketCode} ticket details</h1>
					<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Visible details of this ticket below</p>
					{
						loadingViewTicket ?
							<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
							viewTicket && viewTicket.success ?
								<>
									<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
										<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-7">
											<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewTicket.data.user_data.photo} alt={viewTicket.data.user_data.firstname + (viewTicket.data.user_data.middlename ? " " + viewTicket.data.user_data.middlename + " " : " ") + viewTicket.data.user_data.lastname + " Selfie Image"} />
											<center className="xui-mt-1 xui-mb-4">
												<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewTicket.data.user_data.pid}</b>
												<center>
													<div className="xui-d-inline-flex xui-flex-ai-center">
														<span>
															{
																viewTicket.data.user_data.star === 0 ?
																	<div className='xui-m-half'>
																		<p>No star</p>
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 1 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 2 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 3 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 4 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 5 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
														</span>
													</div>
												</center>
												<p className="xui-mb-1">{viewTicket.data.user_data.firstname + (viewTicket.data.user_data.middlename ? " " + viewTicket.data.user_data.middlename + " " : " ") + viewTicket.data.user_data.lastname}</p>
											</center>
										</div>
										<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7
										">
											<img className="xui-img-responsive xui-bdr-rad-half xui-bg-pos-center xui-bg-sz-cover" src={viewTicket.data.event_data.image} alt={viewTicket.data.event_data.name + " Event Image"} />
											<p className="xui-opacity-4 xui-font-sz-110 xui-text-center xui-m-half">{viewTicket.data.event_data.name}</p>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half"><span className="xui-font-w-bold">Code -</span> {viewTicket.data.code}</p>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half"><span className="xui-font-w-bold">Location -</span> {viewTicket.data.event_data.location}</p>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half"><span className="xui-font-w-bold">Duration -</span>
												{
													viewTicket.data.event_data.start || viewTicket.data.event_data.end ?
														<span>{viewTicket.data.event_data.start ? (viewTicket.data.event_data.end ? timestamp_str(viewTicket.data.event_data.start).fulldate + " to " : "Starts - " + timestamp_str(viewTicket.data.event_data.start).fulldate) : ""}{viewTicket.data.event_data.end ? (viewTicket.data.event_data.start ? timestamp_str(viewTicket.data.event_data.end).fulldate : "Ends - " + timestamp_str(viewTicket.data.event_data.end).fulldate) : ""}</span> :
														<span className='xui-badge xui-badge-success xui-font-sz-90 xui-bdr-rad-half'>No duration</span>
												}
											</p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">
												<span className="xui-font-w-bold">Other Details - </span> 
												{
													viewTicket.data.other !== null ?
														getObjectValues(viewTicket.data.other).map(val => {
															return val;
														}) : " No additional details"
												}
											</p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">
												{
													viewTicket.data.status === "Authenticated" || viewTicket.data.status === "Completed" ?
														<span className='xui-badge xui-badge-success xui-font-sz-90 xui-bdr-rad-half'>{viewTicket.data.status}</span> : ""
												}
												{
													viewTicket.data.status === "Unauthenticated" || viewTicket.data.status === "Timeout" ?
														<span className='xui-badge xui-badge-danger xui-font-sz-90 xui-bdr-rad-half'>{viewTicket.data.status}</span> : ""
												}
											</p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-100 xui-text-center xui-m-half"><span className="xui-font-w-bold">{viewTicket.data.event_ticket_data.name} (x{viewTicket.data.quantity})</span></p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-100 xui-text-center xui-m-half">
												{
													viewTicket.data.total_amount === 0 ? 
														<span className='xui-badge xui-badge-success xui-font-sz-100 xui-bdr-rad-half'>Free</span> : 
														<b>NGN {viewTicket.data.total_amount.toLocaleString()}</b>
												}
											</p>
										</div>
									</div>
									<center>
										<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewTicket.data.createdAt.fulldate} | Last Updated - {viewTicket.data.updatedAt.fulldate}</p>
									</center>
									<p className="xui-font-sz-90 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorValidateTicket}</span></p>
									<p className="xui-font-sz-90 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successValidateTicket}</span></p>
									<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
										{
											viewTicket.data.status === "Authenticated" ?
												<button disabled={viewTicket.data.status === "Authenticated"} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
													<span className="xui-mr-half">Validated</span>
													<Check width="16" height="16" />
												</button> :
												(
													viewTicket.data.status === "Timeout" ?
														<button disabled={true} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85">
															<span className="xui-mr-half">Expired</span>
															<Close width="16" height="16" />
														</button> :
														<button disabled={loadingValidateTicket || viewTicket.data.status === "Authenticated"} onClick={handleValidateTicket} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
															<span className="xui-mr-half">Validate</span>
															{
																loadingValidateTicket ?
																	<Loading width="12" height="12" />
																	: <Arrowright width="16" height="16" />
															}
														</button>
												)
										}
									</div>
								</> :
								<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
									<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
										<center className="xui-text-red">
											<Close width="100" height="100" />
											<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewTicket}</h3>
										</center>
									</div>
								</div>
					}
				</div>
			</section>
		</>
	);
}