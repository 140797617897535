import { useState, useEffect } from "react";
import Close from "../../icons/Close";
import GalleryAdd from "../../assets/images/gallery-add.png";
import Check from "../../icons/Check";
import Loading from "../../icons/Loading";
import { useUpdateDescription, useUpdateEmail, useUpdateName, useUploadBusinessProfilePhoto, useUpdatePointThreshold, useUploadBusinessProfileCover } from "../../hooks/useSettings";
import { useGetBusiness } from "../../hooks/useBusiness";
import { config } from "../../config";

export default function AccountProfile(){
    const changeLGA = config.changeLGA;

    const [canCallBusinessDetails, setCanCallBusinessDetails] = useState(false);
    const [callUseEffect, setCallUseEffect] = useState(true);

    const {
        businessName, errorUpdateName, handleBusinessName, handleUpdateName, loadingUpdateName, setBusinessName, successUpdateName, 
        removeUpdateNameModal, setRemoveUpdateNameModal, cities, handleBusinessCity, handleBusinessCountry, handleBusinessState, 
        businessCity, businessCountry, businessState, setBusinessCity, setBusinessCountry, setBusinessState, setCities
    } = useUpdateName();

    const {
        businessEmail, errorUpdateEmail, handleBusinessEmail, handleUpdateEmail, loadingUpdateEmail, setBusinessEmail, successUpdateEmail, 
        removeUpdateEmailModal, setRemoveUpdateEmailModal, handleStripped
    } = useUpdateEmail();

    const {
        businessDescription, errorUpdateDescription, handleBusinessDescription, handleUpdateDescription, loadingUpdateDescription, 
        setBusinessDescription, successUpdateDescription
    } = useUpdateDescription();

    const {
        errorUpdatePointThreshold, handlePointThreshold, handleUpdatePointThreshold, loadingUpdatePointThreshold, pointThreshold, setPointThreshold, 
        successUpdatePointThreshold
    } = useUpdatePointThreshold();

    const {
        getBusinessDetails, businessDetails
    } = useGetBusiness();

    const {
        errorProfilePhoto, handleUploadProfilePhoto, loadingProfilePhoto, setBusinessUniqueId: PhotoSetBusinessUniqueId, setSelectedProfilePhoto, successProfilePhoto, 
        uploadingProfilePhotoPercentage, selectedProfilePhoto
    } = useUploadBusinessProfilePhoto();

    const {
        errorProfileCover, handleUploadProfileCover, loadingProfileCover, setBusinessUniqueId: CoverSetBusinessUniqueId, setSelectedProfileCover, successProfileCover,
        uploadingProfileCoverPercentage, selectedProfileCover
    } = useUploadBusinessProfileCover();

    const callGetBusinessDetails = getBusinessDetails;

    if (successUpdateName || successUpdateEmail || successUpdatePointThreshold || successUpdateDescription || successProfilePhoto) callGetBusinessDetails();

    if (canCallBusinessDetails) { 
        setTimeout(function () {
            callGetBusinessDetails(); 
            setCanCallBusinessDetails(false);
        }, 2000)
    }

    if (removeUpdateEmailModal) {
        const modalResponse = document.querySelector("#confirmEmailUpdate");
        modalResponse.setAttribute("display", false);
        callGetBusinessDetails(); 
        setRemoveUpdateEmailModal(null);
    }
    if (removeUpdateNameModal) {
        const modalResponse = document.querySelector("#confirmNameUpdate");
        modalResponse.setAttribute("display", false);
        callGetBusinessDetails(); 
        setRemoveUpdateNameModal(null);
    }

    const handleSelectProfilePhoto = (e) => {
        const el = e.target.files[0];
        setSelectedProfilePhoto("");
        setSelectedProfilePhoto(el);
    }
    const handleSelectProfileCover = (e) => {
        const el = e.target.files[0];
        setSelectedProfileCover("");
        setSelectedProfileCover(el);
    }

    useEffect(() => {
        if (callUseEffect) {
            if (businessDetails !== null) {
                setBusinessName(businessDetails.data.name);
                setBusinessEmail(businessDetails.data.email);
                setPointThreshold(businessDetails.data.point_threshold);
                setBusinessDescription(businessDetails.data.description);
                setBusinessCountry(businessDetails.data.country);
                setBusinessState(businessDetails.data.state);
                setCities(changeLGA(businessDetails.data.state));
                setBusinessCity(businessDetails.data.city);
                setCallUseEffect(false);
            }
        }
    }, [businessDetails, callUseEffect]);
    
    return(
        <>
            <div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1">
                <center>
                    <form className="xui-form" onSubmit={handleUploadProfilePhoto}>
                        <div className="xui-form-box">
                            <center>
                                <span className="xui-d-inline-block">Your Profile Picture</span>
                                <label htmlFor="profilePhoto">
                                    <div className="xui-w-250 xui-h-250 xui-bdr-s-dashed xui-bdr-w-1 xui-bdr-black xui-bdr-rad-1 xui-mt-1 xui-d-flex xui-flex-dir-column xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer">
                                        {
                                            selectedProfilePhoto ?
                                                <img className="xui-img-150" src={URL.createObjectURL(selectedProfilePhoto)} alt="" /> :
                                                <>
                                                    {
                                                        businessDetails ?
                                                            <img className="xui-img-150" src={businessDetails.data.photo} alt="" /> :
                                                            <img className="xui-img-40" src={GalleryAdd} alt="" />
                                                    }
                                                    <span className="xui-font-sz-90 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80">Click to select file</span>
                                                </>
                                        }
                                    </div>
                                </label>
                                <input onClick={() => { if (businessDetails) PhotoSetBusinessUniqueId(businessDetails.data.business_unique_id) }} onChange={handleSelectProfilePhoto} type={"file"} id="profilePhoto" style={{ display: "none" }} required />
                                <div className="xui-mt-1">
                                    {
                                        uploadingProfilePhotoPercentage > 0 ?
                                            <>
                                                <label htmlFor="uploader">Uploading</label>
                                                <progress className="xui-h-30" value={uploadingProfilePhotoPercentage} id="uploader" max="100">{uploadingProfilePhotoPercentage + "%"}</progress><br /><br></br>
                                            </> :
                                            ""
                                    }
                                    {
                                        loadingProfilePhoto ?
                                            <button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
                                                <Loading width="16" height="16" />
                                            </button> :
                                            <button type="submit" disabled={loadingProfileCover} className={`xui-btn ${loadingProfileCover ? "psc-btn-blue-alt" : "psc-btn-blue"} xui-font-sz-80`}>
                                                Save Changes
                                            </button>
                                    }
                                </div>
                                <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorProfilePhoto}</span></p>
                                <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successProfilePhoto}</span></p>
                            </center>
                        </div>
                    </form>
                </center>
                <center>
                    <form className="xui-form" onSubmit={handleUploadProfileCover}>
                        <div className="xui-form-box">
                            <center>
                                <span className="xui-d-inline-block">Your Cover Picture</span>
                                <label htmlFor="profileCover">
                                    <div className="xui-w-250 xui-h-250 xui-bdr-s-dashed xui-bdr-w-1 xui-bdr-black xui-bdr-rad-1 xui-mt-1 xui-d-flex xui-flex-dir-column xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer">
                                        {
                                            selectedProfileCover ?
                                                <img className="xui-img-150" src={URL.createObjectURL(selectedProfileCover)} alt="" /> :
                                                <>
                                                    {
                                                        businessDetails ?
                                                            <img className="xui-img-150" src={businessDetails.data.cover} alt="" /> :
                                                            <img className="xui-img-40" src={GalleryAdd} alt="" />
                                                    }
                                                    <span className="xui-font-sz-90 xui-text-center xui-mt-1 xui-mx-auto xui-w-fluid-80">Click to select file</span>
                                                </>
                                        }
                                    </div>
                                </label>
                                <input onClick={() => { if (businessDetails) CoverSetBusinessUniqueId(businessDetails.data.business_unique_id) }} onChange={handleSelectProfileCover} type={"file"} id="profileCover" style={{ display: "none" }} required />
                                <div className="xui-mt-1">
                                    {
                                        uploadingProfileCoverPercentage > 0 ?
                                            <>
                                                <label htmlFor="uploader">Uploading</label>
                                                <progress className="xui-h-30" value={uploadingProfileCoverPercentage} id="uploader" max="100">{uploadingProfileCoverPercentage + "%"}</progress><br /><br></br>
                                            </> :
                                            ""
                                    }
                                    {
                                        loadingProfileCover ?
                                            <button disabled className="xui-btn psc-btn-blue xui-font-sz-80">
                                                <Loading width="16" height="16" />
                                            </button> :
                                            <button type="submit" disabled={loadingProfilePhoto} className={`xui-btn ${loadingProfilePhoto ? "psc-btn-blue-alt" : "psc-btn-blue"} xui-font-sz-80`}>
                                                Save Changes
                                            </button>
                                    }
                                </div>
                                <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorProfileCover}</span></p>
                                <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successProfileCover}</span></p>
                            </center>
                        </div>
                    </form>
                </center>
            </div>
            <div className="xui-form-box xui-w-fluid-100 xui-mt-2">
                <form className="xui-form" onSubmit={(e) => e.preventDefault()}>
                    <label>Business Name</label>
                    <div className="xui-d-flex xui-flex-ai-center">
                        <input type={"text"} placeholder={businessDetails ? businessDetails.data.name : ""} readOnly={businessDetails ? false : true} value={businessName} onClick={() => { if (businessDetails) setBusinessName(businessDetails.data.name) }} onChange={handleBusinessName} required />
                    </div>
                    <div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-md-grid-col-3 xui-grid-gap-1">
                        <div className="xui-mt-1">
                            <label>Country</label>
                            <select disabled={businessDetails ? false : true} onChange={handleBusinessCountry} value={businessCountry} required>
                                <option selected disabled>Select Country</option>
                                <option selected={businessDetails ? (businessDetails.data.country === "Nigeria" ? true : false) : false} value={"Nigeria"}>Nigeria</option>
                            </select>
                        </div>
                        <div className="xui-mt-1">
                            <label>State</label>
                            <select disabled={businessDetails ? false : true} onChange={handleBusinessState} value={businessState} required>
                                <option selected disabled>Select State</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Abia" ? true : false) : false} value={"Abia"}>Abia</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Adamawa" ? true : false) : false} value={"Adamawa"}>Adamawa</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Akwa Ibom" ? true : false) : false} value={"Akwa Ibom"}>Akwa Ibom</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Anambra" ? true : false) : false} value={"Anambra"}>Anambra</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Bauchi" ? true : false) : false} value={"Bauchi"}>Bauchi</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Bayelsa" ? true : false) : false} value={"Bayelsa"}>Bayelsa</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Benue" ? true : false) : false} value={"Benue"}>Benue</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Borno" ? true : false) : false} value={"Borno"}>Borno</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Cross River" ? true : false) : false} value={"Cross River"}>Cross River</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Delta" ? true : false) : false} value={"Delta"}>Delta</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Ebonyi" ? true : false) : false} value={"Ebonyi"}>Ebonyi</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Edo" ? true : false) : false} value={"Edo"}>Edo</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Ekiti" ? true : false) : false} value={"Ekiti"}>Ekiti</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Enugu" ? true : false) : false} value={"Enugu"}>Enugu</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Federal Capital Territory" ? true : false) : false} value={"Federal Capital Territory"}>Federal Capital Territory</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Gombe" ? true : false) : false} value={"Gombe"}>Gombe</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Imo" ? true : false) : false} value={"Imo"}>Imo</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Jigawa" ? true : false) : false} value={"Jigawa"}>Jigawa</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Kaduna" ? true : false) : false} value={"Kaduna"}>Kaduna</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Kano" ? true : false) : false} value={"Kano"}>Kano</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Katsina" ? true : false) : false} value={"Katsina"}>Katsina</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Kebbi" ? true : false) : false} value={"Kebbi"}>Kebbi</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Kogi" ? true : false) : false} value={"Kogi"}>Kogi</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Kwara" ? true : false) : false} value={"Kwara"}>Kwara</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Lagos" ? true : false) : false} value={"Lagos"}>Lagos</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Nasarawa" ? true : false) : false} value={"Nasarawa"}>Nasarawa</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Niger" ? true : false) : false} value={"Niger"}>Niger</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Ogun" ? true : false) : false} value={"Ogun"}>Ogun</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Ondo" ? true : false) : false} value={"Ondo"}>Ondo</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Osun" ? true : false) : false} value={"Osun"}>Osun</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Oyo" ? true : false) : false} value={"Oyo"}>Oyo</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Plateau" ? true : false) : false} value={"Plateau"}>Plateau</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Rivers" ? true : false) : false} value={"Rivers"}>Rivers</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Sokoto" ? true : false) : false} value={"Sokoto"}>Sokoto</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Taraba" ? true : false) : false} value={"Taraba"}>Taraba</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Yobe" ? true : false) : false} value={"Yobe"}>Yobe</option>
                                <option selected={businessDetails ? (businessDetails.data.state === "Zamfara" ? true : false) : false} value={"Zamfara"}>Zamfara</option>
                            </select>
                        </div>
                        <div className="xui-mt-1">
                            <label>City</label>
                            <select disabled={businessDetails ? false : true} onChange={handleBusinessCity} value={businessCity} required>
                                <option selected disabled>Select City</option>
                                {
                                    cities.map((item, index) => {
                                        return (
                                            <option selected={businessDetails ? (businessDetails.data.country === item ? true : false) : false} key={index} value={item}>{item}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <button className="xui-btn xui-mt-2 psc-btn-blue xui-font-sz-80" xui-modal-open="confirmNameUpdate">
                        Save Changes
                    </button>
                    <p className="xui-font-sz-100 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateName}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateName}</span></p>
                </form>
            </div>
            <div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-1 xui-grid-gap-1">
                <div className="xui-mt-2">
                    <form className="xui-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Business Email</label>
                            <div className="xui-d-flex xui-flex-ai-center">
                                <input style={{ width: "calc(100% - 50px)" }} type={"email"} placeholder={businessDetails ? businessDetails.data.email : ""} readOnly={businessDetails ? false : true} value={businessEmail} onClick={() => { if (businessDetails) setBusinessEmail(businessDetails.data.email) }} onChange={handleBusinessEmail} required />
                                <div onClick={() => handleStripped(businessDetails ? businessDetails.data.stripped : "")} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text" xui-modal-open="confirmEmailUpdate">
                                    <Check width="16" height="16" />
                                </div>
                            </div>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateEmail}</span></p>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateEmail}</span></p>
                        </div>
                    </form>
                </div>
                <div className="xui-mt-2">
                    <form className="xui-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="xui-w-fluid-100 xui-lg-w-fluid-100">
                            <label>Point Threshold</label>
                            <div className="xui-d-flex xui-flex-ai-center">
                                <input style={{ width: "calc(100% - 50px)" }} type={"number"} placeholder={businessDetails ? businessDetails.data.point_threshold : ""} readOnly={businessDetails ? false : true} value={pointThreshold} onClick={() => { if (businessDetails) setPointThreshold(businessDetails.data.point_threshold) }} onChange={handlePointThreshold} required />
                                <div onClick={handleUpdatePointThreshold} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
                                    {
                                        loadingUpdatePointThreshold ?
                                            <Loading width="16" height="16" />
                                            : <Check width="16" height="16" />
                                    }
                                </div>
                            </div>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdatePointThreshold}</span></p>
                            <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdatePointThreshold}</span></p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="xui-mt-2 xui-w-fluid-100 xui-lg-w-fluid-100">
                <form className="xui-form">
                    <label>Business Description</label>
                    <div className="xui-d-flex xui-flex-ai-flex-end">
                        <textarea style={{ width: "calc(100% - 50px)" }} type={"text"} placeholder={businessDetails ? businessDetails.data.description : ""} readOnly={businessDetails ? false : true} value={businessDescription} onClick={() => { if (businessDetails) setBusinessDescription(businessDetails.data.description) }} onChange={handleBusinessDescription} required></textarea>
                        <div onClick={handleUpdateDescription} className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-bg-light-blue xui-ml-half xui-d-flex xui-flex-ai-center xui-flex-jc-center xui-cursor-pointer psc-text">
                            {
                                loadingUpdateDescription ?
                                    <Loading width="16" height="16" />
                                    : <Check width="16" height="16" />
                            }
                        </div>
                    </div>
                    <p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateDescription}</span></p>
                    <p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateDescription}</span></p>
                </form>
            </div>
            <section className='xui-modal' xui-modal="confirmNameUpdate" id="confirmNameUpdate">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1 className="5 xui-font-sz-120 xui-mb-2">Update Business Name</h1>
                        <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half xui-mb-2">Are you sure you want to continue with this action?</p>
                        {/* <p className="xui-opacity-5 xui-font-sz-90 xui-text-red xui-mt-half">We will log you out and you'll have to use your new private access url to log in ...</p> */}
                    </center>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateName}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateName}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleUpdateName} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingUpdateName ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingUpdateName ? "" : "confirmNameUpdate"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='xui-modal' xui-modal="confirmEmailUpdate" id="confirmEmailUpdate">
                <div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
                    <center>
                        <h1 className="5 xui-font-sz-120 xui-mb-2">Update Business Email</h1>
                        <p className="xui-opacity-5 xui-font-sz-100 xui-mt-half xui-mb-2">Are you sure you want to continue with this action?</p>
                        <p className="xui-opacity-5 xui-font-sz-90 xui-text-red xui-mt-half">We will log you out and you'll have to verify your new email to log in ...</p>
                    </center>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateEmail}</span></p>
                    <p className="xui-font-sz-100 xui-my-1 xui-mt-2 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateEmail}</span></p>
                    <div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button onClick={handleUpdateEmail} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
                                <span className="xui-mr-half">Yes</span>
                                {
                                    loadingUpdateEmail ?
                                        <Loading width="12" height="12" />
                                        : <Check width="20" height="20" />
                                }
                            </button>
                        </div>
                        <div className="xui-d-inline-flex xui-flex-ai-center">
                            <button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingUpdateEmail ? "" : "confirmEmailUpdate"}>
                                <span className="xui-mr-half">No</span>
                                <Close width="20" height="20" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}