import { useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import Screen from '../components/Screen';
import Content from '../components/Content';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Boxes from '../assets/images/boxes.png';
import FlowerPlant from '../assets/images/flower-plant.png';
import { getBusinessPaymentRequests } from "../api/requests";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { useRequestUserPayment } from "../hooks/useRequests";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Minus from "../icons/Minus";
import Check from "../icons/Check";
import Star from "../icons/Star";
import CheckCircle from "../icons/CheckCircle";
import { useGetBusiness } from "../hooks/useBusiness";

export default function Payments() {
	const { cookie, forceLogout } = useCookie(config.token, "");

	const { businessDetails } = useGetBusiness();

	const {
		errorRequestUserPayment, handleAmount, handlePID: RequestUserPaymentHandlePID, handleSubmitRequestUserPayment, loadingRequestUserPayment,
		amount, pid: RequestUserPaymentPID, showRequestUserPaymentModal, setAmount, setShowRequestUserPaymentModal, successRequestUserPayment, setDetails,
		userPaymentDetails, setUserPaymentDetails: PaymentAuthenticatedUserDetails, handleDetails, details
	} = useRequestUserPayment();

	const [paymentRequests, setPaymentRequests] = useState(null);
	const [errorPaymentRequests, setErrorPaymentRequests] = useState(null);
	const [loadingPaymentRequests, setLoadingPaymentRequests] = useState(false);

	const [size, setSize] = useState(50);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getPaymentRequests(page, e.target.value); };

	async function previousPaymentRequests() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getPaymentRequests(page - 1, size);
	};

	async function nextPaymentRequests() {
		if (page < paymentRequests.data.pages) setPage(page + 1);
		if (page < paymentRequests.data.pages) getPaymentRequests(page + 1, size);
	};

	async function getPaymentRequests(_page, _size) {
		setLoadingPaymentRequests(true);
		const response = await getBusinessPaymentRequests(cookie, (_page || page), (_size || size));
		setPaymentRequests(response.data);
		if (response.response_code === 403) forceLogout();
		if (response.error) setErrorPaymentRequests(response.error.response.data.message);
		setLoadingPaymentRequests(false);
	};

	useEffect(() => {
		if (paymentRequests === null) {
			getPaymentRequests();
		}
	}, [paymentRequests]);

	if (successRequestUserPayment) {
		const modalResponse = document.querySelector("#paymentUserAuthenticated");
		modalResponse.setAttribute("display", true);
	}

	async function continueSuccessRequestUserPayment() {
		const modalResponse = document.querySelector("#paymentUserAuthenticated");
		modalResponse.setAttribute("display", false);
		PaymentAuthenticatedUserDetails(null);
		getPaymentRequests();
	}
	return (
		<>
			<Screen aside="true" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All payment requests</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View payments history and track records.</p>
							</div>
						</div>
						{
							loadingPaymentRequests ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									paymentRequests && paymentRequests.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-min-w-20'>S/N</th>
														<th className='xui-min-w-250'>User</th>
														<th className='xui-min-w-150'>Star</th>
														<th className='xui-min-w-200'>Amount</th>
														<th className='xui-min-w-200'>Status</th>
														<th className='xui-min-w-300'>Timestamp</th>
													</tr>
												</thead>
												<tbody>
													{paymentRequests.data.rows.sort((a, b) => new Date(a.updatedAt.date + " " + a.updatedAt.time).getTime() < new Date(b.updatedAt.date + " " + b.updatedAt.time).getTime() ? 1 : -1).map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	{i + 1}
																</div>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<p>{data.user_data ? data.user_data.firstname : "No"} {data.user_data ? data.user_data.lastname : "Name"} ({data.user_data ? data.user_data.pid : "No PID"})</p>
																</div>
															</td>
															<td className='xui-opacity-5'>
																{
																	data.user_data ?
																		(
																			<span>

																				{
																					data.user_data.star === 0 ?
																						<div className=''>
																							<p>No star</p>
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 1 ?
																						<div className=''>
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 2 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 3 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 4 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 5 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																			</span>
																		) :
																		<div className=''>
																			<p>No star</p>
																		</div>
																}
															</td>
															<td className='xui-opacity-5 xui-font-w-bold'>
																<span>{"NGN " + data.amount.toLocaleString()}</span>
															</td>
															<td className=''>
																{
																	data.status === "Authenticated" || data.status === "Completed" ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{data.status}</span> : ""
																}
																{
																	data.status === "Pending" ?
																		<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>{data.status}</span> : ""
																}
																{
																	data.status === "Ineligible" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Insufficient Balance</span> : ""
																}
																{
																	data.status === "Unauthenticated" || data.status === "Timeout" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.status}</span> : ""
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorPaymentRequests}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingPaymentRequests ?
								<Loading width="12" height="12" /> :
								(
									paymentRequests && paymentRequests.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'>{page}</span> of {paymentRequests ? paymentRequests.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousPaymentRequests}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextPaymentRequests}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
					<center className="xui-mt-4 xui-lg-d-none xui-md-d-none">
						<span className='xui-opacity-4 xui-font-sz-100 xui-font-w-700 xui-open-sidebar'>Click to open right sidebar</span>
					</center>
				</Content>
				<div className="aside psc-bg-light-blue xui-py-2 xui-px-1-half">
					<div className='xui-mb-3'>
						<div className='xui-d-flex xui-flex-ai-baseline xui-flex-jc-flex-end'>
							<div className='xui-pr-1 '>
								<img className='xui-img-100' src={FlowerPlant} alt='flower plant' />
							</div>
						</div>
						<div className='psc-bg-light-blue-ii xui-px-1 xui-pt-5 xui-pb-1 xui-mt--4'>
							<form className="xui-form" layout="2" onSubmit={handleSubmitRequestUserPayment}>
								<h1 className='xui-font-sz-110 xui-mt-half'>Create Invoice</h1>
								<div className="xui-mt-2">
									<label>Passcoder ID</label>
									<input type="text" className="xui-bdr-black" minLength={6} maxLength={6} value={RequestUserPaymentPID} onChange={RequestUserPaymentHandlePID} placeholder="Enter user Passcoder ID" required ></input>
								</div>
								<div className="xui-mt-2">
									<label>Amount</label>
									<input type={"number"} className="xui-bdr-black" min={1} value={amount} onChange={handleAmount} placeholder="Bill amount" required ></input>
								</div>
								<div className="xui-mt-2">
									<label>Description</label>
									<textarea type={"text"} value={details} maxLength={300} onChange={handleDetails} placeholder="Enter description (optional)"></textarea>
								</div>
								<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-black"><span className="xui-font-w-bold psc-text-red">{loadingRequestUserPayment ? "Awaiting authentication" : ""}</span></p>
								<button disabled={loadingRequestUserPayment || (businessDetails ? !businessDetails.data.verified : true)} className={`xui-btn-block ${loadingRequestUserPayment ? "psc-btn-blue xui-mt-1" : "psc-btn-blue-alt xui-mt-2"} xui-bdr-rad-half xui-text-center xui-font-sz-85`}>
									<center>
										{
											loadingRequestUserPayment ?
												<Loading width="12" height="12" />
												: "Request Payment"
										}
									</center>
								</button>
							</form>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorRequestUserPayment}</span></p>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successRequestUserPayment}</span></p>
						</div>
					</div>
				</div>
			</Screen>
			<section className='xui-modal' xui-modal="paymentUserAuthenticated" id="paymentUserAuthenticated">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<h1>Payment Summary</h1>
					<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Visible details of the user below</p>
					{
						userPaymentDetails ?
							<>
								<center className="xui-m-1-half">
									<div className="xui-w-200 xui-h-200">
										<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={userPaymentDetails.photo} alt={userPaymentDetails.name + " Selfie Image"} />
									</div>
								</center>
								<center>
									<p className="xui-opacity-4 xui-font-sz-150 xui-m-half">{userPaymentDetails.name}</p>
									<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {userPaymentDetails.pid}</b>
									<center>
										<div className="xui-d-inline-flex xui-flex-ai-center">
											<span>
												{
													userPaymentDetails.star === 0 ?
														<div className='xui-m-half'>
															<p>No star</p>
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 1 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 2 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 3 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 4 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 5 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
											</span>
										</div>
									</center>
									<div className="xui-d-inline-flex xui-flex-ai-center">
										<span><CheckCircle width="20" height="20" /></span>
										<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Successfully received NGN {userPaymentDetails.amount_txt}</p>
									</div>
								</center>
								<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly ">
									<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Total Points: <b>{userPaymentDetails.user_points.toLocaleString()}</b></p>
									<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Points with you: <b>{userPaymentDetails.user_business_points.toLocaleString()}</b></p>
								</div>
								<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
									<button onClick={continueSuccessRequestUserPayment} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
										<span className="xui-mr-half">Complete</span>
										<Arrowright width="12" height="12" />
									</button>
								</div>
							</> :
							<center>
								<Loading width="12" height="12" />
							</center>
					}
				</div>
			</section>
		</>
	);
}