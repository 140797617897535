import axios from 'axios';
import { config } from '../config';

const getAccessDetails = async function (stripped) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/access/details`,
			{
				stripped
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getBusiness = async function (token) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessMetrics = async function (token) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/metrics`,
			{

			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getCompanyBankAccount = async function () {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/company/bank/account`,
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addPremiumUpgrade = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/add/premium/upgrade`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const completePremiumUpgrade = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/complete/premium/upgrade`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const cancelPremiumUpgrade = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/cancel/premium/upgrade`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-key': config.internalKey,
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getAccessDetails, getBusiness, getBusinessMetrics, getCompanyBankAccount, addPremiumUpgrade, completePremiumUpgrade, cancelPremiumUpgrade };