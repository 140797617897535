import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { addBusinessAnnouncement } from "../api/announcements";

const useBusinessAnnouncement = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingBusinessAnnouncement, setLoadingBusinessAnnouncement] = useState(false);
	const [removeBusinessAnnouncementModal, setRemoveBusinessAnnouncementModal] = useState(null);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const [errorBusinessAnnouncement, setErrorBusinessAnnouncement] = useState(null);
	const [successBusinessAnnouncement, setSuccessBusinessAnnouncement] = useState(null);

	const handleTitle = (e) => { e.preventDefault(); setTitle(e.target.value); };
	const handleDescription = (contents) => { setDescription(contents); };

	const handleSubmitBusinessAnnouncement = (e) => {
		e.preventDefault();

		if (!loadingBusinessAnnouncement) {
			if (title.length < 3) {
				setErrorBusinessAnnouncement(null);
				setSuccessBusinessAnnouncement(null);
				setErrorBusinessAnnouncement("Title is required | Min character - 3");
				setTimeout(function () {
					setErrorBusinessAnnouncement(null);
				}, 2500)
			} else if (title.length > 50) {
				setErrorBusinessAnnouncement("Invalid Title | Max character - 50");
				setTimeout(function () {
					setErrorBusinessAnnouncement(null);
				}, 2500)
			} else if (description.length < 3) {
				setErrorBusinessAnnouncement("Description is required | Min character - 3");
				setTimeout(function () {
					setErrorBusinessAnnouncement(null);
				}, 2500)
			} else if (description.length > 65535) {
				setErrorBusinessAnnouncement("Invalid Description | Max length reached");
				setTimeout(function () {
					setErrorBusinessAnnouncement(null);
				}, 2500)
			} else {
				setLoadingBusinessAnnouncement(true);

				const addBusinessAnnouncementRes = addBusinessAnnouncement(cookie, {
					title,
					description
				})

				addBusinessAnnouncementRes.then(res => {
					setLoadingBusinessAnnouncement(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorBusinessAnnouncement(error);
							setTimeout(function () {
								setErrorBusinessAnnouncement(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorBusinessAnnouncement(error);
							setTimeout(function () {
								setErrorBusinessAnnouncement(null);
							}, 2000)
						}
					} else {
						setErrorBusinessAnnouncement(null);
						setSuccessBusinessAnnouncement(`Announcement created successfully!`);

						setTimeout(function () {
							setSuccessBusinessAnnouncement(null);
							setRemoveBusinessAnnouncementModal(true);
							setTitle(""); setDescription("");
						}, 2500)
					}
				}).catch(err => {
					setLoadingBusinessAnnouncement(false);
				})

			}
		}
	};

	return {
		cookie, loadingBusinessAnnouncement, removeBusinessAnnouncementModal, title, description, errorBusinessAnnouncement, successBusinessAnnouncement,
		handleTitle, handleDescription, handleSubmitBusinessAnnouncement, setRemoveBusinessAnnouncementModal
	};
};

export { useBusinessAnnouncement };