import axios from 'axios';
import { config } from '../config';

const getBusinessRequests = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/requests`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessPaymentRequests = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/payment/requests`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessRequestsViaOffer = async function (token, page, size, offer_unique_id) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/requests/via/offer`,
			{
				page,
				size,
				offer_unique_id
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const offerAuthenticateUser = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/user/offer/authentication`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const requestUserPayment = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/user/payment/request`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getBusinessRequests, getBusinessRequestsViaOffer, getBusinessPaymentRequests, offerAuthenticateUser, requestUserPayment };