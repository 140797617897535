import axios from 'axios';
import { config } from '../config';

const getBusinessOffers = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/offers`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessOffer = async function (token, unique_id) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/offer`,
			{
				unique_id
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addBusinessOffer = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/offer/add`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateOfferDetails = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/offer/update/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateOfferLimit = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/offer/update/limit`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateOfferCriteria = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/offer/update/criteria`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const deleteOffer = async function (token, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/business/offer`,
			{
				data: {
					token,
					...payload
				}
			},
			// {
			// 	headers: {
			// 		'passcoder-access-token': token
			// 	}
			// }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getBusinessOffer, addBusinessOffer, deleteOffer, getBusinessOffers, updateOfferLimit, updateOfferCriteria, updateOfferDetails };
