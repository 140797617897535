import { useState, useEffect } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { validateTicket } from "../api/tickets";

const useValidateTicket = () => {

	const { cookie } = useCookie(config.token, "");

	const [loadingValidateTicket, setLoadingValidateTicket] = useState(false);
	const [removeValidateTicketModal, setRemoveValidateTicketModal] = useState(null);
	const [validateTicketCode, setValidateTicketCode] = useState("");

	const [errorValidateTicket, setErrorValidateTicket] = useState(null);
	const [successValidateTicket, setSuccessValidateTicket] = useState(null);

	const handleValidateTicketCode = (e) => { e.preventDefault(); setValidateTicketCode(e.target.value.toLocaleUpperCase()); };

	const handleValidateTicket = () => {

		if (!loadingValidateTicket) {
			if (validateTicketCode.length < 1) {
				setErrorValidateTicket(null);
				setSuccessValidateTicket(null);
				setErrorValidateTicket("Code is required");
				setTimeout(function () {
					setErrorValidateTicket(null);
				}, 2500)
			} else if (!validateTicketCode.length === 10) {
				setErrorValidateTicket("Code invalid");
				setTimeout(function () {
					setErrorValidateTicket(null);
				}, 2500)
			} else {
				setLoadingValidateTicket(true);

				const validateTicketRes = validateTicket(cookie, {
					code: validateTicketCode
				})

				validateTicketRes.then(res => {
					setLoadingValidateTicket(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorValidateTicket(error);
							setTimeout(function () {
								setErrorValidateTicket(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorValidateTicket(error);
							setTimeout(function () {
								setErrorValidateTicket(null);
							}, 2000)
						}
					} else {
						setErrorValidateTicket(null);
						setSuccessValidateTicket(`Ticket validated successfully!`);

						setTimeout(function () {
							setSuccessValidateTicket(null);
							setRemoveValidateTicketModal(true);
							setValidateTicketCode("");
						}, 2000)
					}
				}).catch(err => {
					setLoadingValidateTicket(false);
				})

			}
		}
	};

	return {
		cookie, loadingValidateTicket, removeValidateTicketModal, validateTicketCode, errorValidateTicket, successValidateTicket,
		handleValidateTicket, setRemoveValidateTicketModal, setValidateTicketCode, handleValidateTicketCode
	};
};

export { useValidateTicket };