import { useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import Screen from '../components/Screen';
import Content from '../components/Content';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Boxes from '../assets/images/boxes.png';
import FlowerPlant from '../assets/images/flower-plant.png';
import { getBusinessEvents } from "../api/events";
import { getBusinessTicketViaCode, getBusinessTicketsViaEvent, getBusinessTickets } from "../api/tickets";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { useValidateTicket } from "../hooks/useTickets";
import { useRequestUserPayment } from "../hooks/useRequests";
import Loading from "../icons/Loading";
import Close from "../icons/Close";
import Edit from "../icons/Edit";
import Copy from "../icons/Copy";
import EyeOpen from "../icons/EyeOpen";
import Ticket from "../icons/Ticket";
import Plus from "../icons/Plus";
import Minus from "../icons/Minus";
import Delete from "../icons/Delete";
import Check from "../icons/Check";
import Star from "../icons/Star";
import CheckCircle from "../icons/CheckCircle";
import { useGetBusiness } from "../hooks/useBusiness";
import Filter from "../icons/Filter";

export default function Tickets() {
	const { cookie, forceLogout } = useCookie(config.token, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);
	const events_link = "https://events.passcoder.io/";

	const { businessDetails } = useGetBusiness();

	const {
		errorRequestUserPayment, handleAmount, handlePID: RequestUserPaymentHandlePID, handleSubmitRequestUserPayment, loadingRequestUserPayment,
		amount, pid: RequestUserPaymentPID, showRequestUserPaymentModal, setAmount, setShowRequestUserPaymentModal, successRequestUserPayment, setDetails,
		userPaymentDetails, setUserPaymentDetails: PaymentAuthenticatedUserDetails, handleDetails, details
	} = useRequestUserPayment();

	const {
		errorValidateTicket, handleValidateTicket, handleValidateTicketCode, loadingValidateTicket, removeValidateTicketModal, setRemoveValidateTicketModal,
		setValidateTicketCode, successValidateTicket, validateTicketCode
	} = useValidateTicket();

	const timestamp_str = (date) => {
		const d = new Date(date);
		return {
			fulldate: d.toDateString() + " at " + d.toLocaleTimeString(),
			date: d.toDateString(),
			time: d.toLocaleTimeString(),
		};
	};

	const showPreview = function (file) {
		const preview = file;

		window.open(preview, "_blank");
	};

	const [currentFunction, setCurrentFunction] = useState("getAllTickets");

	const [eventTickets, setEventTickets] = useState(null);

	const [allTickets, setAllTickets] = useState(null);
	const [errorAllTickets, setErrorAllTickets] = useState(null);
	const [loadingAllTickets, setLoadingAllTickets] = useState(false);

	const [size, setSize] = useState(20);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); transactionsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); transactionsByPage(parseInt(e.target.value), size); };
	const handleEventTickets = (e) => { e.preventDefault(); setEventTickets(e.target.value === "Select to Reset" ? null : e.target.value); setPage(1); if (e.target.value !== null && e.target.value !== "Select to Reset") setCurrentFunction("getAllEventTickets"); getAllEventTickets(e.target.value, page, size); };

	async function callLastTicketFunction() {
		switch (currentFunction) {
			case "getAllTickets":
				getAllTickets(page, size);
				break;
			case "getAllEventTickets":
				getAllEventTickets(eventTickets, page, size);
				break;
			// default:
			// 	getAllTickets(page, size);
		}
	};

	async function transactionsBySize(size) {
		switch (currentFunction) {
			case "getAllTickets":
				getAllTickets(page, size);
				break;
			case "getAllEventTickets":
				getAllEventTickets(eventTickets, page, size);
				break;
			default:
				getAllTickets(page, size);
		}
	};

	async function transactionsByPage(page) {
		switch (currentFunction) {
			case "getAllTickets":
				getAllTickets(page, size);
				break;
			case "getAllEventTickets":
				getAllEventTickets(eventTickets, page, size);
				break;
			default:
				getAllTickets(page, size);
		}
	};

	async function previousTickets() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) { 
			switch (currentFunction) {
				case "getAllTickets":
					getAllTickets(page - 1, size);
					break;
				case "getAllEventTickets":
					getAllEventTickets(eventTickets, page - 1, size);
					break;
				default:
					getAllTickets(page - 1, size);
			}
		}
	};

	async function nextTickets() {
		if (page < allTickets.data.pages) setPage(page + 1);
		if (page < allTickets.data.pages) { 
			switch (currentFunction) {
				case "getAllTickets":
					getAllTickets(page + 1, size);
					break;
				case "getAllEventTickets":
					getAllEventTickets(eventTickets, page + 1, size);
					break;
				default:
					getAllTickets(page + 1, size);
			}
		}
	};

	async function getAllTickets(_page, _size) {
		setLoadingAllTickets(true);
		const response = await getBusinessTickets(cookie, (_page || page), (_size || size));
		setAllTickets(response.data);
		if (response.response_code === 403) forceLogout();
		if (response.error) setErrorAllTickets(response.error.response.data.message);
		setLoadingAllTickets(false);
	};

	async function getAllEventTickets(event_unique_id, _page, _size) {
		setLoadingAllTickets(true);
		const response = await getBusinessTicketsViaEvent(cookie, (_page || page), (_size || size), ({ event_unique_id: event_unique_id }));
		setAllTickets(response.data);
		if (response.error) setErrorAllTickets(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllTickets(false);
	};

	const [loadingViewTicket, setLoadingViewTicket] = useState(false);
	const [errorViewTicket, setErrorViewTicket] = useState(null);
	const [viewTicket, setViewTicket] = useState(null);

	async function getTicket() {
		setLoadingViewTicket(true)
		setViewTicket(null);
		setErrorViewTicket(null);
		const response = await getBusinessTicketViaCode(cookie, { code: validateTicketCode });
		if (!response.err) {
			setViewTicket(response.data);
		} else { setErrorViewTicket(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewTicket(false)
	};
	
	const [allEvents, setAllEvents] = useState(null);
	const [errorAllEvents, setErrorAllEvents] = useState(null);
	const [loadingAllEvents, setLoadingAllEvents] = useState(false);

	async function getAllEvents(_page, _size) {
		setLoadingAllEvents(true);
		const response = await getBusinessEvents(cookie, 1, 100);
		setAllEvents(response.data);
		if (response.error) setErrorAllEvents(response.error.response.data.message);
		setLoadingAllEvents(false);
	};

	useEffect(() => {
		if (allTickets === null) {
			callLastTicketFunction();
		}
		if (allEvents === null) {
			getAllEvents();
		}
	}, [allTickets]);

	if (successRequestUserPayment) {
		const modalResponse = document.querySelector("#paymentUserAuthenticated");
		modalResponse.setAttribute("display", true);
	}

	async function continueSuccessRequestUserPayment() {
		const modalResponse = document.querySelector("#paymentUserAuthenticated");
		modalResponse.setAttribute("display", false);
		PaymentAuthenticatedUserDetails(null);
	}

	if (removeValidateTicketModal) {
		const modalResponse = document.querySelector("#ticketDetails");
		modalResponse.setAttribute("display", false);
		setRemoveValidateTicketModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allTickets ? allTickets.data.pages : 0).fill(0);

	const getObjectValues = (obj) => {
		let values = [];
		Object.keys(obj).some((key) => {
			values.push(
				<>
					<br></br>
					<div className='xui-d-inline-flex xui-flex-ai-center'>
						<p id={obj[key]}><b>{key}</b> : {obj[key].length > 300 ? obj[key].slice(0, 200) + " ..." : obj[key]}</p>
						<span title="Copy Text" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(obj[key]); setTextCopied(obj[key]); }}>
							{copiedText && textCopied === obj[key] ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
						</span>
					</div>
				</>
			)
		});
		return values;
	};

	return (
		<>
			<Screen aside="true" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All your tickets sold, from various events</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View, filter and export records of all tickets purchased.</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={eventTickets} onChange={handleEventTickets} disabled={businessDetails ? !businessDetails.data.verified : true} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!eventTickets ?
													<option selected disabled>Filter By Event</option> :
													<option value={null}>Select to Reset</option>
											}
											{
												allEvents ? (
													allEvents.data.rows.map((item, index) => {
														return (
															<option key={index} value={item.unique_id}>{item.name}</option>
														)
													})
												) : ""
											}
										</select>
									</div>
								</div>
							</div>
						</div>
						{
							loadingAllTickets ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allTickets && allTickets.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-min-w-20'>S/N</th>
														<th className='xui-min-w-250'>User</th>
														<th className='xui-min-w-150'>Star</th>
														<th className='xui-min-w-200'>Contact</th>
														<th className='xui-min-w-300'>Name</th>
														<th className='xui-min-w-150'>Image</th>
														<th className='xui-min-w-200'>Ticket</th>
														<th className='xui-min-w-350'>Other</th>
														<th className='xui-min-w-200'>Total Amount</th>
														<th className='xui-min-w-200'>Status</th>
														<th className='xui-min-w-300'>Purchase Date</th>
														<th className='xui-min-w-300'>Last Updated</th>
													</tr>
												</thead>
												<tbody>
													{allTickets.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	{i + 1}
																</div>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<p>{data.user_data ? data.user_data.firstname : "No"} {data.user_data ? data.user_data.lastname : "Name"} ({data.user_data ? data.user_data.pid : "No PID"})</p>
																</div>
															</td>
															<td className='xui-opacity-5'>
																{
																	data.user_data ?
																		(
																			<span>

																				{
																					data.user_data.star === 0 ?
																						<div className=''>
																							<p>No star</p>
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 1 ?
																						<div className=''>
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 2 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 3 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 4 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																				{
																					data.user_data.star === 5 ?
																						<div className=''>
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																							<Star width="18" height="18" />
																						</div>
																						: ""
																				}
																			</span>
																		) :
																		<div className=''>
																			<p>No star</p>
																		</div>
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.user_data.phone_number}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<p>{allTickets.data.event_data ? allTickets.data.event_data.name : data.event_data.name}</p>
																	<span title="Copy Event Link" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText((events_link + (allTickets.data.event_data ? allTickets.data.event_data.stripped : data.event_data.stripped))); setTextCopied((events_link + (allTickets.data.event_data ? allTickets.data.event_data.stripped : data.event_data.stripped))); }}>
																		{copiedText && textCopied === (events_link + (allTickets.data.event_data ? allTickets.data.event_data.stripped : data.event_data.stripped)) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className=''>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<img className="xui-img-50" src={allTickets.data.event_data ? allTickets.data.event_data.image : data.event_data.image} alt="Event Image" />
																	{/* <span title="Copy Link" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(allTickets.data.event_data ? allTickets.data.event_data.image : data.event_data.image); setTextCopied(allTickets.data.event_data ? allTickets.data.event_data.image : data.event_data.image); }}>
																		{copiedText && textCopied === allTickets.data.event_data ? allTickets.data.event_data.image : data.event_data.image ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span> */}
																	<span title="View Image" className="xui-cursor-pointer xui-mx-1" onClick={() => { showPreview(allTickets.data.event_data ? allTickets.data.event_data.image : data.event_data.image); }}>
																		<EyeOpen width="16" height="16" />
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.event_ticket_data.name} | <span>{data.event_ticket_data.amount === 0 ? "Free" : "NGN " + data.event_ticket_data.amount.toLocaleString()}</span> | x{data.quantity}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>
																	{
																		data.other !== null ? 
																			getObjectValues(data.other).map(val => {
																				return val;
																			}) : "No additional details"
																	}
																</span>
															</td>
															<td className='xui-opacity-5 xui-font-w-bold'>
																<span>{data.total_amount === 0 ? "Free" : "NGN " + data.total_amount.toLocaleString()}</span>
															</td>
															<td className=''>
																{
																	data.status === "Authenticated" || data.status === "Completed" ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>{data.status}</span> : ""
																}
																{
																	data.status === "Unauthenticated" || data.status === "Timeout" ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>{data.status}</span> : ""
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorAllTickets}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllTickets ?
								<Loading width="12" height="12" /> :
								(
									allTickets && allTickets.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allTickets ? allTickets.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousTickets}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextTickets}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
					<center className="xui-mt-4 xui-lg-d-none">
						<span className='xui-opacity-4 xui-font-sz-100 xui-font-w-700 xui-open-sidebar'>Click to open right sidebar</span>
					</center>
				</Content>
				<div className="aside psc-bg-light-blue xui-py-2 xui-px-1-half">
					<div className='xui-mt-1 xui-mb-3'>
						<div className='xui-d-flex xui-flex-ai-baseline xui-flex-jc-flex-end'>
							<div className='xui-pr-1 '>
								<img className='xui-img-100' src={FlowerPlant} alt='flower plant' />
							</div>
						</div>
						<div className='psc-bg-light-blue-ii xui-px-1 xui-pt-5 xui-pb-1 xui-mt--4'>
							<form className="xui-form" layout="2" onSubmit={(e) => { e.preventDefault(); }}>
								<h1 className='xui-font-sz-110 xui-mt-half'>Validate Ticket</h1>
								<div className="xui-mt-2">
									<label>Code</label>
									<input type="text" className="xui-bdr-black" minLength={10} maxLength={10} value={validateTicketCode} onChange={handleValidateTicketCode} placeholder="Enter ticket code" required ></input>
								</div>
								<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-black"><span className="xui-font-w-bold psc-text-red">{loadingViewTicket ? "Checking ..." : ""}</span></p>
								<button onClick={() => getTicket()} disabled={loadingViewTicket || (businessDetails ? !businessDetails.data.verified : true) || validateTicketCode.length !== 10} className={`xui-btn-block ${loadingViewTicket ? "psc-btn-blue xui-mt-1" : "psc-btn-blue-alt xui-mt-2"} xui-bdr-rad-half xui-text-center xui-font-sz-85`} xui-modal-open="ticketDetails">
									<center>
										{
											loadingViewTicket ?
												<Loading width="12" height="12" />
												: "Check Ticket"
										}
									</center>
								</button>
							</form>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorViewTicket}</span></p>
						</div>
					</div>
					<div className='xui-mt-5 xui-mb-3'>
						<div className='xui-d-flex xui-flex-ai-baseline xui-flex-jc-flex-end'>
							<div className='xui-pr-1 '>
								<img className='xui-img-100' src={FlowerPlant} alt='flower plant' />
							</div>
						</div>
						<div className='psc-bg-light-blue-ii xui-px-1 xui-pt-5 xui-pb-1 xui-mt--4'>
							<form className="xui-form" layout="2" onSubmit={handleSubmitRequestUserPayment}>
								<h1 className='xui-font-sz-110 xui-mt-half'>Create Invoice</h1>
								<div className="xui-mt-2">
									<label>Passcoder ID</label>
									<input type="text" className="xui-bdr-black" minLength={6} maxLength={6} value={RequestUserPaymentPID} onChange={RequestUserPaymentHandlePID} placeholder="Enter user Passcoder ID" required ></input>
								</div>
								<div className="xui-mt-2">
									<label>Amount</label>
									<input type={"number"} className="xui-bdr-black" min={1} value={amount} onChange={handleAmount} placeholder="Bill amount" required ></input>
								</div>
								<div className="xui-mt-2">
									<label>Description</label>
									<textarea type={"text"} value={details} maxLength={300} onChange={handleDetails} placeholder="Enter description (optional)"></textarea>
								</div>
								<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-black"><span className="xui-font-w-bold psc-text-red">{loadingRequestUserPayment ? "Awaiting authentication" : ""}</span></p>
								<button disabled={loadingRequestUserPayment || (businessDetails ? !businessDetails.data.verified : true)} className={`xui-btn-block ${loadingRequestUserPayment ? "psc-btn-blue xui-mt-1" : "psc-btn-blue-alt xui-mt-2"} xui-bdr-rad-half xui-text-center xui-font-sz-85`}>
									<center>
										{
											loadingRequestUserPayment ?
												<Loading width="12" height="12" />
												: "Request Payment"
										}
									</center>
								</button>
							</form>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorRequestUserPayment}</span></p>
							<p className="xui-font-sz-90 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successRequestUserPayment}</span></p>
						</div>
					</div>
				</div>
			</Screen>
			<section className='xui-modal' xui-modal="paymentUserAuthenticated" id="paymentUserAuthenticated">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<h1>Payment Summary</h1>
					<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Visible details of the user below</p>
					{
						userPaymentDetails ?
							<>
								<center className="xui-m-1-half">
									<div className="xui-w-200 xui-h-200">
										<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={userPaymentDetails.photo} alt={userPaymentDetails.name + " Selfie Image"} />
									</div>
								</center>
								<center>
									<p className="xui-opacity-4 xui-font-sz-150 xui-m-half">{userPaymentDetails.name}</p>
									<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {userPaymentDetails.pid}</b>
									<center>
										<div className="xui-d-inline-flex xui-flex-ai-center">
											<span>
												{
													userPaymentDetails.star === 0 ?
														<div className='xui-m-half'>
															<p>No star</p>
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 1 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 2 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 3 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 4 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
												{
													userPaymentDetails.star === 5 ?
														<div className='xui-m-half'>
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
															<Star width="18" height="18" />
														</div>
														: ""
												}
											</span>
										</div>
									</center>
									<div className="xui-d-inline-flex xui-flex-ai-center">
										<span><CheckCircle width="20" height="20" /></span>
										<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Successfully received NGN {userPaymentDetails.amount_txt}</p>
									</div>
								</center>
								<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly ">
									<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Total Points: <b>{userPaymentDetails.user_points.toLocaleString()}</b></p>
									<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Points with you: <b>{userPaymentDetails.user_business_points.toLocaleString()}</b></p>
								</div>
								<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
									<button onClick={continueSuccessRequestUserPayment} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
										<span className="xui-mr-half">Complete</span>
										<Arrowright width="12" height="12" />
									</button>
								</div>
							</> :
							<center>
								<Loading width="12" height="12" />
							</center>
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="ticketDetails" id="ticketDetails">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="ticketDetails">
						<Close width="24" height="24" />
					</div>
					<h1>#{validateTicketCode} ticket details</h1>
					<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Visible details of this ticket below</p>
					{
						loadingViewTicket ?
							<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
							viewTicket && viewTicket.success ?
								<>
									<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
										<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-7">
											<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewTicket.data.user_data.photo} alt={viewTicket.data.user_data.firstname + (viewTicket.data.user_data.middlename ? " " + viewTicket.data.user_data.middlename + " " : " ") + viewTicket.data.user_data.lastname + " Selfie Image"} />
											<center className="xui-mt-1 xui-mb-4">
												<b className="xui-opacity-4 xui-font-sz-100 xui-m-half">PID - {viewTicket.data.user_data.pid}</b>
												<center>
													<div className="xui-d-inline-flex xui-flex-ai-center">
														<span>
															{
																viewTicket.data.user_data.star === 0 ?
																	<div className='xui-m-half'>
																		<p>No star</p>
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 1 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 2 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 3 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 4 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
															{
																viewTicket.data.user_data.star === 5 ?
																	<div className='xui-m-half'>
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																		<Star width="18" height="18" />
																	</div>
																	: ""
															}
														</span>
													</div>
												</center>
												<p className="xui-mb-1">{viewTicket.data.user_data.firstname + (viewTicket.data.user_data.middlename ? " " + viewTicket.data.user_data.middlename + " " : " ") + viewTicket.data.user_data.lastname}</p>
											</center>
										</div>
										<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7
										">
											<img className="xui-img-responsive xui-bdr-rad-half xui-bg-pos-center xui-bg-sz-cover" src={viewTicket.data.event_data.image} alt={viewTicket.data.event_data.name + " Event Image"} />
											<p className="xui-opacity-4 xui-font-sz-110 xui-text-center xui-m-half">{viewTicket.data.event_data.name}</p>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half"><span className="xui-font-w-bold">Code -</span> {viewTicket.data.code}</p>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half"><span className="xui-font-w-bold">Location -</span> {viewTicket.data.event_data.location}</p>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half"><span className="xui-font-w-bold">Duration -</span>
												{
													viewTicket.data.event_data.start || viewTicket.data.event_data.end ?
														<span>{viewTicket.data.event_data.start ? (viewTicket.data.event_data.end ? timestamp_str(viewTicket.data.event_data.start).fulldate + " to " : "Starts - " + timestamp_str(viewTicket.data.event_data.start).fulldate) : ""}{viewTicket.data.event_data.end ? (viewTicket.data.event_data.start ? timestamp_str(viewTicket.data.event_data.end).fulldate : "Ends - " + timestamp_str(viewTicket.data.event_data.end).fulldate) : ""}</span> :
														<span className='xui-badge xui-badge-success xui-font-sz-90 xui-bdr-rad-half'>No duration</span>
												}
											</p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">
												<span className="xui-font-w-bold">Other Details - </span>
												{
													viewTicket.data.other !== null ?
														getObjectValues(viewTicket.data.other).map(val => {
															return val;
														}) : " No additional details"
												}
											</p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">
												{
													viewTicket.data.status === "Authenticated" || viewTicket.data.status === "Completed" ?
														<span className='xui-badge xui-badge-success xui-font-sz-90 xui-bdr-rad-half'>{viewTicket.data.status}</span> : ""
												}
												{
													viewTicket.data.status === "Unauthenticated" || viewTicket.data.status === "Timeout" ?
														<span className='xui-badge xui-badge-danger xui-font-sz-90 xui-bdr-rad-half'>{viewTicket.data.status}</span> : ""
												}
											</p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-100 xui-text-center xui-m-half"><span className="xui-font-w-bold">{viewTicket.data.event_ticket_data.name} (x{viewTicket.data.quantity})</span></p>
											<hr></hr>
											<p className="xui-opacity-4 xui-font-sz-100 xui-text-center xui-m-half">
												{
													viewTicket.data.total_amount === 0 ?
														<span className='xui-badge xui-badge-success xui-font-sz-100 xui-bdr-rad-half'>Free</span> :
														<b>NGN {viewTicket.data.total_amount.toLocaleString()}</b>
												}
											</p>
										</div>
									</div>
									<center>
										<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewTicket.data.createdAt.fulldate} | Last Updated - {viewTicket.data.updatedAt.fulldate}</p>
									</center>
									<p className="xui-font-sz-90 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorValidateTicket}</span></p>
									<p className="xui-font-sz-90 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successValidateTicket}</span></p>
									<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
										{
											viewTicket.data.status === "Authenticated" ? 
												<button disabled={viewTicket.data.status === "Authenticated"} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
													<span className="xui-mr-half">Validated</span>
													<Check width="16" height="16" />
												</button> : 
												(
													viewTicket.data.status === "Timeout" ? 
														<button disabled={true} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85">
															<span className="xui-mr-half">Expired</span>
															<Close width="16" height="16" />
														</button> : 
														<button disabled={loadingValidateTicket || viewTicket.data.status === "Authenticated"} onClick={handleValidateTicket} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
															<span className="xui-mr-half">Validate</span>
															{
																loadingValidateTicket ?
																	<Loading width="12" height="12" />
																	: <Arrowright width="16" height="16" />
															}
														</button>
												)
										}
									</div>
								</> :
								<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
									<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
										<center className="xui-text-red">
											<Close width="100" height="100" />
											<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewTicket}</h3>
										</center>
									</div>
								</div>
					}
				</div>
			</section>
		</>
	);
}