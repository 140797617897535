import axios from 'axios';
import { config } from '../config';

const getBusinessEvents = async function (token, page, size) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/events`,
			{
				page,
				size
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getBusinessEvent = async function (token, unique_id) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/event`,
			{
				unique_id
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const getBusinessEventImage = async function (payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/proofs/business/event/image`,
			{ ...payload }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const addBusinessEvent = async function (token, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/business/event/add`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventName = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/update/name`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventDetails = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/update/details`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventDuration = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/update/duration`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventLocation = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/update/location`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventDescription = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/update/description`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const updateEventImage = async function (token, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/business/event/update/image`,
			{
				...payload
			},
			{
				headers: {
					'passcoder-access-token': token
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

const deleteEvent = async function (token, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/business/event`,
			{
				data: {
					token,
					...payload
				}
			},
			// {
			// 	headers: {
			// 		'passcoder-access-token': token
			// 	}
			// }
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error };
	}
};

export { getBusinessEvent, getBusinessEventImage, addBusinessEvent, deleteEvent, getBusinessEvents, updateEventDetails, updateEventDescription, updateEventDuration, updateEventImage, updateEventLocation, updateEventName };
